import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

function Airports() {
  const [airports, setAirports] = useState([])
  const [selectedAirports, setSelectedAirports] = useState([])

  const [airportName, setAirportName] = useState('')
  const [airportType, setAirportType] = useState('')
  const [airportAddress, setAirportAddress] = useState('')
  const [airportCountry, setAirportCountry] = useState('')
  const [airportCity, setAirportCity] = useState('')
  const [airportZipCode, setAirportZipCode] = useState('')
  const [airportStatus, setAirportStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalAirports = airports.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Airport/GetAllDashAirportDetails`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token.authToken,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        console.log(data)
        setAirports(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchAirports()
  }, [selectedAirports, token.authToken])

  function handleStatusChange(e) {
    setAirportStatus(e.target.value === 'true')
  }

  // Handle Airport creation
  const handleCreateAirport = async (e) => {
    e.preventDefault()
    const newAirport = {
      airportName,
      airportType,
      airportAddress,
      airportCountry,
      airportCity,
      airportZipCode,
      isActive: airportStatus,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/airport/CreateAirport`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(newAirport),
        }
      )

      if (response.ok) {
        const createdAirport = await response.json()
        // setAirports((prevAirports) => [...prevAirports, createdAirport])
        toast.success(`Airport '${createdAirport.airportName}' created`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Airport.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new Airport
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedAirports([])

    setAirportName('')
    setAirportType('')
    setAirportAddress('')
    setAirportCountry('')
    setAirportCity('')
    setAirportZipCode('')
    setAirportStatus(true)
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle Airport update
  const handleEditAirport = async (e) => {
    e.preventDefault()
    const selectedAirport = airports.find(
      (airport) => airport.airportID === selectedAirports[0]
    )

    console.log(selectedAirport)
    const updatedAirport = {
      ...selectedAirport,
      airportName,
      airportType,
      airportCountry,
      airportAddress,
      airportCity,
      airportZipCode,
      isActive: true,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Airport/Update/${selectedAirport.airportID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedAirport),
        }
      )

      if (response.ok) {
        const updatedAirportData = await response.json()
        setAirports((prevAirports) =>
          prevAirports.map((airport) =>
            airport.airportID === updatedAirportData.airportID
              ? updatedAirportData
              : airport
          )
        )
        console.log(updatedAirport)
        console.log(airports)
        toast.success(`Airport '${selectedAirport.airportName}' updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update Airport.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Airport: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing Airport
  const handleEditButtonClick = () => {
    const selectedAirport = airports.find(
      (airport) => airport.airportID === selectedAirports[0]
    )
    console.log(selectedAirport)
    setAirportName(selectedAirport.airportName)
    setAirportType(selectedAirport.airportType)
    setAirportAddress(selectedAirport.airportAddress)
    setAirportCountry(selectedAirport.airportCountry)
    setAirportCity(selectedAirport.airportCity)
    setAirportZipCode(selectedAirport.airportZipCode)
    setAirportStatus(selectedAirport.isActive)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Airport
  const handleDetailsAirport = () => {
    const selectedAirport = airports.find(
      (airport) => airport.airportID === selectedAirports[0]
    )
    setAirportName(selectedAirport.airportName)
    setAirportType(selectedAirport.airportType)
    setAirportAddress(selectedAirport.airportAddress)
    setAirportCountry(selectedAirport.airportCountry)
    setAirportCity(selectedAirport.airportCity)
    setAirportZipCode(selectedAirport.airportZipCode)
    setAirportStatus(selectedAirport.isActive)
  }

  const handleDetailAirport = (singleAirport) => {
    const selectedAirport = airports.find(
      (airport) => airport.airportID === singleAirport
    )
    setAirportName(selectedAirport.airportName)
    setAirportType(selectedAirport.airportType)
    setAirportAddress(selectedAirport.airportAddress)
    setAirportCountry(selectedAirport.airportCountry)
    setAirportCity(selectedAirport.airportCity)
    setAirportZipCode(selectedAirport.airportZipCode)
    setAirportStatus(selectedAirport.isActive)
  }

  // Handle Airport deletion
  const handleDeleteAirport = async () => {
    const selectedAirport = { airportID: selectedAirports }

    try {
      const response = await fetch(`${API_BASE_URL}/api/Airport/BulkDelete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(selectedAirport),
      })

      if (response.ok) {
        setAirports((prevAirports) =>
          prevAirports.filter(
            (airport) => !selectedAirports.includes(airport.airportID)
          )
        )
        toast.success(`Selected Airports deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedAirports([])
      }
    } catch (error) {
      toast.error(`Error deleting Airports: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setAirportName('')
    setAirportType('')
    setAirportAddress('')
    setAirportCountry('')
    setAirportCity('')
    setAirportZipCode('')
  }
  console.log(selectedAirports)
  // Show modal for deleting Airport
  const handleDeleteButtonClick = () => {
    const selectedAirport = airports.find(
      (airport) => airport.airportID === selectedAirports[0]
    )
    setAirportName(selectedAirport.airportName)
  }

  // Handle Airport activation/deactivation
  const handleToggleAirportStatus = async (isActive) => {
    try {
      const validAirports = selectedAirports.filter((id) => {
        const airport = airports.find((airport) => airport.airportID === id)
        return airport.airportName && airport.airportAddress
      })
      const selectedAirport = {
        airportID: selectedAirports,
        isActive: isActive,
      }
      const response = await fetch(`${API_BASE_URL}/api/Airport/BulkActive`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(selectedAirport),
      })

      if (response.ok) {
        setAirports((prevAirports) =>
          prevAirports.map((airport) =>
            validAirports.includes(airport.airportID)
              ? { ...airport, isActive }
              : airport
          )
        )
        toast.success(`Selected Airports updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })

        setSelectedAirports([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating Airport: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredAirports = airports.filter((airport) => {
    const matchesSearchTerm =
      airport.airportName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      airport.airportType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      airport.airportAddress
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      airport.airportCountry
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      airport.airportState?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      airport.airportCity?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      airport.airportZipCode?.toLowerCase().includes(searchTerm.toLowerCase())

    const airportDate = new Date(airport.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      // Helper function to format date to 'dd/mm/yyyy'
      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedAirportDate = formatDate(airportDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedAirportDate >= formattedFromDate &&
          formattedAirportDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedAirportDate === formattedFromDate // Exact match for fromDate
      }

      return true // If no fromDate or toDate, show all data
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (airportId) => {
    setSelectedAirports((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(airportId)
        ? prevSelected.filter((id) => id !== airportId)
        : [...prevSelected, airportId]

      const hasAirportWithBoth = newSelection.some((id) => {
        const selectedAirport = airports.find(
          (airport) => airport.airportID === id
        )
        return selectedAirport.airportName && selectedAirport.airportCountry
      })

      setIsActiveButtonEnabled(hasAirportWithBoth)
      return newSelection
    })
  }
  // console.log(selectedAirports, '----itsme-----')
  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(filteredAirports.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentAirports = filteredAirports.reverse().slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentAirports.length}/${totalAirports}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>{' '}
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedAirports.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsAirport}
                    title="Details"
                    disabled={selectedAirports.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Airport Name: '}</p>
                            <p className="ms-2 mb-0">{airportName}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Airport Type: '}</p>
                            <p className="ms-2 mb-0">{airportType}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Airport Country: '}</p>
                            <p className="ms-2 mb-0">{airportCountry}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Airport City: '}</p>
                            <p className="ms-2 mb-0">{airportCity}</p>
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Airport Country: '}</p>
                            <p className="ms-2 mb-0">{airportCountry}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedAirports.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedAirports.length === 1
                                ? ` '${airportName}' record?`
                                : `${selectedAirports.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteAirport}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as active"
                    onClick={() => handleToggleAirportStatus(true)}
                    disabled={
                      (selectedAirports.length === 0 &&
                        !isActiveButtonEnabled) ||
                      airports
                        .filter((airport) =>
                          selectedAirports.includes(airport.airportID)
                        )
                        .every((airport) => airport.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-on"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as inactive"
                    onClick={() => handleToggleAirportStatus(false)}
                    disabled={
                      (selectedAirports.length === 0 &&
                        !isActiveButtonEnabled) ||
                      airports
                        .filter((airport) =>
                          selectedAirports.includes(airport.airportID)
                        )
                        .every((airport) => !airport.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-off"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div
                id="AirportmodelMake"
                tabIndex="-1"
                aria-labelledby="AirportModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode ? handleEditAirport : handleCreateAirport
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="AirportModalLabel">
                          {isEditMode ? 'Edit Airport' : 'Add New Airport'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Airport Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={airportName}
                            onChange={(e) => setAirportName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Airport Type
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={airportType}
                            onChange={(e) => setAirportType(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Airport Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={airportAddress}
                            onChange={(e) => setAirportAddress(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Airport Country
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={airportCountry}
                            onChange={(e) => setAirportCountry(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Airport City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={airportCity}
                            onChange={(e) => setAirportCity(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            AirportZip Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={airportZipCode}
                            onChange={(e) => setAirportZipCode(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3 active-inactive-all">
                          <div>
                            <input
                              type="radio"
                              id="active"
                              name="airportStatus"
                              value="true"
                              onChange={handleStatusChange}
                              checked={airportStatus === true}
                            />
                            <label htmlFor="active">Active</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="inactive"
                              name="airportStatus"
                              value="false"
                              onChange={handleStatusChange}
                              checked={airportStatus === false}
                            />
                            <label htmlFor="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update Airport' : 'Create Airport'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      airports.length <= 0
                        ? false
                        : selectedAirports.length === airports.length
                      // airports.length >= 1
                      // selectedAirports.length === airports.length ? true : false
                      // airports.length
                    }
                    onChange={() =>
                      setSelectedAirports(
                        selectedAirports.length === airports.length
                          ? []
                          : airports.map((airport) => airport.airportID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Airport Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Airport Type
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Airport Address
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Airport Country
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Airport City
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Airport ZipCode
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Terminals
                </th>
              </tr>
            </thead>
            <tbody>
              {currentAirports.map((airport, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${
                    airport.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={airport.airportID}
                  style={airport.isActive ? { backgroundColor: '#D3D3D3' } : {}}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedAirports.includes(airport.airportID)}
                      onChange={(e) => handleCheckboxChange(airport.airportID)}
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailAirport(airport.airportID)}
                  >
                    {airport.airportName}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailAirport(airport.airportID)}
                  >
                    {airport.airportType}
                  </td>
                  <td>{airport.airportAddress}</td>
                  <td>{airport.airportCountry}</td>
                  <td>{airport.airportCity}</td>
                  <td>{airport.airportZipCode}</td>
                  <td>{airport.createdDate}</td>

                  <td key={airport.airportID}>
                    <Link to={`/airport/airports/${airport.airportID}/terminals`}>
                      <i class="fa-solid fa-plane text-black"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing Airports: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Airports
