import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

function Lanes() {
  const { yardID } = useParams()
  const navigate = useNavigate()

  const [lanes, setLanes] = useState([])
  const [selectedLanes, setSelectedLanes] = useState([])

  const [yardLaneName, setYardLaneName] = useState('')
  const [yardLaneDescription, setYardLaneDescription] = useState('')
  const [laneStatus, setLaneStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  // const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalLanes = lanes.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  useEffect(() => {
    const fetchLanes = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Yard/GetYardLanesByYardId/${yardID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token.authToken,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        console.log(data)

        setLanes(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
    fetchLanes()
  }, [selectedLanes, yardID])

  function handleBack() {
    navigate(-1)
  }

  function handleStatusChange(e) {
    setLaneStatus(e.target.value === 'true')
  }

  // Handle Lane creation
  const handleCreateLane = async (e) => {
    e.preventDefault()
    const newLane = {
      yardLaneName,
      yardLaneDescription,
      IsActive: true,
    }
    console.log(newLane)

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Yard/CreateYardLane/${yardID}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(newLane),
        }
      )

      if (response.ok) {
        const createdLane = await response.json()
        console.log(createdLane)
        // setLanes((prevLanes) => [...prevLanes, createdLane])
        toast.success(`Lane '${createdLane.yardLaneName}' created`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Lane.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new Lane
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedLanes([])

    setYardLaneName('')
    setYardLaneDescription('')

    setLaneStatus(true)
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle Lane update
  const handleEditLane = async (e) => {
    e.preventDefault()
    const selectedLane = lanes.find(
      (lane) => lane.yardLaneID === selectedLanes[0]
    )
    const updatedLane = {
      ...selectedLane,
      yardLaneName,
      yardLaneDescription,
      IsActive: true,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Yard/UpdateYardLane/${selectedLane.yardLaneID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedLane),
        }
      )

      if (response.ok) {
        const updatedLaneData = await response.json()
        console.log(updatedLaneData)
        // setLanes((prevLanes) =>
        //   prevLanes.map((lane) =>
        //     lane.yardLaneID === updatedLaneData.yardLaneID ? updatedLaneData : lane
        //   )
        // )
        // console.log(updatedLane)
        // console.log(lanes)
        toast.success(`Lane '${selectedLane.yardLaneName}' updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update Lane.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Lane: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing Lane
  const handleEditButtonClick = () => {
    const selectedLane = lanes.find(
      (lane) => lane.yardLaneID === selectedLanes[0]
    )
    console.log(selectedLane)
    setYardLaneName(selectedLane.yardLaneName)
    setYardLaneDescription(selectedLane.yardLaneDescription)
    setLaneStatus(selectedLane.isActive)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Lane
  const handleDetailsLane = () => {
    const selectedLane = lanes.find(
      (lane) => lane.yardLaneID === selectedLanes[0]
    )
    setYardLaneName(selectedLane.yardLaneName)
    setYardLaneDescription(selectedLane.yardLaneDescription)

    setLaneStatus(selectedLane.isActive)
  }

  const handleDetailLane = (singleLane) => {
    const selectedLane = lanes.find((lane) => lane.yardLaneID === singleLane)
    setYardLaneName(selectedLane.yardLaneName)
    setYardLaneDescription(selectedLane.yardLaneDescription)

    setLaneStatus(selectedLane.isActive)
  }

  // Handle Lane deletion
  const handleDeleteLane = async () => {
    const selectedLane = { yardLaneID: selectedLanes }
    console.log(selectedLane)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/yard/BulkDeleteYardLanes`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(selectedLane),
        }
      )

      if (response.ok) {
        setLanes((prevLanes) =>
          prevLanes.filter((lane) => !selectedLanes.includes(lane.yardLaneID))
        )
        toast.success(`Selected Lanes deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedLanes([])
      }
    } catch (error) {
      toast.error(`Error deleting Lanes: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setYardLaneName('')
    setYardLaneDescription('')
  }

  // Show modal for deleting Lane
  const handleDeleteButtonClick = () => {
    const selectedLane = lanes.find(
      (lane) => lane.yardLaneID === selectedLanes[0]
    )
    setYardLaneName(selectedLane.yardLaneName)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredLanes = lanes.filter((lane) => {
    const matchesSearchTerm =
      lane.yardLaneName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lane.laneType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lane.laneAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lane.laneCountry?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lane.laneState?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lane.laneCity?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lane.laneZipCode?.toLowerCase().includes(searchTerm.toLowerCase())

    const laneDate = new Date(lane.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      // Helper function to format date to 'dd/mm/yyyy'
      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedLaneDate = formatDate(laneDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedLaneDate >= formattedFromDate &&
          formattedLaneDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedLaneDate === formattedFromDate // Exact match for fromDate
      }

      return true // If no fromDate or toDate, show all data
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (yardLaneID) => {
    setSelectedLanes((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(yardLaneID)
        ? prevSelected.filter((id) => id !== yardLaneID)
        : [...prevSelected, yardLaneID]

      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(filteredLanes.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentLanes = filteredLanes.reverse().slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentLanes.length}/${totalLanes}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleBack}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i class="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedLanes.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsLane}
                    title="Details"
                    disabled={selectedLanes.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Lane Name: '}</p>
                            <p className="ms-2 mb-0">{yardLaneName}</p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedLanes.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedLanes.length === 1
                                ? ` '${yardLaneName}' record?`
                                : `${selectedLanes.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteLane}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="LanemodelMake"
                tabIndex="-1"
                aria-labelledby="LaneModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={isEditMode ? handleEditLane : handleCreateLane}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="LaneModalLabel">
                          {isEditMode ? 'Edit Lane' : 'Add New Lane'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Lane Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={yardLaneName}
                            onChange={(e) => setYardLaneName(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Lane Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={yardLaneDescription}
                            onChange={(e) =>
                              setYardLaneDescription(e.target.value)
                            }
                            required
                          />
                        </div>

                        <div className="mb-3 active-inactive-all">
                          <div>
                            <input
                              type="radio"
                              id="active"
                              name="laneStatus"
                              value="true"
                              onChange={handleStatusChange}
                              checked={laneStatus === true}
                            />
                            <label htmlFor="active">Active</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="inactive"
                              name="laneStatus"
                              value="false"
                              onChange={handleStatusChange}
                              checked={laneStatus === false}
                            />
                            <label htmlFor="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update Lane' : 'Create Lane'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      selectedLanes.length === lanes.length && lanes.length >= 1
                    }
                    onChange={() =>
                      setSelectedLanes(
                        selectedLanes.length === lanes.length
                          ? []
                          : lanes.map((lane) => lane.yardLaneID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Lane Name
                </th>{' '}
                <th scope="col" className="faqs-table-row-d3">
                  Lane Description
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Rows
                </th>
              </tr>
            </thead>
            <tbody>
              {currentLanes.map((lane, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${lane.isActive ? 'Active Item' : 'Inactive Item'}`}
                  key={lane.yardLaneID}
                  style={lane.isActive ? { backgroundColor: '#D3D3D3' } : {}}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedLanes.includes(lane.yardLaneID)}
                      onChange={(e) =>
                        handleCheckboxChange(lane.yardLaneID, index, e)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailLane(lane.yardLaneID)}
                  >
                    {lane.yardLaneName}
                  </td>
                  <td>{lane.yardLaneDescription}</td>
                  <td>{lane.createdDate}</td>
                  <td key={lane.yardLaneID}>
                    <Link to={`/airport/${yardID}/${lane.yardLaneID}/rows`}>
                      <i class="fa-solid fa-grip-lines-vertical fa-rotate-90 text-black"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <div className="pagination-container">
              <div className="pagination-left">
                <div className="items-per-page-label">Items per page:</div>
                <select
                  name="faqPerPage"
                  onChange={(e) => setItemPerPage(e.target.value)}
                  className="items-per-page-select form-control"
                >
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="60">60</option>
                  <option value="75">75</option>
                  <option value="90">90</option>
                </select>
              </div>

              <div className="pagination-right">
                <div className="faq-display-range">
                  Showing Lanes: {calculateDisplayRange()}
                </div>
                <ul className="pagination-list pagination ">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handlePrevious}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      className={`page-item ${
                        currentPage === index + 1 ? 'active' : ''
                      }`}
                      key={index}
                    >
                      <button
                        className="page-link"
                        onClick={() => handleSerialNumberChange(index)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Lanes
