import { useState, useEffect } from 'react'
import { verifyToken } from '..'

const useFetch = (url, refreshDependency) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const authToken = await verifyToken()

      setLoading(true)
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
        const json = await response.json()
        if (response.ok) {
          setLoading(false)
          setData(json)
          setError(null)
        } else {
          setLoading(false)
          setData(null)
          setError(json)
        }
      } catch (error) {
        setError(`${error}`)
        setLoading(false)
      }
    }
    fetchData()
  }, [url, refreshDependency])
  return { data, loading, setLoading, error }
}
export default useFetch
