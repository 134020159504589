import React, { useEffect, useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

function Rows() {
  const { yardLaneID } = useParams()
  const navigate = useNavigate()

  const [rows, setRows] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [laneRow, setLaneRow] = useState('')
  const [rowStatus, setRowStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalRows = rows.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  useEffect(() => {
    const fetchRows = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Yard/GetLaneRowsByYardLaneId/${yardLaneID}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setRows(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
    fetchRows()
  }, [selectedRows, yardLaneID])

  console.log(rows)
  function handleBack() {
    navigate(-1)
  }

  function handleStatusChange(e) {
    setRowStatus(e.target.value === 'true')
  }

  // Handle Row creation
  const handleCreateRow = async (e) => {
    e.preventDefault()
    const newRow = {
      laneRow,
      isActive: true,
    }
    console.log(newRow)

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Yard/CreateLaneRow/${yardLaneID}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(newRow),
        }
      )

      if (response.ok) {
        const createdRow = await response.json()
        // setRows((prevRows) => [...prevRows, createdRow])
        toast.success(`Row '${createdRow.laneRow}' created`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Row.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new Row
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedRows([])

    setLaneRow('')

    setRowStatus(true)
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle Row update
  const handleEditRow = async (e) => {
    e.preventDefault()
    const selectedRow = rows.find((row) => row.laneRowsID === selectedRows[0])

    console.log(selectedRow)
    const updatedRow = {
      ...selectedRow,
      laneRow,

      isActive: rowStatus,
      isDeleted: false,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Yard/UpdateLaneRow/${selectedRow.laneRowsID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedRow),
        }
      )

      if (response.ok) {
        const updatedRowData = await response.json()
        // setRows((prevRows) =>
        //   prevRows.map((row) =>
        //     row.laneRowsID === updatedRowData.laneRowsID ? updatedRowData : row
        //   )
        // )
        console.log(updatedRow)
        console.log(rows)
        toast.success(`Row '${selectedRow.laneRow}' updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update Row.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Row: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing Row
  const handleEditButtonClick = () => {
    const selectedRow = rows.find((row) => row.laneRowsID === selectedRows[0])
    console.log(selectedRow)
    setLaneRow(selectedRow.laneRow)

    setRowStatus(selectedRow.isActive)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Row
  const handleDetailsRow = () => {
    const selectedRow = rows.find((row) => row.laneRowsID === selectedRows[0])
    setLaneRow(selectedRow.laneRow)

    setRowStatus(selectedRow.isActive)
  }

  const handleDetailRow = (singleRow) => {
    const selectedRow = rows.find((row) => row.laneRowsID === singleRow)
    setLaneRow(selectedRow.laneRow)

    setRowStatus(selectedRow.isActive)
  }

  // Handle Row deletion
  const handleDeleteRow = async () => {
    const selectedRow = { laneRowsID: selectedRows }
    console.log(selectedRow)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/yard/BulkDeleteLaneRows`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(selectedRow),
        }
      )

      if (response.ok) {
        setRows((prevRows) =>
          prevRows.filter((row) => !selectedRows.includes(row.laneRowsID))
        )
        toast.success(`Selected Rows deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedRows([])
      }
    } catch (error) {
      toast.error(`Error deleting Rows: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setLaneRow('')
  }

  // Show modal for deleting Row
  const handleDeleteButtonClick = () => {
    const selectedRow = rows.find((row) => row.laneRowsID === selectedRows[0])
    setLaneRow(selectedRow.laneRow)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredRows = rows.filter((row) => {
    const matchesSearchTerm = row.laneRow
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase())

    const rowDate = new Date(row.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      // Helper function to format date to 'dd/mm/yyyy'
      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedRowDate = formatDate(rowDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedRowDate >= formattedFromDate &&
          formattedRowDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedRowDate === formattedFromDate // Exact match for fromDate
      }

      return true // If no fromDate or toDate, show all data
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (laneRowsID) => {
    setSelectedRows((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(laneRowsID)
        ? prevSelected.filter((id) => id !== laneRowsID)
        : [...prevSelected, laneRowsID]

      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(filteredRows.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentRows = filteredRows.reverse().slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentRows.length}/${totalRows}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleBack}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i class="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedRows.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsRow}
                    title="Details"
                    disabled={selectedRows.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Row Name: '}</p>
                            <p className="ms-2 mb-0">{laneRow}</p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedRows.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedRows.length === 1
                                ? ` '${laneRow}' record?`
                                : `${selectedRows.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteRow}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="RowmodelMake"
                tabIndex="-1"
                aria-labelledby="RowModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={isEditMode ? handleEditRow : handleCreateRow}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="RowModalLabel">
                          {isEditMode ? 'Edit Row' : 'Add New Row'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Row Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={laneRow}
                            onChange={(e) => setLaneRow(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3 active-inactive-all">
                          <div>
                            <input
                              type="radio"
                              id="active"
                              name="rowStatus"
                              value="true"
                              onChange={handleStatusChange}
                              checked={rowStatus === true}
                            />
                            <label htmlFor="active">Active</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="inactive"
                              name="rowStatus"
                              value="false"
                              onChange={handleStatusChange}
                              checked={rowStatus === false}
                            />
                            <label htmlFor="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update Row' : 'Create Row'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      selectedRows.length === rows.length && rows.length >= 1
                    }
                    onChange={() =>
                      setSelectedRows(
                        selectedRows.length === rows.length
                          ? []
                          : rows.map((row) => row.laneRowsID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Row Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${row.isActive ? 'Active Item' : 'Inactive Item'}`}
                  key={row.laneRowsID}
                  style={row.isActive ? { backgroundColor: '#D3D3D3' } : {}}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(row.laneRowsID)}
                      onChange={(e) =>
                        handleCheckboxChange(row.laneRowsID, index, e)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailRow(row.laneRowsID)}
                  >
                    {row.laneRow}
                  </td>
                  <td>{row.createdDate}</td>
                  {/* <td>{row.createdDate}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <div className="pagination-container">
              <div className="pagination-left">
                <div className="items-per-page-label">Items per page:</div>
                <select
                  name="faqPerPage"
                  onChange={(e) => setItemPerPage(e.target.value)}
                  className="items-per-page-select form-control"
                >
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="60">60</option>
                  <option value="75">75</option>
                  <option value="90">90</option>
                </select>
              </div>

              <div className="pagination-right">
                <div className="faq-display-range">
                  Showing Rows: {calculateDisplayRange()}
                </div>
                <ul className="pagination-list pagination ">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handlePrevious}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      className={`page-item ${
                        currentPage === index + 1 ? 'active' : ''
                      }`}
                      key={index}
                    >
                      <button
                        className="page-link"
                        onClick={() => handleSerialNumberChange(index)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Rows
