import React, { useEffect, useState } from 'react'
import BASE_URL from '../../../Config/Config'
import { useLoaderData } from 'react-router-dom'
import './../Quotes/Quotes.css'
import { API_BASE_URL } from '../../../Config/Config.js'
import { toast, Slide } from 'react-toastify'
import { verifyToken } from '../../..'
import useFetch from '../../../CustomHooks/useFetch'

function CancelledBookings() {
  const [token, setToken] = useState('')
  const apiURLCancelledBookings = `${API_BASE_URL}/api/booking/AllCancelBookings`
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const data = useLoaderData()
  const [cancelledBookings, setCancelledBookings] = useState(data)
  const [selectedCancelledBookings, setSelectedCancelledBookings] = useState([])
  const [expandedRows, setExpandedRows] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalCancelledBookings = cancelledBookings.length
  const [cancelledBooking, setCancelledBooking] = useState({
    fullName: '',
    phoneNumber: '',
    customerEmail: '',
    quoteCode: '',
    startDateTime: null,
    endDateTime: null,
    totalCost: 0,
    flyingFrom: '',
    inboundFlightNo: '',
    outboundFlightNo: '',
    outboundTerminalID: null,
    inboundTerminalID: null,
    selectedCarCleanID: null,
    location: '',
    selectedEChargeID: null,
    companyName: '',
    source: '',
    flyingTo: '',
    discountCode: '',
    productName: '',
    productDescription: '',
    productPrice: 0,
    airportID: null,
    flightFrom: '',
    flightTo: '',
    inBoundFlight: '',
    outBoundFlight: '',
    isRegister: false,
    isPromotions: false,
    isFlyingfromDifferent: false,
  })
  const [airports, setAirports] = useState([])

  const { triggerData } = useFetch(
    apiURLCancelledBookings,
    token,
    selectedCancelledBookings
  )
  useEffect(() => {
    if (triggerData) {
      setCancelledBookings(triggerData)
    }
  }, [cancelledBookings, triggerData])

  const updateFormData = (field, value) => {
    setCancelledBooking((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  // Handle quote deletion
  const handleCancelBooking = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/booking/CancelBooking/${selectedCancelledBookings}`,
        {
          method: 'DELETE',

          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      )

      if (response.ok) {
        toast.success(`Selected CancelledBookings Cancelled`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        setSelectedCancelledBookings([])
        setExpandedRows([])
      }
    } catch (error) {
      toast.error(`Error deleting Quote: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
    setIsModalOpen(false)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredCancelledBookings = cancelledBookings.filter((quote) => {
    const quoteDate = new Date(quote.createdDate) // Convert string date to Date object

    // Check if Quote question includes the search term (case insensitive)
    const matchesSearchTerm =
      quote.bookingID
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      quote.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quote.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quote.customerEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quote.location?.toLowerCase().includes(searchTerm.toLowerCase())

    // Check date filtering conditions
    const matchesDateRange = (() => {
      if (fromDate && toDate) {
        // If both fromDate and toDate are set, filter by the range
        return quoteDate >= new Date(fromDate) && quoteDate <= new Date(toDate)
      } else if (fromDate) {
        // If only fromDate is set, filter from that date onward
        return quoteDate >= new Date(fromDate)
      } else if (toDate) {
        // If only toDate is set, filter up to that date
        return quoteDate <= new Date(toDate)
      }
      // If neither fromDate nor toDate is set, match all dates
      return true
    })()

    // Return true only if both search term and date range conditions are met
    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (bookingID) => {
    setSelectedCancelledBookings(bookingID)
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index)
    // setCurrentPage(index + 1)
    setSerialNumber(index * itemsPerPage)
  }

  // Calculate total pages based on filtered Quote
  const totalPages = Math.ceil(filteredCancelledBookings.length / itemsPerPage)
  // Calculate the index range of CancelledBookings to display
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  // CancelledBookings to be displayed on the current page
  const currentCancelledBookings = filteredCancelledBookings
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    const endQuote = Math.min(
      currentPage * itemsPerPage,
      totalCancelledBookings
    )
    return `${endQuote}/${totalCancelledBookings}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]))
  }

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="d-flex flex-row flex-wrap">
            <div
              className="modal fade"
              id="CancelModal"
              tabindex="-1"
              aria-labelledby="CancelModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="CancelModalLabel">
                      Confirmation
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <div className="mb-3">
                      <label className="fs-5">Do you want to Cancel? </label>
                      <br />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="button" className="site-btn invisible">
                      Update changes
                    </button>
                    <button
                      type="button"
                      className="cancel-btn"
                      data-bs-dismiss="modal"
                      onClick={handleCancelBooking}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="cancel-btn"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6"></div>
            <div className="p-2">
              <div className="col-lg-4 col-md-6">
                <div className="input-box mt-2">
                  <input
                    type="search"
                    className="form-control search-bar"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="col-lg-4 col-md-3 ">
                <div className="right mb-3 d-flex justify-content-between align-items-center col-md-12">
                  <label className="mx-1">From: </label>
                  <input
                    type="date"
                    className="form-control col-md-5"
                    id="exampleFormControlInput1"
                    placeholder="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  <label className="mx-1">To: </label>
                  <input
                    type="date"
                    className="form-control col-md-5"
                    id="exampleFormControlInput2"
                    placeholder="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                  <div className="p-2" title="Reset Date">
                    <button
                      type="button"
                      className="btn btn-light details-btnn"
                      onClick={handleResetFilter}
                    >
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Booking"
                      >
                        <i className="fa-solid fa-filter-circle-xmark"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle">
              <thead className="table-dark">
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Customer Email</th>
                  <th scope="col">Quote Code</th>
                  <th scope="col">Booking ID</th>
                  <th scope="col">Start Date Time</th>
                  <th scope="col">End Date Time</th>
                  <th scope="col">Created Date</th>
                </tr>
              </thead>
              <tbody>
                {currentCancelledBookings?.map((quote, index) => (
                  <>
                    <tr
                      key={quote.bookingID}
                      className={`${
                        expandedRows.includes(index) ? 'table-info' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{serialNumber + index + 1}</td>
                      <td>{quote.customerName || 'N/A'}</td>
                      <td>{quote.phoneNumber || 'N/A'}</td>
                      <td>{quote.customerEmail || 'N/A'}</td>
                      <td>{quote.quoteCode || 'N/A'}</td>
                      <td>{quote.bookingID || 'N/A'}</td>
                      <td>{quote.startDateTime || 'N/A'}</td>
                      <td>{quote.endDateTime || 'N/A'}</td>
                      <td>{quote.createdDate || 'N/A'}</td>
                    </tr>

                    {expandedRows.includes(index) && (
                      <tr className="bg-light">
                        <td colSpan="9">
                          <div className="row g-2 p-3">
                            <div className="col-md-3">
                              <strong>Total Cost:</strong> £
                              {quote.totalCost || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Flying From:</strong>{' '}
                              {quote.flyingFrom || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Flying To:</strong>{' '}
                              {quote.flyingTo || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Inbound Terminal Name:</strong>{' '}
                              {quote.inBoundTerminalName || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Outbound Terminal Name:</strong>{' '}
                              {quote.outBoundTerminalName || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Airport Name:</strong>{' '}
                              {quote.airportName || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Product Price:</strong> £
                              {quote.productPrice || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Discount Code:</strong>{' '}
                              {quote.discountCode || 'N/A'}
                            </div>
                            <div className="col-md-3">
                              <strong>Payment Status:</strong>{' '}
                              {quote.paymentStatus ? 'Yes' : 'No'}
                            </div>
                            <div className="col-md-3">
                              <strong>Promotions:</strong>{' '}
                              {quote.isPromotions ? 'Yes' : 'No'}
                            </div>
                            <div className="col-md-3">
                              <strong>Registered:</strong>{' '}
                              {quote.isRegister ? 'Yes' : 'No'}
                            </div>
                            <div className="col-md-3">
                              <strong>Flying From Different:</strong>{' '}
                              {quote.isFlyingfromDifferent ? 'Yes' : 'No'}
                            </div>
                            <div className="col-md-4 d-flex gap-2">
                              <button
                                className="btn btn-info btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#CancelModal"
                                onClick={(e) =>
                                  handleCheckboxChange(quote.bookingID)
                                }
                              >
                                Update Booking
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#CancelModal"
                                onClick={(e) =>
                                  handleCheckboxChange(quote.bookingID)
                                }
                              >
                                Cancel Quote
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <nav aria-label="Page navigation example">
            <div className="container text-center align-content-center d-flex">
              <div>Showing CancelledBookings: {calculateDisplayRange()}</div>
            </div>
            <select
              name="quotePerPage"
              onChange={(e) => setItemPerPage(e.target.value)}
              className="form-control col-lg-4"
            >
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="60">60</option>
              <option value="75">75</option>
              <option value="90">90</option>
            </select>
            <ul className="pagination justify-content-end me-1">
              {/* Previous Button */}
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <button
                  className="page-link"
                  onClick={handlePrevious}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>

              {/* Dynamically Render Page Numbers */}
              {Array.from({ length: 3 }, (_, index) => currentPage + index).map(
                (page) =>
                  page <= totalPages && (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handleSerialNumberChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  )
              )}

              {/* Next Button */}
              <li
                className={`page-item ${
                  currentPage === totalPages ? 'disabled' : ''
                }`}
              >
                <button
                  className="page-link"
                  onClick={handleNext}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default CancelledBookings
