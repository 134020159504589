import { useEffect, useState } from 'react'
import useFetch from '../../../CustomHooks/useFetch'
import { API_BASE_URL } from '../../../Config/Config'
import { verifyToken } from '../../..'
import CustomLoading from '../../../Components/CustomHandling/LoadingAndError'
import { Slide, toast } from 'react-toastify'
import PageHeaderAndFooter from '../../../Components/PageHeaderAndFooter/PageHeaderAndFooter'

const Modules = () => {
  const showToast = (message, type = 'info', options = {}) => {
    const baseOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Slide,
      ...options,
    }

    switch (type) {
      case 'success':
        toast.success(message, baseOptions)
        break
      case 'warn':
        toast.warn(message, baseOptions)
        break
      case 'error':
        toast.error(message, baseOptions)
        break
      case 'info':
      default:
        toast.info(message, baseOptions)
        break
    }
  }

  const modulesURL = `${API_BASE_URL}/api/Module/GetAllModule`
  const [token, setToken] = useState('')
  // const [modules, setModules] = useState([])
  const [moduleName, setModuleName] = useState('')
  const [moduleID, setModuleID] = useState(null) // For updating
  const [refreshDependency, setRefreshDependency] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [createState, setCreateState] = useState(false)
  const [editState, setEditState] = useState(false)
  const [deleteState, setDeleteState] = useState(false)

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const {
    data: modules,
    loading,
    error,
  } = useFetch(modulesURL, refreshDependency)

  const handleCreateOrEditModule = async (e) => {
    e.preventDefault()
    const newModule = { moduleName, moduleID: moduleID ? moduleID : null }

    const url = `${API_BASE_URL}/api/module/CreateOrUpdateModule`

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(newModule),
      })

      if (response.ok) {
        const createdModule = await response.json()
        const action = moduleID ? 'updated' : 'created'
        showToast(`Module '${createdModule.moduleName}' ${action}`, 'success')

        // Trigger a refresh
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setCreateState(false)
        setEditState(false)
        setModuleName('')
        setModuleID(null)
      } else {
        showToast('Failed to save module.', 'warn')
      }
    } catch (error) {
      showToast(`${error.message}`, 'error')
    }
  }

  const handleDeleteModule = async (e) => {
    e.preventDefault()
    const deleteModule = { moduleID }

    const url = `${API_BASE_URL}/api/module/DeleteModule/${moduleID}`

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(deleteModule),
      })

      if (response.ok) {
        const createdModule = await response.json()
        const action = moduleID ? 'updated' : 'created'
        showToast(`Module '${createdModule.moduleName}' ${action}`, 'success')

        // Trigger a refresh
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setDeleteState(false)
        setModuleName('')
        setModuleID(null)
      } else {
        showToast('Failed to Delete module.', 'warn')
      }
    } catch (error) {
      showToast(`${error.message}`, 'error')
    }
  }

  const handleOpenModal = (creating, updating, deleting, moduleID) => {
    const selectedModule = modules.find((mod) => mod.moduleID === moduleID)
    if (creating) {
      setCreateState(true)
      setIsModalOpen(true)
    } else if (updating) {
      setModuleName(selectedModule.moduleName)
      setModuleID(moduleID)
      setEditState(true)
      setIsModalOpen(true)
    } else if (deleting) {
      setModuleName(selectedModule.moduleName)
      setModuleID(moduleID)
      setDeleteState(true)
      setIsModalOpen(true)
    } else {
      setCreateState(false)
      setEditState(false)
      setDeleteState(false)
      setIsModalOpen(false)
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setCreateState(false)
    setEditState(false)
    setDeleteState(false)
    setModuleName('')
    setModuleID(null)
  }

  if (loading) return <CustomLoading />
  if (error) return <div>Error: {error}</div>

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New Modules"
        handleOpenModal={() => handleOpenModal(true, null, null, null)}
        data={modules}
        filterFields={['moduleName']}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">ModuleName</th>
                  <th scope="col">CreatedDate</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((mod, index) => (
                  <tr key={mod.moduleID}>
                    <td>{startIndex + index + 1}</td>
                    <td>{mod.moduleName || 'N/A'}</td>
                    <td>{mod.createdDate || 'N/A'}</td>

                    <td className="d-flex gap-2">
                      <button
                        className="btn btn_modal"
                        onClick={() =>
                          handleOpenModal(null, true, null, mod.moduleID)
                        }
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn_modal_sec"
                        onClick={() =>
                          handleOpenModal(null, null, true, mod.moduleID)
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? 'show' : ''}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? 'block' : 'none',
              background: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={handleCloseModal}
          >
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  createState || editState
                    ? handleCreateOrEditModule
                    : handleDeleteModule
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {/* {moduleID ? 'Update module name' : 'Create module name'} */}
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : deleteState
                        ? 'Delete'
                        : ''}{' '}
                      module name
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="txtMake" className="form-label">
                        Module Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={moduleName}
                        onChange={(e) => setModuleName(e.target.value)}
                        required
                        disabled={deleteState}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        createState
                          ? 'btn btn_modal_sec'
                          : editState
                          ? 'btn btn_modal_sec'
                          : deleteState
                          ? 'btn btn_modal'
                          : ''
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        createState
                          ? 'btn btn_modal'
                          : editState
                          ? 'btn btn_modal'
                          : deleteState
                          ? 'btn btn_modal_sec'
                          : ''
                      }
                      type="submit"
                    >
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : deleteState
                        ? 'Delete'
                        : ''}{' '}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Modules
