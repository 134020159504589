// Combined File: PageHeaderAndFooter with Enhanced Pagination Logic
import React, { useState, useMemo } from 'react'
import './PageHeaderAndFooter.css'

function PageHeaderAndFooter({
  handleOpenModal,
  data,
  filterFields,
  renderContent, // Pass a function to render paginated content
  itemsPerPage = 10,
  btn_text = 'Add New',
  btn_add = true,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPageOptions] = useState([15, 30, 45, 60])
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(itemsPerPage)

  // Filter data based on dynamic fields and date range
  const filteredData = (data || []).filter((item) => {
    const matchesSearchTerm = filterFields.some((field) =>
      item[field]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )

    const itemDate = new Date(item.createdDate)
    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      return (
        (!fromDateObj || itemDate >= fromDateObj) &&
        (!toDateObj || itemDate <= toDateObj)
      )
    })()

    return matchesSearchTerm && matchesDateRange
  })

  // Pagination Logic
  const totalPages = Math.ceil(filteredData.length / currentItemsPerPage)

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * currentItemsPerPage
    return filteredData.slice(start, start + currentItemsPerPage)
  }, [filteredData, currentPage, currentItemsPerPage])

  const changePage = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page)
  }

  const handleResetFilter = () => {
    setSearchTerm('')
    setFromDate('')
    setToDate('')
  }

  const calculateDisplayInfo = () => {
    const start = (currentPage - 1) * currentItemsPerPage + 1
    const end = Math.min(currentPage * currentItemsPerPage, filteredData.length)
    return `Showing ${start}-${end} of ${filteredData.length}`
  }

  // Dynamic Pagination Buttons
  const getVisiblePageNumbers = () => {
    const maxVisibleButtons = 5
    const startPage = Math.max(
      1,
      Math.min(
        currentPage - Math.floor(maxVisibleButtons / 2),
        totalPages - maxVisibleButtons + 1
      )
    )

    const endPage = Math.min(startPage + maxVisibleButtons - 1, totalPages)

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    )
  }

  // console.log()

  return (
    <div>
      <div className="mb-3">
        <div className="d-flex justify-content-between main_page_header gap-2">
          {/* Left Section: Add Button */}
          {btn_add ? (
            <div className="w-lg-auto page_left_side">
              <button
                type="button"
                className="btn site-btn w-100"
                onClick={handleOpenModal}
              >
                {btn_text}
              </button>
            </div>
          ) : (
            <div className="w-lg-auto page_left_side"></div>
          )}

          {/* Right Section: Filters */}
          <div className="d-flex flex-row flex-lg-row gap-3 page_right_side">
            {/* Search Input */}
            <div className="d-flex flex-row gap-2 align-items-center">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Date Filters */}
            <div className="d-flex flex-row gap-2 align-items-center">
              <label className="mb-0">From:</label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>

            <div className="d-flex flex-row gap-2 align-items-center">
              <label className="mb-0">To:</label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <button
                type="button"
                className="btn btn_reset"
                onClick={handleResetFilter}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Display Information */}
      <div className="mb-3 d-flex justify-content-between align-items-center text-sm">
        <p className="text-muted m-0">{calculateDisplayInfo()}</p>
        <div>
          {/* <label htmlFor="itemsPerPage" className="me-2">
            Items per page:
          </label> */}
          <select
            id="itemsPerPage"
            className="form-select d-inline-block w-auto"
            value={currentItemsPerPage}
            onChange={(e) => {
              setCurrentItemsPerPage(Number(e.target.value))
              setCurrentPage(1) // Reset to the first page when items per page changes
            }}
          >
            <option selected disabled>
              {' '}
              Items per page:
            </option>
            {itemsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Render paginated content */}
      {renderContent(paginatedData, (currentPage - 1) * currentItemsPerPage)}

      {/* Pagination Controls */}
      <div className="d-flex ">
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-end me-1">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={() => changePage(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {getVisiblePageNumbers().map((page) => (
              <li
                key={page}
                className={`page-item ${page === currentPage ? 'active' : ''}`}
              >
                <button className="page-link" onClick={() => changePage(page)}>
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={() => changePage(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default PageHeaderAndFooter
