import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { API_BASE_URL } from '../../../Config/Config'
import { toast, Slide } from 'react-toastify'

const Services = () => {
  const data = useLoaderData()
  const [services, setServices] = useState(data)
  const [selectedServices, setSelectedServices] = useState([])
  const [serviceID, setServiceID] = useState(16)
  const [serviceName, setServiceName] = useState('')
  const [serviceType, setServiceType] = useState('')
  const [serviceDescription, setServiceDescription] = useState('')
  const [servicePrice, setServicePrice] = useState('')
  const [servicePicUrl, setServicePicUrl] = useState('')
  const [currency, setCurrency] = useState('Dollars')
  const [isDeleted, setIsDeleted] = useState(null)
  const [isActive, setIsActive] = useState(true)
  const [createdDate, setCreatedDate] = useState(null) // Initialize in state if needed

  const [category, setCategory] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [serialNumber, setSerialNumber] = useState((currentPage - 1) * 15)
  const itemsPerPage = 15
  const totalServices = services.length

  // const data = useLoaderData()
  // const [faqs, setFaqs] = useState(data)
  // const [selectedFAQs, setSelectedFAQs] = useState([])
  // const [answer, setAnswer] = useState('')
  const [question, setQuestion] = useState('')
  // const [category, setCategory] = useState(1)
  // const [isModalOpen, setIsModalOpen] = useState(false)
  // const [isEditMode, setIsEditMode] = useState(false)
  // const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  // const [searchTerm, setSearchTerm] = useState('')
  // const [fromDate, setFromDate] = useState('')
  // const [toDate, setToDate] = useState('')
  // const [currentPage, setCurrentPage] = useState(1)
  // const [serialNumber, setSerialNumber] = useState((currentPage - 1) * 15)
  // const itemsPerPage = 15
  // const totalFAQs = faqs.length

  // Handle Service creation
  const handleCreateService = async (e) => {
    e.preventDefault()
    const newService = {
      servicePicUrl,
      serviceName,
      serviceType,
      serviceDescription,
      servicePrice,
      currency,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Service/CreateService`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newService),
        }
      )

      if (response.ok) {
        const createdService = await response.json()
        setServices((prevServices) => [...prevServices, createdService])
        toast.success(`Service '${createdService.serviceName}' created`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Service.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
    console.log(
      serviceName,
      serviceType,
      serviceDescription,
      servicePrice,
      currency
    )
  }

  // Show modal for adding new Service
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setServiceName('')
    setServiceType('')
    setServiceDescription('')
    setServicePrice('')
    setServicePicUrl('')
    setCurrency('')
    setIsEditMode(false)
    setSelectedServices([])
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      setServiceName('')
      setServiceType('')
      setServiceDescription('')
      setServicePrice('')
      setServicePicUrl('')
      setCurrency('')
      setIsEditMode(false)
      setIsModalOpen(false)
    }
  }

  // Handle Service update
  const handleEditService = async (e) => {
    e.preventDefault()
    const selectedService = services.find(
      (service) => service.serviceID === selectedServices[0]
    )
    const updatedService = {
      ...selectedService,
      servicePicUrl,
      serviceName,
      serviceDescription,
      servicePrice,
      createdDate,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Services/UpdateService/${selectedService.serviceID}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedService),
        }
      )

      if (response.ok) {
        const updatedServiceData = await response.json()
        setServices((prevServices) =>
          prevServices.map((service) =>
            service.serviceID === updatedServiceData.serviceID
              ? updatedServiceData
              : service
          )
        )
        toast.success(`Service '${selectedService.serviceName}' updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update Service.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Service: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing Service
  const handleEditButtonClick = () => {
    const selectedService = services.find(
      (service) => service.serviceID === selectedServices[0]
    )
    setServiceName(selectedService.serviceName)
    setServiceType(selectedService.serviceType)
    setServiceDescription(selectedService.serviceDescription)
    setServicePrice(selectedService.servicePrice)
    setCurrency(selectedService.currency)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Service
  const handleDetailsService = () => {
    const selectedService = services.find(
      (service) => service.serviceID === selectedServices[0]
    )
    setServiceName(selectedService.serviceName)
    setServiceType(selectedService.serviceType)
    setServiceDescription(selectedService.serviceDescription)
    setServicePrice(selectedService.servicePrice)
    setCurrency(selectedService.currency)
    // setSubject(selectedService.subject)
  }

  const handleDetailService = (singleService) => {
    const selectedService = services.find(
      (service) => service.serviceID === singleService
    )
    setServiceName(selectedService.serviceName)
    setServiceType(selectedService.serviceType)
    setServiceDescription(selectedService.serviceDescription)
    setServicePrice(selectedService.servicePrice)
    setCurrency(selectedService.currency)
  }

  const handleDeleteService = async () => {
    const selectedService = { serviceID: selectedServices } // Change 'serviceIDs' to 'serviceID'

    try {
      const response = await fetch(`${API_BASE_URL}/api/Services/BulkDelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedService),
      })

      if (response.ok) {
        setServices((prevServices) =>
          prevServices.filter(
            (service) => !selectedServices.includes(service.serviceID)
          )
        )
        toast.success(`Selected Services deleted`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        setSelectedServices([])
      }
    } catch (error) {
      toast.error(`Error deleting Service: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
    setIsModalOpen(false)
    setServiceName('')
    setCategory(1)
  }

  // Show modal for editing Service
  const handleDeleteButtonClick = () => {
    const selectedService = services.find(
      (service) => service.serviceID === selectedServices[0]
    )
    setServiceName(selectedService.serviceName)
  }

  // Handle Service activation/deactivation
  const handleToggleServiceStatus = async (isActive) => {
    try {
      // Filter selected Services to include only those with both a service and an serviceName
      const validServices = selectedServices.filter((id) => {
        const service = services.find((service) => service.serviceID === id)
        return service.serviceName && service.serviceName
      })
      const selectedService = {
        serviceID: selectedServices,
        isActive: isActive,
      }
      // await Promise.all(
      const response = await fetch(`${API_BASE_URL}/api/Services/BulkActive`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedService),
      })

      if (response.ok) {
        // Update state
        setServices((prevServices) =>
          prevServices.map((service) =>
            validServices.includes(service.serviceID)
              ? { ...service, isActive }
              : service
          )
        )
        toast.success(`Selected Services updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })

        setSelectedServices([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating Service: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredServices = services.filter((service) => {
    const serviceDate = new Date(service.serviceCreatedDate)

    // Check if Service question includes the search term (case insensitive)
    const matchesSearchTerm =
      service.serviceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.serviceDescription
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())

    // Check date filtering conditions
    const matchesDateRange = (() => {
      if (fromDate && toDate) {
        // If both fromDate and toDate are set, filter by the range
        return (
          serviceDate >= new Date(fromDate) && serviceDate <= new Date(toDate)
        )
      } else if (fromDate) {
        // If only fromDate is set, filter from that date onward
        return serviceDate >= new Date(fromDate)
      } else if (toDate) {
        // If only toDate is set, filter up to that date
        return serviceDate <= new Date(toDate)
      }
      // If neither fromDate nor toDate is set, match all dates
      return true
    })()

    // Return true only if both search term and date range conditions are met
    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const [lastSelectedIndex, setLastSelectedIndex] = useState(null) // Tracks last clicked index for Shift-click functionality
  const handleCheckboxChange = (serviceID, index, event) => {
    setSelectedServices((prevSelected) => {
      let newSelection

      if (event.shiftKey && lastSelectedIndex !== null) {
        // Shift-click functionality: select a range
        const start = Math.min(lastSelectedIndex, index)
        const end = Math.max(lastSelectedIndex, index)

        // Get all Service IDs within the range
        const rangeSelection = services
          .slice(start, end + 1)
          .map((service) => service.serviceID)

        // Merge the new range selection with the previous selection
        newSelection = [...new Set([...prevSelected, ...rangeSelection])]
      } else {
        // Normal click: toggle selection
        newSelection = prevSelected.includes(serviceID)
          ? prevSelected.filter((id) => id !== serviceID)
          : [...prevSelected, serviceID]
      }

      // Check if the active button should be enabled
      const hasServiceWithBoth = newSelection.some((id) => {
        const selectedService = services.find(
          (service) => service.serviceID === id
        )
        return selectedService.serviceName && selectedService.serviceName
      })

      setIsActiveButtonEnabled(hasServiceWithBoth)

      // Update the last selected index for future Shift-click selections
      setLastSelectedIndex(index)

      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  // Calculate total pages based on filtered FAQs
  const totalPages = Math.ceil(filteredServices.length / itemsPerPage)
  // Calculate the index range of FAQs to display
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  // FAQs to be displayed on the current page
  const currentServices = filteredServices.reverse().slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    const endFAQ = Math.min(currentPage * itemsPerPage, totalServices)
    return `${endFAQ}/${totalServices}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  // Handle file input change
  const handleFileChange = (e) => {
    setServicePicUrl(e.target.files[0]) // Save the file to state
  }

  console.log(data)

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedServices.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsService}
                    title="Details"
                    disabled={selectedServices.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>
                  {/* {////////////////////////////////////////////////////////////////////////////////////////////} */}

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Service Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            {/* <h6 className="mb-0">Question:</h6> */}
                            <p className="ms-2 mb-0">{'Service Name: '}</p>
                            <p className="ms-2 mb-0">{serviceName}</p>
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">Answer:</h6> */}
                            <p className="ms-2 mb-0">{'Service Type: '}</p>
                            <p className="ms-2 mb-0">{serviceType}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">Answer:</h6> */}
                            <p className="ms-2 mb-0">
                              {'Service Description: '}
                            </p>
                            <p className="ms-2 mb-0">{serviceDescription}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">Answer:</h6> */}
                            <p className="ms-2 mb-0">{'Service Price: '}</p>
                            <p className="ms-2 mb-0">{servicePrice}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">Answer:</h6> */}
                            <p className="ms-2 mb-0">{'currency: '}</p>
                            <p className="ms-2 mb-0">{currency}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedServices.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            // aria-label="Close"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedServices.length === 1
                                ? ` '${serviceName}' record?`
                                : `${selectedServices.length} records?`}{' '}
                              {/* records? */}
                            </label>
                            <br />
                          </div>

                          {/* <div className="form-check">
                            <input
                              className="form-check-input fs-7"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label fs-7"
                              for="flexCheckDefault"
                            >
                              Never ask again
                            </label>
                          </div> */}
                        </div>

                        <div className="modal-footer">
                          {/* <button type="button" className="site-btn invisible">
                            Update changes
                          </button> */}
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteService}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as active"
                    onClick={() => handleToggleServiceStatus(true)}
                    disabled={
                      (selectedServices.length === 0 &&
                        !isActiveButtonEnabled) || // Disable if no Service has both question and serviceName
                      services
                        .filter((service) =>
                          selectedServices.includes(service.serviceID)
                        )
                        .every((service) => service.isActive) // Disable if all selected Services are already active
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-on"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as inactive"
                    onClick={() => handleToggleServiceStatus(false)}
                    disabled={
                      (selectedServices.length === 0 &&
                        !isActiveButtonEnabled) ||
                      services
                        .filter((service) =>
                          selectedServices.includes(service.serviceID)
                        )
                        .every((service) => !service.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-off"></i>
                    </span>
                  </button>
                </div>
              </div>

              {/* Add Question Modal /////////////////////////////////////////////////////////////////////////////////// */}
              <div
                id="QuestionmodelMake"
                tabIndex="-1"
                aria-labelledby="QuestionModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode ? handleEditService : handleCreateService
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="QuestionModalLabel">
                          {isEditMode ? 'Edit Service' : 'Add New Service'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        {/* Image upload input */}
                        <div className="mb-3">
                          <label htmlFor="serviceImage" className="form-label">
                            Upload Service Image
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={handleFileChange}
                            accept="image/*" // Optional: restrict to image files only
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Service Name
                          </label>
                          <input
                            type="text"
                            placeholder="Service Name"
                            className="form-control"
                            value={serviceName}
                            onChange={(e) => setServiceName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Service Type
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={serviceType}
                            onChange={(e) => setServiceType(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Service Description
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={serviceDescription}
                            onChange={(e) =>
                              setServiceDescription(e.target.value)
                            }
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Service Price
                          </label>
                          <input
                            type="price"
                            className="form-control"
                            value={servicePrice}
                            onChange={(e) => setServicePrice(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Currency
                          </label>
                          <input
                            type="price"
                            className="form-control"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn site-btn"
                          onClick={resetModal}
                        >
                          {isEditMode ? 'Update Service' : 'Create Service'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Search Input */}
          <div className="col-lg-3 col-md-6">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg- col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    // data-bs-toggle="modal"
                    // data-bs-target="#quotesmodelMake"
                    onClick={handleResetFilter}
                    // disabled={selectedServices.length > 0}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="font">
                  Sr No.
                </th>
                <th scope="col">Services Name </th>
                <th scope="col">Services Value</th>
                <th scope="col">Services Description</th>
                <th scope="col">Services Actions</th>
              </tr>
            </thead>

            <tbody>
              {data?.map((service, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td
                    className="table-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#ServicemodelMake"
                  >
                    {service.title}
                  </td>

                  <td>{service.serviceCount}</td>
                  <td>{service.description}</td>
                  <td>
                    <button
                      type="button"
                      className="details-btn"
                      // data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                      data-bs-toggle="modal"
                      data-bs-target="#ServicemodelMake"
                    >
                      <i className="fa-solid fa-pencil" title="Edit"></i>
                    </button>
                    <div
                      className="modal fade"
                      id="ServicemodelMake"
                      tabindex="-1"
                      aria-labelledby="ServiceModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="ServiceModalLabel">
                              Add New Services
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <form>
                              <div className="mb-3">
                                <label for="txtMake" className="form-label">
                                  Services Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtMake"
                                  placeholder="Services Name"
                                />
                              </div>

                              <div className="mb-3">
                                <label for="txtMake" className="form-label">
                                  Services Value
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtMake"
                                  placeholder="Services Value"
                                />
                              </div>

                              <div className="mb-3">
                                <label for="txtMake" className="form-label">
                                  Services Description
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtMake"
                                  placeholder="Services Description"
                                />
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="cancel-btn"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" className="btn site-btn">
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="details-btn"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash" title="Delete"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        {/* Service List */}
        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={selectedServices.length === services.length}
                    onChange={() =>
                      setSelectedServices(
                        selectedServices.length === services.length
                          ? []
                          : services.map((service) => service.serviceID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Service Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Discription
                </th>
                {/* <th scope="col">Question</th>
                <th scope="col">Answer</th> */}
                <th scope="col">Service Price</th>
                <th scope="col">Currency</th>
                <th scope="col">Created on</th>
              </tr>
            </thead>
            <tbody>
              {/* {data.map((service, index) => ( */}
              {currentServices.map((service, index) => (
                <tr
                  className="faqs-table-row"
                  title="Click to view details"
                  key={service.serviceID}
                  style={service.isActive ? { backgroundColor: '#D3D3D3' } : {}}
                >
                  <td>
                    {' '}
                    <input
                      type="checkbox"
                      checked={selectedServices.includes(service.serviceID)}
                      onChange={(e) =>
                        handleCheckboxChange(service.serviceID, index, e)
                      }
                    />
                  </td>
                  <td

                  // className="table-btn"
                  // data-bs-toggle="modal"
                  // data-bs-target="#DetailsCard"
                  // onClick={() => handleDetailFAQ(faq.faQsID)}
                  >
                    {serialNumber + index + 1}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailService(service.serviceID)}
                  >
                    {service.serviceName}
                  </td>
                  {/* <td> {service.serviceDescription}</td> */}
                  <td>
                    {' '}
                    {service.serviceDescription?.length > 50
                      ? service.serviceDescription.substring(0, 50) + '...'
                      : service.serviceDescription}
                  </td>
                  <td> {service.servicePrice}</td>
                  <td> {service.currency}</td>
                  <td> {service.createdDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <nav aria-label="Page navigation example">
          <div className="container text-center align-content-center d-flex">
            <div>Showing Services: {calculateDisplayRange()}</div>
          </div>
          <ul className="pagination justify-content-end me-1">
            {/* <li>Showing Services: {calculateDisplayRange()}</li> */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {[...Array(totalPages)].map((_, index) => (
              <li
                className={`page-item ${
                  currentPage === index + 1 ? 'active' : ''
                }`}
                key={index}
              >
                <button
                  className="page-link"
                  onClick={() => handleSerialNumberChange(index)}
                >
                  {index + 1}
                </button>
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Services
export const getAllServices = async () => {
  const response = await fetch(`${API_BASE_URL}/api/Services/GetAllDashService`)
  // const response = await fetch(`${API_BASE_URL}/api/FAQs/GetAllDashFAQs`)
  return response.json()
}
