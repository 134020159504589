import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'

const Home = () => {
  return (
    <>
      {/* <div className="section-1"> */}
      {/* <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="left">
              <h3>Good Morning, Anna!</h3>
              <p>Here's what's happening with your store today.</p>
            </div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="right d-flex justify-content-between align-items-center">
              <label for="From">From: </label>
              <input
                type="date"
                className="form-control"
                id="From"
                placeholder="date"
              />
              <label for="To">To: </label>
              <input
                type="date"
                className="form-control"
                id="To"
                placeholder="date"
              />

              <div>
                <a href="#" className="site-btn">
                  Add Product
                </a>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      {/* <div className="col-lg-3 col-md-3 p-0">
        <Navbar />
      </div> */}

      <div className="section-2">
        <div className="card-main row justify-content-between align-items-center">
          <div className="col-lg-3 col-md-6 p-2">
            <div className="card-1 card-animate" id="card-a">
              <div className="card-head d-flex justify-content-between align-items-center">
                <div className="card-1-left">
                  <h3>Number of Quotes</h3>
                </div>
                <div className="card-1-right d-flex justify-content-between align-items-center">
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  <p>+6.4 %</p>
                </div>
              </div>
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="card-body-left">
                  <a href="#">View net Quotes</a>
                </div>
                <div className="card-body-right">
                  <i className="fa-solid fa-circle-dollar-to-slot"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 p-2">
            <div className="card-1 card-animate" id="card-b">
              <div className="card-head d-flex justify-content-between align-items-center">
                <div className="card-1-left">
                  <h3>Number of Bookings</h3>
                </div>
                <div className="card-1-right d-flex justify-content-between align-items-center">
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  <p>-3.5%</p>
                </div>
              </div>
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="card-body-left">
                  <a href="#">View all Bookings</a>
                </div>
                <div className="card-body-right bg-color">
                  <i className="fa-solid fa-circle-dollar-to-slot"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 p-2">
            <div className="card-1 card-animate" id="card-c">
              <div className="card-head d-flex justify-content-between align-items-center">
                <div className="card-1-left">
                  <h3>Number of customer</h3>
                </div>
                <div className="card-1-right d-flex justify-content-between align-items-center">
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  <p>+2.8%</p>
                </div>
              </div>
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="card-body-left">
                  <a href="#">View net customer</a>
                </div>
                <div className="card-body-right bg-color-2">
                  <i className="fa-solid fa-circle-dollar-to-slot"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 p-2">
            <div className="card-1 card-animate" id="card-d">
              <div className="card-head d-flex justify-content-between align-items-center">
                <div className="card-1-left">
                  <h3>Total Earnings</h3>
                </div>
                <div className="card-1-right d-flex justify-content-between align-items-center">
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  <p>+16.24 %</p>
                </div>
              </div>
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="card-body-left">
                  <a href="#">View net earnings</a>
                </div>
                <div className="card-body-right bg-color-3">
                  <i className="fa-solid fa-circle-dollar-to-slot"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="revenue-div row">
          <div className="col-lg-8 col-md-12">
            <div className="left-side">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Revenue</h3>
                <div className="d-flex justify-content-between">
                  <div className="min-btn">
                    <a href="#">ALL</a>
                  </div>
                  <div className="min-btn">
                    <a href="#">1M</a>
                  </div>
                  <div className="min-btn">
                    <a href="#">6M</a>
                  </div>
                  <div className="min-btn colorless">
                    <a href="#">1Y</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-6 pe-lg-0 pe-md-0">
                <div className="tab-1">
                  <h3>7,585</h3>
                  <p>Order</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 p-lg-0 ps-md-0">
                <div className="tab-1">
                  <h3>$22.89k</h3>
                  <p>Earnings</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 p-lg-0 pe-md-0">
                <div className="tab-1">
                  <h3>367</h3>
                  <p>Refunds</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 ps-lg-0 ps-md-0">
                <div className="tab-1">
                  <h3 className="green-color">18.92%</h3>
                  <p>Conversation Ratio</p>
                </div>
              </div>
            </div>

            <div className="map-div">
              <div className="simple-bar-chart">
                <div
                  className="item"
                  // style="--clr: rgba(10, 179, 156, 0.9); --val: 80"
                >
                  <div className="label">Jan</div>
                  <div className="value">80%</div>
                </div>

                <div
                  className="item"
                  // style="--clr: rgba(10, 179, 156, 0.9); --val: 35"
                >
                  <div className="label">Feb</div>
                  <div className="value">50%</div>
                </div>

                <div
                  className="item"
                  // style="--clr: rgba(10, 179, 156, 0.9); --val: 45"
                >
                  <div className="label">Mar</div>
                  <div className="value">100%</div>
                </div>

                <div
                  className="item"
                  // style="--clr: rgba(10, 179, 156, 0.9); --val: 15"
                >
                  <div className="label">Apr</div>
                  <div className="value">15%</div>
                </div>

                <div
                  className="item"
                  // style="--clr: rgba(10, 179, 156, 0.9); --val: 5"
                >
                  <div className="label">May</div>
                  <div className="value">1%</div>
                </div>

                <div
                  className="item"
                  // style="--clr: rgba(10, 179, 156, 0.9); --val: 100"
                >
                  <div className="label">Jun</div>
                  <div className="value">90%</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="right-side" id="right-side-color">
              <div className="border-bottom d-flex justify-content-between align-items-center">
                <h4>Sales by Locations</h4>
                <div className="Export-btn">
                  <a href="#">Export Report</a>
                </div>
              </div>
              <div className="map-photo">
                <img src="content/img/right-side-map.png" alt="map" />
              </div>
              <div className="range">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Canada</p>
                  <output>75%</output>
                </div>
                <input
                  type="range"
                  value="75%"
                  min="1"
                  max="100"
                  oninput="this.nextElementSibling.value = this.value"
                />
              </div>
              <div className="range">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Russia</p>
                  <output>82%</output>
                </div>
                <input
                  type="range"
                  value="82%"
                  min="1"
                  max="100"
                  oninput="this.nextElementSibling.value = this.value"
                />
              </div>
              <div className="range">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Greenland</p>
                  <output>47%</output>
                </div>
                <input
                  type="range"
                  value="47%"
                  min="1"
                  max="100"
                  oninput="this.nextElementSibling.value = this.value"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section-3">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6">
            <div className="section-3-left">
              <div className="section-3-left-head d-flex justify-content-between align-items-center">
                <h4>Best Selling Products</h4>
                <div>
                  <div className="dropdown">
                    <button
                      className="section-3-btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <a href="#">
                        <span>Sort by:</span> Today
                        <i className="fa-solid fa-chevron-down"></i>
                      </a>
                    </button>
                    <ul className="dropdown-menu section-3-ul">
                      <li>
                        <a href="#">Today</a>
                      </li>
                      <li>
                        <a href="#">Yesterday</a>
                      </li>
                      <li>
                        <a href="#"> Last 7 Days</a>
                      </li>
                      <li>
                        <a href="#"> Last 30 Days</a>
                      </li>
                      <li>
                        <a href="#">This Month</a>
                      </li>
                      <li>
                        <a href="#"> Last Month</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="section-3-left-table">
                <table className="table table-sm">
                  <tr>
                    <td>
                      <a href="#">Branded T-Shirts</a>
                      <p>24 Apr 2021</p>
                    </td>
                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">510</a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="#">Bentwood Chair</a>
                      <p>19 Mar 2021</p>
                    </td>
                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">
                        <span>Out of stock</span>
                      </a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="#">Borosil Paper Cup</a>
                      <p>01 Mar 2021</p>
                    </td>
                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">510</a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="#">One Seater Sofa</a>
                      <p>24 Apr 2021</p>
                    </td>
                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">
                        <span>Out of stock</span>
                      </a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="section-3-left">
              <div className="section-3-left-head d-flex justify-content-between align-items-center">
                <h4>Top Sellers</h4>
                <div>
                  <div className="dropdown">
                    <button
                      className="section-3-btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <a href="#">
                        Report <i className="fa-solid fa-chevron-down"></i>
                      </a>
                    </button>
                    <ul className="dropdown-menu section-3-ul">
                      <li>
                        <a href="#">Today</a>
                      </li>
                      <li>
                        <a href="#">Yesterday</a>
                      </li>
                      <li>
                        <a href="#"> Last 7 Days</a>
                      </li>
                      <li>
                        <a href="#"> Last 30 Days</a>
                      </li>
                      <li>
                        <a href="#">This Month</a>
                      </li>
                      <li>
                        <a href="#"> Last Month</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="section-3-left-table">
                <table className="table table-sm">
                  <tr>
                    <td>
                      <a href="#">Branded T-Shirts</a>
                      <p>24 Apr 2021</p>
                    </td>

                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">510</a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="#">Bentwood Chair</a>
                      <p>19 Mar 2021</p>
                    </td>
                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">
                        <span>Out of stock</span>
                      </a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="#">Borosil Paper Cup</a>
                      <p>01 Mar 2021</p>
                    </td>
                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">510</a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="#">One Seater Sofa</a>
                      <p>24 Apr 2021</p>
                    </td>
                    <td>
                      <a href="#">$29.00</a>
                      <p>Price</p>
                    </td>
                    <td>
                      <a href="#">62</a>
                      <p>Orders</p>
                    </td>
                    <td>
                      <a href="#">
                        <span>Out of stock</span>
                      </a>
                      <p>Stock</p>
                    </td>
                    <td>
                      <a href="#">$1,798</a>
                      <p>Amount</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Home
