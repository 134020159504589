import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Cookies from 'js-cookie'
import { API_BASE_DOMAIN, API_BASE_URL } from '../Config/Config'

const ProtectedRoutes = () => {
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      // console.log('Cookie Value:', cookieValue)

      if (!cookieValue) {
        setLoading(false)
        return
      }

      let parsedCookie
      try {
        parsedCookie = JSON.parse(cookieValue)
      } catch (error) {
        console.error('Error parsing cookie:', error)
        setLoading(false)
        return
      }

      const { tokenID, authToken, userId } = parsedCookie
      // console.log(parsedCookie)

      // console.log('tokenID:', tokenID)
      // console.log('authToken:', authToken)
      // console.log('userId:', userId)

      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Token/GetTokenByUserID/${userId}`
        )
        const data = await response.json()
        // console.log(data)

        const apiToken = data.jwtToken

        if (authToken === apiToken) {
          setIsAuthenticated(true)
        } else {
          setIsAuthenticated(false)
        }
      } catch (error) {
        console.error('Error fetching the token from API:', error)
        setIsAuthenticated(false)
      } finally {
        setLoading(false)
      }
    }

    verifyToken()
  }, [])

  // Show a loading screen while the token is being verified
  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center vh-100">
        <p>Loading...</p>
      </div>
    )
  }

  // Redirect to login if not authenticated
  // return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes
