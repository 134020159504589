import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

const FAQs = () => {
  const data = useLoaderData()
  const [faqs, setFaqs] = useState(data)
  const [selectedFAQs, setSelectedFAQs] = useState([])
  const [answer, setAnswer] = useState('')
  const [question, setQuestion] = useState('')
  const [category, setCategory] = useState(1)
  const [token, setToken] = useState({})

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalFAQs = faqs.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      console.log(parsedCookie)
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  // Handle FAQ creation
  const handleCreateFAQ = async (e) => {
    e.preventDefault()
    const newFAQ = { question, answer, category }

    try {
      const response = await fetch(`${API_BASE_URL}/api/FAQs/CreateFAQs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(newFAQ),
      })

      if (response.ok) {
        const createdFAQ = await response.json()
        setFaqs((prevFaqs) => [...prevFaqs, createdFAQ])
        toast.success(`FAQ '${createdFAQ.question}' created`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create FAQ.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new FAQ
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setQuestion('')
    setAnswer('')
    setCategory(1)
    setIsEditMode(false)
    setSelectedFAQs([])
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      setQuestion('')
      setAnswer('')
      setCategory('')
      setIsEditMode(false)
      setIsModalOpen(false)
    }
  }

  // Handle FAQ update
  const handleEditFAQ = async (e) => {
    e.preventDefault()
    const selectedFAQ = faqs.find((faq) => faq.faQsID === selectedFAQs[0])
    const updatedFAQ = { ...selectedFAQ, question, answer, category }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/FAQs/UpdateFAQs/${selectedFAQ.faQsID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedFAQ),
        }
      )

      if (response.ok) {
        const updatedFaqData = await response.json()
        setFaqs((prevFaqs) =>
          prevFaqs.map((faq) =>
            faq.faQsID === updatedFaqData.faQsID ? updatedFaqData : faq
          )
        )
        toast.success(`FAQ '${selectedFAQ.question}' updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update FAQ.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating FAQ: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing FAQ
  const handleEditButtonClick = () => {
    const selectedFAQ = faqs.find((faq) => faq.faQsID === selectedFAQs[0])
    setQuestion(selectedFAQ.question)
    setAnswer(selectedFAQ.answer)
    setCategory(selectedFAQ.category)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details FAQ
  const handleDetailsFAQ = () => {
    const selectedFAQ = faqs.find((faq) => faq.faQsID === selectedFAQs[0])
    setQuestion(selectedFAQ.question)
    setAnswer(selectedFAQ.answer)
  }

  const handleDetailFAQ = (singleFaq) => {
    const selectedFAQ = faqs.find((faq) => faq.faQsID === singleFaq)
    setQuestion(selectedFAQ.question)
    setAnswer(selectedFAQ.answer)
  }

  // Handle FAQ deletion
  const handleDeleteFAQ = async () => {
    const selectedFAQ = { fAQsIDs: selectedFAQs }
    console.log(token.authToken)

    try {
      const response = await fetch(`${API_BASE_URL}/api/FAQs/BulkDelete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(selectedFAQ),
      })

      if (response.ok) {
        setFaqs((prevFaqs) =>
          prevFaqs.filter((faq) => !selectedFAQs.includes(faq.faQsID))
        )
        toast.success(`Selected FAQs deleted`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        setSelectedFAQs([])
      }
    } catch (error) {
      toast.error(`Error deleting FAQ: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
    setIsModalOpen(false)
    setQuestion('')
    setCategory(1)
  }

  // Show modal for deleting FAQ
  const handleDeleteButtonClick = () => {
    const selectedFAQ = faqs.find((faq) => faq.faQsID === selectedFAQs[0])
    setQuestion(selectedFAQ.question)
  }

  // Handle FAQ activation/deactivation
  const handleToggleFAQStatus = async (isActive) => {
    try {
      const validFAQs = selectedFAQs.filter((id) => {
        const faq = faqs.find((faq) => faq.faQsID === id)
        return faq.question && faq.answer
      })
      const selectedFAQ = { fAQsIDs: selectedFAQs, isActive: isActive }
      // await Promise.all(
      const response = await fetch(`${API_BASE_URL}/api/FAQs/BulkActive`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedFAQ),
      })

      if (response.ok) {
        // Update state
        setFaqs((prevFaqs) =>
          prevFaqs.map((faq) =>
            validFAQs.includes(faq.faQsID) ? { ...faq, isActive } : faq
          )
        )
        toast.success(`Selected FAQs updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })

        setSelectedFAQs([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating FAQ: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredFAQs = faqs.filter((faq) => {
    const faqDate = new Date(faq.faqCreatedDate)
    const matchesSearchTerm =
      faq.question?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer?.toLowerCase().includes(searchTerm.toLowerCase())
    const matchesDateRange = (() => {
      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate)
        const toDateObj = new Date(fromDate)
        return (
          faqDate.toDateString() === fromDateObj.toDateString() &&
          faqDate.toDateString() === toDateObj.toDateString()
        )
      } else if (fromDate) {
        const fromDateObj = new Date(fromDate)
        return faqDate.toDateString() === fromDateObj.toDateString()
      }
      return true
    })()
    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (faqId) => {
    setSelectedFAQs((prevSelected) => {
      let newSelection = prevSelected.includes(faqId)
        ? prevSelected.filter((id) => id !== faqId)
        : [...prevSelected, faqId]
      const hasFAQWithBoth = newSelection.some((id) => {
        const selectedFAQ = faqs.find((faq) => faq.faQsID === id)
        return selectedFAQ.question && selectedFAQ.answer
      })

      setIsActiveButtonEnabled(hasFAQWithBoth)
      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * itemsPerPage)
  }

  const totalPages = Math.ceil(filteredFAQs.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentFAQs = filteredFAQs.reverse().slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentFAQs.length}/${totalFAQs}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  return (
    <>
      <div className="section-1">
        {/* component internal nav */}
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap mt-1">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Edit"
                    onClick={handleEditButtonClick}
                    disabled={selectedFAQs.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsFAQ}
                    title="Details"
                    disabled={selectedFAQs.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Details"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Question: '}</p>
                            <p className="ms-2 mb-0">{question}</p>
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Answer: '}</p>
                            <p className="ms-2 mb-0">{answer}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedFAQs.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Are you sure to want to delete{' '}
                              {selectedFAQs.length === 1
                                ? ` '${question}' record?`
                                : `${selectedFAQs.length} records?`}{' '}
                              {/* records? */}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteFAQ}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as active"
                    onClick={() => handleToggleFAQStatus(true)}
                    disabled={
                      (selectedFAQs.length === 0 && !isActiveButtonEnabled) || // Disable if no FAQ has both question and answer
                      faqs
                        .filter((faq) => selectedFAQs.includes(faq.faQsID))
                        .every((faq) => faq.isActive) // Disable if all selected FAQs are already active
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-on"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as inactive"
                    onClick={() => handleToggleFAQStatus(false)}
                    disabled={
                      (selectedFAQs.length === 0 && !isActiveButtonEnabled) ||
                      faqs
                        .filter((faq) => selectedFAQs.includes(faq.faQsID))
                        .every((faq) => !faq.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-off"></i>
                    </span>
                  </button>
                </div>
              </div>

              {/* Add Question Modal /////////////////////////////////////////////////////////////////////////////////// */}
              <div
                id="QuestionmodelMake"
                tabIndex="-1"
                aria-labelledby="QuestionModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={isEditMode ? handleEditFAQ : handleCreateFAQ}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="QuestionModalLabel">
                          {isEditMode ? 'Edit Question' : 'Add New Question'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Question
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Answer
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update FAQ' : 'Create FAQ'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-5 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ List */}
        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      selectedFAQs.length === faqs.length && faqs.length >= 1
                    }
                    onChange={() =>
                      setSelectedFAQs(
                        selectedFAQs.length === faqs.length
                          ? []
                          : faqs.map((faq) => faq.faQsID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Question
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Answer
                </th>
                <th scope="col">Created on</th>
              </tr>
            </thead>
            <tbody>
              {currentFAQs.map((faq, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${faq.isActive ? 'Active Item' : 'Inactive Item'}`}
                  key={faq.faQsID}
                  style={faq.isActive ? { backgroundColor: '#D3D3D3' } : {}}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedFAQs.includes(faq.faQsID)}
                      onChange={(e) => handleCheckboxChange(faq.faQsID)}
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailFAQ(faq.faQsID)}
                  >
                    {faq.question?.length > 50
                      ? faq.question.substring(0, 50) + '...'
                      : faq.question}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() => handleDetailFAQ(faq.faQsID)}
                  >
                    {faq.answer?.length > 100
                      ? faq.answer.substring(0, 100) + '...'
                      : faq.answer}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() => handleDetailFAQ(faq.faQsID)}
                  >
                    {faq.faqCreatedDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing FAQs: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default FAQs

export const getAllFAQS = async () => {
  const response = await fetch(`${API_BASE_URL}/api/FAQs/GetAllDashFAQs`)
  return response.json()
}
