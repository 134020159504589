import React, { useEffect, useState } from 'react'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

function DispatchedAssignedBookings() {
  const [dispatchedAssignedBookings, setDispatchedAssignedBookings] = useState(
    []
  )
  const [
    selectedDispatchedAssignedBookings,
    setSelectedDispatchedAssignedBookings,
  ] = useState([])
  const [
    detailsDispatchedAssignedBookings,
    setDetailsDispatchedAssignedBookings,
  ] = useState([])
  const [drivers, setDrivers] = useState([])
  const [driverName, setDriverName] = useState()

  const [bookingDetails, setBookingDetails] = useState()
  const [inboundterminals, setInboundTerminals] = useState([])
  const [outboundterminals, setOutboundTerminals] = useState([])

  const [isAssignDrivermodal, setIsAssignDrivermodal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [isDetailsModal, setIsDetailsModal] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )

  const totalDispatchedAssignedBookings = dispatchedAssignedBookings.length
  useEffect(() => {
    const fetchDispatchedAssignedBookings = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/GetReceivingAssignedBookings`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token.authToken,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setDispatchedAssignedBookings(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchDispatchedAssignedBookings()
  }, [selectedDispatchedAssignedBookings, token.authToken])

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Driver/GetAllDrivers`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token.authToken,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setDrivers(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchDrivers()
  }, [selectedDispatchedAssignedBookings, token.authToken])

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  useEffect(() => {
    const fetchTerminals = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Terminal/GetAllbyAirPort/${detailsDispatchedAssignedBookings?.airportID}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        const inbounds = data.filter(
          (terminal) => terminal.terminalType === 'InBound'
        )
        const outbounds = data.filter(
          (terminal) => terminal.terminalType === 'OutBound'
        )
        setInboundTerminals(inbounds)
        setOutboundTerminals(outbounds)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
    fetchTerminals()
  }, [detailsDispatchedAssignedBookings?.airportID])

  // Handle DispatchedAssignedBooking creation
  const handleAssignDispatchedAssignedBooking = async (e) => {
    e.preventDefault()
    const selectedDispatchedAssignedBooking = dispatchedAssignedBookings.find(
      (dispatchedAssignedBooking) =>
        dispatchedAssignedBooking.bookingID ===
        selectedDispatchedAssignedBookings[0]
    )
    const newDispatchedAssignedBooking = {
      DriverID: Number(driverName),
      BookingID: selectedDispatchedAssignedBooking.bookingID,
      CreatedDate: null,
    }
    console.log(newDispatchedAssignedBooking)

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Booking/AssignReceivingDriver`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: token.authToken,
          },
          body: JSON.stringify(newDispatchedAssignedBooking),
        }
      )

      if (response.ok) {
        const createdDispatchedAssignedBooking = await response.json()
        toast.success(
          `DispatchedAssignedBooking '${createdDispatchedAssignedBooking.dispatchedAssignedBookingName}' created`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to Assign.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new DispatchedAssignedBooking
  const handleAddButtonClick = () => {
    setIsAssignDrivermodal(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsAssignDrivermodal(false)
    setIsEditModal(false)
    setIsDetailsModal(false)
    setSelectedDispatchedAssignedBookings([])
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle DispatchedAssignedBooking update
  const handleEditDispatchedAssignedBooking = async (e) => {
    e.preventDefault()
    const selectedDispatchedAssignedBooking = dispatchedAssignedBookings.find(
      (dispatchedAssignedBooking) =>
        dispatchedAssignedBooking.bookingID ===
        selectedDispatchedAssignedBookings[0]
    )

    // console.log(currentDispatchedAssignedBookings, '===itsme===')
    const updatedDispatchedAssignedBooking = {
      ...selectedDispatchedAssignedBooking,
      endDateTime: detailsDispatchedAssignedBookings.endDateTime,
      inboundTerminalID: detailsDispatchedAssignedBookings.inboundTerminalID,
      outboundTerminalID: detailsDispatchedAssignedBookings.outboundTerminalID,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/DispatchedAssignedBooking/Update/${selectedDispatchedAssignedBooking.bookingID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedDispatchedAssignedBooking),
        }
      )

      if (response.ok) {
        toast.success(
          `Received UnAssigned Booking '${selectedDispatchedAssignedBooking.dispatchedAssignedBookingName}' Updated`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to update DispatchedAssignedBooking.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating DispatchedAssignedBooking: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing DispatchedAssignedBooking
  const handleEditButtonClick = () => {
    const selectedDispatchedAssignedBooking = dispatchedAssignedBookings.find(
      (dispatchedAssignedBooking) =>
        dispatchedAssignedBooking.bookingID ===
        selectedDispatchedAssignedBookings[0]
    )

    setIsEditModal(selectedDispatchedAssignedBooking)
    setBookingDetails(selectedDispatchedAssignedBooking)
  }

  // handle for Details DispatchedAssignedBooking
  const handleDetailsDispatchedAssignedBooking = () => {
    const selectedDispatchedAssignedBooking = dispatchedAssignedBookings.find(
      (dispatchedAssignedBooking) =>
        dispatchedAssignedBooking.bookingID ===
        selectedDispatchedAssignedBookings[0]
    )
    setIsDetailsModal(true)
    setDetailsDispatchedAssignedBookings(selectedDispatchedAssignedBooking)
  }

  const handleDetailDispatchedAssignedBooking = (
    singleDispatchedAssignedBooking
  ) => {
    const selectedDispatchedAssignedBooking = dispatchedAssignedBookings.find(
      (dispatchedAssignedBooking) =>
        dispatchedAssignedBooking.bookingID === singleDispatchedAssignedBooking
    )
    setDetailsDispatchedAssignedBookings(selectedDispatchedAssignedBooking)
    setIsDetailsModal(true)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredDispatchedAssignedBookings = dispatchedAssignedBookings.filter(
    (dispatchedAssignedBooking) => {
      const matchesSearchTerm = dispatchedAssignedBooking.quoteCode
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())

      const dispatchedAssignedBookingDate = new Date(
        dispatchedAssignedBooking.createdDate
      )

      const matchesDateRange = (() => {
        const fromDateObj = fromDate ? new Date(fromDate) : null
        const toDateObj = toDate ? new Date(toDate) : null

        // Helper function to format date to 'dd/mm/yyyy'
        const formatDate = (date) => {
          if (!date) return null
          const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
          const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
          const year = date.getFullYear()
          return `${day}/${month}/${year}`
        }

        const formattedDispatchedAssignedBookingDate = formatDate(
          dispatchedAssignedBookingDate
        )
        const formattedFromDate = formatDate(fromDateObj)
        const formattedToDate = formatDate(toDateObj)

        if (fromDateObj && toDateObj) {
          return (
            formattedDispatchedAssignedBookingDate >= formattedFromDate &&
            formattedDispatchedAssignedBookingDate <= formattedToDate
          ) // Inclusive range
        } else if (fromDateObj) {
          return formattedDispatchedAssignedBookingDate === formattedFromDate // Exact match for fromDate
        }

        return true // If no fromDate or toDate, show all data
      })()

      return matchesSearchTerm && matchesDateRange
    }
  )

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (bookingID) => {
    setSelectedDispatchedAssignedBookings((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(bookingID)
        ? prevSelected.filter((id) => id !== bookingID)
        : [...prevSelected, bookingID]

      return newSelection
    })
  }
  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(
    filteredDispatchedAssignedBookings.length / itemsPerPage
  )
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentDispatchedAssignedBookings = filteredDispatchedAssignedBookings
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentDispatchedAssignedBookings.length}/${totalDispatchedAssignedBookings}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  // console.log(currentDispatchedAssignedBookings)
  console.log(bookingDetails)
  // console.log(dispatchedAssignedBookings)

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleAddButtonClick}
                    disabled={selectedDispatchedAssignedBookings.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="assignDriver"
                    >
                      <i
                        className="fa-regular fa-calendar-check"
                        title="Calender"
                      ></i>
                    </span>
                  </button>
                </div>
                <div
                  id="assignDriver"
                  tabIndex="-1"
                  aria-labelledby="assignDriver"
                  className={`modal fade ${
                    isAssignDrivermodal ? 'show d-block' : ''
                  }`}
                  onClick={handleBackdropClick}
                  aria-hidden={!isAssignDrivermodal}
                  style={
                    isAssignDrivermodal
                      ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                      : {}
                  }
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="modal-content">
                      <form
                        id="add-new-user-form"
                        onSubmit={handleAssignDispatchedAssignedBooking}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" id="assignDriver">
                            Assign Driver
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Assign Driver
                            </label>
                            <select
                              name="faqPerPage"
                              onChange={(e) => setDriverName(e.target.value)}
                              className=" form-control"
                            >
                              <option disabled>Select Driver</option>
                              {drivers.map((driver) => (
                                <>
                                  <option
                                    key={driver.driverID}
                                    value={driver.driverID}
                                  >
                                    {driver.driverName}
                                  </option>
                                </>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn site-btn">
                            Assign Booking
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedDispatchedAssignedBookings.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>

                <div
                  id="DispatchedAssignedBookingmodelMake"
                  tabIndex="-1"
                  aria-labelledby="DispatchedAssignedBookingModalLabel"
                  className={`modal fade ${isEditModal ? 'show d-block' : ''}`}
                  onClick={handleBackdropClick}
                  aria-hidden={!isEditModal}
                  style={
                    isEditModal
                      ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                      : {}
                  }
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="modal-content">
                      <form
                        id="add-new-user-form"
                        onSubmit={handleEditDispatchedAssignedBooking}
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="DispatchedAssignedBookingModalLabel"
                          >
                            Edit Booking
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Booking End Date
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              value={bookingDetails?.endDateTime}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              InBound Terminal
                            </label>
                            <select
                              className="form-control"
                              value={bookingDetails?.inboundTerminalID}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                            >
                              {inboundterminals.map((inbound) => (
                                <option>{inbound.terminalName}</option>
                              ))}
                            </select>
                          </div>{' '}
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              OutBound Terminal
                            </label>
                            <select
                              className="form-control"
                              value={bookingDetails?.outboundTerminalID}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                            >
                              {outboundterminals.map((outbound) => (
                                <option>{outbound.terminalName}</option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Product Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={bookingDetails?.productName}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Product Description
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={bookingDetails?.productDescription}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn site-btn">
                            Update UnAssigned Booking
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsDispatchedAssignedBooking}
                    title="Details"
                    disabled={selectedDispatchedAssignedBookings.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isDetailsModal ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isDetailsModal}
                    style={
                      isDetailsModal
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Booking Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Quote Code: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.quoteCode}
                            </p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Customer Name: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.customerName}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Customer Email: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.customerEmail}
                            </p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Phone Number: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.phoneNumber}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Company Name: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.companyName}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Airport Name: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.airportName}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Booking Start Time: '}
                            </p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.startDateTime}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Booking End Time: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.endDateTime}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Flying From: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.flyingFrom}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Flying To: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.flyingTo}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Created Date: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsDispatchedAssignedBookings.createdDate}
                            </p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      dispatchedAssignedBookings.length <= 0
                        ? false
                        : selectedDispatchedAssignedBookings.length ===
                          dispatchedAssignedBookings.length
                      // dispatchedAssignedBookings.length >= 1
                      // selectedDispatchedAssignedBookings.length === dispatchedAssignedBookings.length ? true : false
                      // dispatchedAssignedBookings.length
                    }
                    onChange={() =>
                      setSelectedDispatchedAssignedBookings(
                        selectedDispatchedAssignedBookings.length ===
                          dispatchedAssignedBookings.length
                          ? []
                          : dispatchedAssignedBookings.map(
                              (dispatchedAssignedBooking) =>
                                dispatchedAssignedBooking.bookingID
                            )
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Quote Code
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Customer Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Customer Email
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Phone Number
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Company Name
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Airport Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  startDateTime
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  endDateTime
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  flyingFrom
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  flyingTo
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentDispatchedAssignedBookings.map(
                (dispatchedAssignedBooking, index) => (
                  <tr
                    className="faqs-table-row"
                    title={`${
                      dispatchedAssignedBooking.isActive
                        ? 'Active Item'
                        : 'Inactive Item'
                    }`}
                    key={dispatchedAssignedBooking.bookingID}
                    style={
                      dispatchedAssignedBooking.isActive
                        ? { backgroundColor: '#D3D3D3' }
                        : {}
                    }
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedDispatchedAssignedBookings.includes(
                          dispatchedAssignedBooking.bookingID
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(
                            dispatchedAssignedBooking.bookingID
                          )
                        }
                      />
                    </td>
                    <td>{serialNumber + index + 1}</td>
                    <td
                      data-bs-toggle="modal"
                      data-bs-target="#DetailsCard"
                      className="table-btn"
                      onClick={() =>
                        handleDetailDispatchedAssignedBooking(
                          dispatchedAssignedBooking.bookingID
                        )
                      }
                    >
                      {dispatchedAssignedBooking.quoteCode}
                    </td>
                    <td
                      data-bs-toggle="modal"
                      data-bs-target="#DetailsCard"
                      className="table-btn"
                      onClick={() =>
                        handleDetailDispatchedAssignedBooking(
                          dispatchedAssignedBooking.bookingID
                        )
                      }
                    >
                      {dispatchedAssignedBooking.customerName}
                    </td>
                    <td>{dispatchedAssignedBooking.customerEmail}</td>
                    <td>{dispatchedAssignedBooking.phoneNumber}</td>
                    <td>{dispatchedAssignedBooking.companyName}</td>
                    <td>{dispatchedAssignedBooking.airportName}</td>
                    <td>{dispatchedAssignedBooking.startDateTime}</td>
                    <td>{dispatchedAssignedBooking.endDateTime}</td>
                    <td>{dispatchedAssignedBooking.flyingFrom}</td>
                    <td>{dispatchedAssignedBooking.flyingTo}</td>
                    <td>{dispatchedAssignedBooking.createdDate}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing DispatchedAssignedBookings: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default DispatchedAssignedBookings
