import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import './NavbarLink.css'

function NavbarLink({ name, logo, to }) {
  return (
    <li className="nav-item">
      <NavLink
        to={to}
        className={({ isActive, isPending, isFocused }) =>
          `btn-3 text-decoration-none ${
            isActive
              ? 'active_link'
              : isPending
              ? 'active_link'
              : isFocused
              ? 'active_link'
              : ''
          }`
        }
        aria-label={name}
      >
        {logo && <span aria-hidden="true">{logo}</span>} {name}
      </NavLink>
    </li>
  )
}

NavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.node,
  to: PropTypes.string.isRequired,
}

NavbarLink.defaultProps = {
  logo: null,
}

export default NavbarLink
