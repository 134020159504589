import { Outlet } from 'react-router-dom'
import Header from '../Components/Header/Header'
import GarageNavbar from '../Navbars/GarageNavbar'

const GarageLayout = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 col-md-3 p-0">
          <GarageNavbar />
        </div>
        <div className="col-lg-9 col-md-9 col-12">
          <Header />
          <Outlet />
        </div>
      </div>
    </div>
  )
}
export default GarageLayout
