import React, { useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import { toast, Slide, ToastContainer } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

function UserTypes() {
  // const data = useLoaderData()
  const [userTypes, setUserTypes] = useState([])
  const [selectedUserTypes, setSelectedUserTypes] = useState([])

  const [userType, setUserType] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalUserTypes = userTypes.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  useEffect(() => {
    const fetchUserTypes = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/UserType/GetAllUserType`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token.authToken,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setUserTypes(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchUserTypes()
  }, [selectedUserTypes, token.authToken])
  console.log(userTypes)

  // Handle UserType creation
  const handleCreateUserType = async (e) => {
    e.preventDefault()
    const newUserType = {
      userType,
      isDeleted: false,
    }
    console.log(newUserType)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/userType/CreateUserType`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(newUserType),
        }
      )

      if (response.ok) {
        const createdUserType = await response.json()
        // setUserTypes((prevUserTypes) => [...prevUserTypes, createdUserType])
        console.log(createdUserType)
        toast.success(`UserType '${createdUserType.userType}' created`, {
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create UserType.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        transition: Slide,
      })
    }
  }

  // Show modal for adding new UserType
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedUserTypes([])

    setUserType('')
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle UserType update
  const handleEditUserType = async (e) => {
    e.preventDefault()
    const selectedUserType = userTypes.find(
      (userType) => userType.userTypesID === selectedUserTypes[0]
    )

    console.log(selectedUserType)
    const updatedUserType = {
      ...selectedUserType,
      userType,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/UserType/UpdateUserType/${selectedUserType.userTypesID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedUserType),
        }
      )

      if (response.ok) {
        const updatedUserTypeData = await response.json()
        setUserTypes((prevUserTypes) =>
          prevUserTypes.map((userType) =>
            userType.userTypesID === updatedUserTypeData.userTypesID
              ? updatedUserTypeData
              : userType
          )
        )
        console.log(updatedUserType)
        console.log(userTypes)
        toast.success(`UserType '${selectedUserType.userType}' updated`, {
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update UserType.`, {
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating UserType: ${error}`, {
        transition: Slide,
      })
    }
  }

  // Show modal for editing UserType
  const handleEditButtonClick = () => {
    const selectedUserType = userTypes.find(
      (userType) => userType.userTypesID === selectedUserTypes[0]
    )
    console.log(selectedUserType)
    setUserType(selectedUserType.userType)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  const handleDetailUserType = (singleUserType) => {
    const selectedUserType = userTypes.find(
      (userType) => userType.userTypesID === singleUserType
    )
    setUserType(selectedUserType.userType)
  }

  // Handle UserType deletion
  const handleDeleteUserType = async () => {
    const selectedUserType = { userTypesID: selectedUserTypes }

    try {
      const response = await fetch(`${API_BASE_URL}/api/UserType/BulkDelete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(selectedUserType),
      })

      if (response.ok) {
        setUserTypes((prevUserTypes) =>
          prevUserTypes.filter(
            (userType) => !selectedUserTypes.includes(userType.userTypesID)
          )
        )
        toast.success(`Selected UserTypes deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedUserTypes([])
      }
    } catch (error) {
      toast.error(`Error deleting UserTypes: ${error}`, {})
    }
    setIsModalOpen(false)
    setUserType('')
  }

  // Show modal for deleting UserType
  const handleDeleteButtonClick = () => {
    const selectedUserType = userTypes.find(
      (userType) => userType.userTypesID === selectedUserTypes[0]
    )
    setUserType(selectedUserType.userType)
  }

  // Handle UserType activation/deactivation

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredUserTypes = userTypes.filter((userType) => {
    const matchesSearchTerm = userType.userType
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase())
    return matchesSearchTerm
  })

  const handleCheckboxChange = (userTypesID) => {
    setSelectedUserTypes((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(userTypesID)
        ? prevSelected.filter((id) => id !== userTypesID)
        : [...prevSelected, userTypesID]

      return newSelection
    })
  }
  console.log(selectedUserTypes, '----itsme-----')
  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * itemsPerPage)
  }

  const totalPages = Math.ceil(filteredUserTypes.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentUserTypes = filteredUserTypes
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentUserTypes.length}/${totalUserTypes}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>{' '}
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedUserTypes.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'UserType: '}</p>
                            <p className="ms-2 mb-0">{userType}</p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedUserTypes.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedUserTypes.length === 1
                                ? ` '${userType}' record?`
                                : `${selectedUserTypes.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteUserType}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="UserTypemodelMake"
                tabIndex="-1"
                aria-labelledby="UserTypeModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode ? handleEditUserType : handleCreateUserType
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="UserTypeModalLabel">
                          {isEditMode ? 'Edit UserType' : 'Add New UserType'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            UserType
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update UserType' : 'Create UserType'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2 mb-3">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>

        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      userTypes.length <= 0
                        ? false
                        : selectedUserTypes.length === userTypes.length
                      // userTypes.length >= 1
                      // selectedUserTypes.length === userTypes.length ? true : false
                      // userTypes.length
                    }
                    onChange={() =>
                      setSelectedUserTypes(
                        selectedUserTypes.length === userTypes.length
                          ? []
                          : userTypes.map((userType) => userType.userTypesID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  UserType
                </th>
              </tr>
            </thead>
            <tbody>
              {currentUserTypes.map((userType, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${
                    userType.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={userType.userTypesID}
                  style={
                    userType.isActive ? { backgroundColor: '#D3D3D3' } : {}
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedUserTypes.includes(userType.userTypesID)}
                      onChange={(e) =>
                        handleCheckboxChange(userType.userTypesID, index, e)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailUserType(userType.userTypesID)}
                  >
                    {userType.userType}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                {/* <option value="2">2</option> */}
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing UserTypes: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default UserTypes
