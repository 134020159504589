import React, { useEffect, useState } from 'react'
import ProfileImg from './../../Assets/img/profile-img.jpg'
import { API_BASE_URL } from '../../Config/Config'
import { verifyToken } from '../..'

const Header = ({ searchTerm, setSearchTerm }) => {
  const [token, setToken] = useState('')
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])
  verifyToken()
  const handleLogoutUser = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/User/LogOut`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        //  body: JSON.stringify({
        //    userName: userName,
        //    password: password,
        //  }),
      })
      if (response.ok) {
        window.location.href = `https://askaii.co.uk/`
      }

      //  if (data.jwtToken) {
      // Redirect to the external dashboard link
      //  } else {
      //  throw new Error('Login failed')
      //  }
    } catch (err) {
      alert('Invalid credentials. Please try again.')
    }
  }
  return (
    <>
      {/* <div className="top-right-navbar">
        <div className="top-navbar row justify-content-between align-items-center">
          <div className="col-lg-4 col-md-8 col-8">

            <div className="icons d-flex justify-content-center align-items-center">

              <button
                type="button"
                onclick="refreshPage()"
                className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                data-toggle="Refresh"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                data-bs-title="Refresh"
              >
                <i className="fa-solid fa-arrows-rotate"></i>
              </button>

              <button
                type="button"
                className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                data-toggle="fullscreen"
              >
                <i className="fa-solid fa-expand"></i>
              </button>

              <button
                type="button"
                className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                data-toggle="Help"
              >
                <i className="fa-solid fa-question"></i>
              </button>

              <button
                type="button"
                className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                data-toggle="fullscreen"
              >
                <i className="fa-regular fa-moon"></i>
              </button>

              <button type="button" className="btn-2 position-relative">
                <i className="fa-solid fa-bag-shopping"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                  5<span className="visually-hidden">unread messages</span>
                </span>
              </button>

              <button type="button" className="btn-2 position-relative">
                <i className="fa-regular fa-bell"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  99+
                  <span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
          </div>

          <div className="col-lg-6 col-md-3 col-4">
            <div className="dropdown profile-main">
              <button
                className="profile-btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <a href="#">
                  <div className="profile d-flex justify-content-between align-items-center">
                    <div>
                      <img src="content/img/profile-img.jpg" alt="profile" />
                    </div>
                    <div>
                      <p>Anna Adame</p>
                      <p>Founder</p>
                    </div>
                  </div>
                </a>
              </button>
              <ul className="dropdown-menu profile-dropdown">
                <li>
                  <a href="login.html">
                    <h6>Welcome Anna!</h6>
                  </a>
                </li>

                <li>
                  <a href="profile.html">
                    <i className="fa-regular fa-user"></i> Profile
                  </a>
                </li>

                <li>
                  <a href="#">
                    <i className="fa-regular fa-message"></i>Messages
                  </a>
                </li>

                <li>
                  <a href="#">
                    <i className="fa-regular fa-life-ring"></i>Help
                  </a>
                </li>

                <li>
                  <a href="#">
                    {' '}
                    <i className="fa-solid fa-gear"></i>Setting
                  </a>
                </li>

                <li>
                  <a href="#">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                    LogOut
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="top-navbar row justify-content-between align-items-center"
        id="top-navbar-color"
      >
        <div className="col-lg-4 col-md-4 col-2">
          <div
            className="search-input"
            id="border-color"
            data-toggle="Search..."
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-title="Search..."
          >
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              // type="search"
              className="form-control"
              // placeholder=" search..."
              id="input-color"
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-4 col-8">
          <div className="icons d-flex justify-content-center align-items-center">
            <button
              type="button"
              // onClick="refreshPage()"
              onClick={() => window.location.reload()}
              className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
              data-toggle="Refresh"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              data-bs-title="Refresh"
            >
              <i className="fa-solid fa-arrows-rotate"></i>
            </button>

            <button
              type="button"
              className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
              data-toggle="Fullscreen"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-title="Fullscreen"
            >
              <i className="fa-solid fa-expand"></i>
            </button>

            <button
              type="button"
              className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
              data-toggle="Help"
            >
              <i className="fa-solid fa-question"></i>
            </button>

            <button
              type="button"
              className="btn-2 btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
              data-toggle="Moon"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-title="Moon"
            >
              <i className="fa-regular fa-moon"></i>
            </button>
            <button
              type="button"
              className="btn-2 position-relative"
              data-toggle="Bookings"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-title="Bookings"
            >
              <a href="activebooking.html" className="text-dark fs-6">
                <i className="fa-solid fa-bag-shopping"></i>
              </a>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                5<span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <button
              className="btn-2 position-relative"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <i className="fa-regular fa-bell"></i>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                2+
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>

            <div
              className="offcanvas offcanvas-end"
              tabindex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasRightLabel">
                  Notifications
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <a
                  href="#"
                  className="text-decoration-none text-dark border p-2"
                >
                  <i className="fa-solid fa-trash"></i> Clear All Notifications
                </a>
                <div className="list-notifi">
                  <div className="p-3 border mt-3 row justify-content-between align-items-center">
                    <div className="col-lg-4 col-md-4 col-4 p-md-0">
                      <div className="notification-photo">
                        <img
                          src="content/img/visa-photo.jpg"
                          alt="visa-image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-8 p-md-0">
                      <div className="Visa-Ending">
                        <p className="mb-0 fs-7 big">
                          Visa Ending with 5678 Lorem ipsum color, amet consel
                          sequi.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="p-3 border my-3 row justify-content-between align-items-center">
                    <div className="col-lg-4 col-md-4 col-4 p-md-0">
                      <div className="notification-photo">
                        <img
                          src="content/img/master-card.jpg"
                          alt="visa-image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-8 p-md-0">
                      <div className="Visa-Ending">
                        <p className="mb-0 fs-7 big">
                          Visa Ending with 5678 Lorem ipsum color, amet consel
                          sequi.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- <div className="big ">
                  <p className="text-center p-4"> No notifications found </p>
                </div> --> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-3 col-2">
          <div className="dropdown profile-main">
            <button
              className="profile-btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <a href="#" className="text-decoration-none text-dark">
                <div
                  className="profile d-flex justify-content-between align-items-center"
                  id="profile-color"
                >
                  <div>
                    <img src={ProfileImg} alt="profile" />
                  </div>
                  <div>
                    <p>Anna Adame</p>
                    <p>Founder</p>
                  </div>
                </div>
              </a>
            </button>
            <ul className="dropdown-menu profile-dropdown">
              <li>
                <a
                  href="#"
                  className="text-dark text-decoration-none hover-danger"
                >
                  <h6>Welcome Anna!</h6>
                </a>
              </li>
              <li>
                <a
                  href="profile.html"
                  className="text-dark text-decoration-none"
                >
                  <i className="fa-regular fa-user"></i>Profile
                </a>
              </li>
              <li>
                <a href="#" className="text-dark text-decoration-none">
                  <i className="fa-regular fa-message"></i>Messages
                </a>
              </li>
              <li>
                <a href="#" className="text-dark text-decoration-none">
                  <i className="fa-regular fa-life-ring"></i>Help
                </a>
              </li>
              <li>
                <a href="#" className="text-dark text-decoration-none">
                  <i className="fa-solid fa-gear"></i>Setting
                </a>
              </li>
              <li>
                <span
                  className="text-dark text-decoration-none"
                  onClick={handleLogoutUser}
                >
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  LogOut
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
