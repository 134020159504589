import { useEffect, useState } from 'react'
import useFetch from '../../../CustomHooks/useFetch'
import { API_BASE_URL } from '../../../Config/Config'
import { verifyToken } from '../../..'
import CustomLoading, {
  CustomModalLoading,
} from '../../../Components/CustomHandling/LoadingAndError'
import { Slide, toast } from 'react-toastify'
import PageHeaderAndFooter from '../../../Components/PageHeaderAndFooter/PageHeaderAndFooter'

const Roles = () => {
  const showToast = (message, type = 'info', options = {}) => {
    const baseOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Slide,
      ...options,
    }

    switch (type) {
      case 'success':
        toast.success(message, baseOptions)
        break
      case 'warn':
        toast.warn(message, baseOptions)
        break
      case 'error':
        toast.error(message, baseOptions)
        break
      case 'info':
      default:
        toast.info(message, baseOptions)
        break
    }
  }

  const rolesURL = `${API_BASE_URL}/api/UserRoles/GetAllRoles`
  const modulesURL = `${API_BASE_URL}/api/module/GetAllModule`

  const [token, setToken] = useState('')
  const [roleName, setRoleName] = useState('')
  const [roleId, setRoleId] = useState(null)
  const [modData, setModData] = useState([])
  const [modId, setModId] = useState([])
  const [refreshDependency, setRefreshDependency] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [createState, setCreateState] = useState(false)
  const [editState, setEditState] = useState(false)
  const [deleteState, setDeleteState] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const {
    data: roles,
    loading,
    setLoading,
    error,
  } = useFetch(rolesURL, refreshDependency)
  const { data: modules } = useFetch(modulesURL, refreshDependency)
  // console.log(modId)

  const handleCreateOrEditRole = async (e) => {
    e.preventDefault()
    const newRole = {
      roleName,
      roleId: roleId ? roleId : null,
      moduleID: modId ? modId : null,
    }

    const url = `${API_BASE_URL}/api/UserRoles/CreateOrUpdateRole`
    setLoading(true)
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(newRole),
      })

      if (response.ok) {
        const createdRole = await response.json()
        const action = roleId ? 'updated' : 'created'
        showToast(`Role '${createdRole.roleName}' ${action}`, 'success')

        // Trigger a refresh
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setCreateState(false)
        setEditState(false)
        setModData([])
        setModId([])
        setRoleName('')
        setRoleId(null)
        setLoading(false)
      } else {
        setLoading(false)
        showToast('Failed to save role.', 'warn')
      }
    } catch (error) {
      setLoading(false)
      showToast(`${error.message}`, 'error')
    }
  }

  const handleDeleteRole = async (e) => {
    e.preventDefault()
    const deleteRole = { roleId }

    const url = `${API_BASE_URL}/api/UserRoles/DeleteRole/${roleId}`

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(deleteRole),
      })

      if (response.ok) {
        const createdRole = await response.json()
        const action = roleId ? 'updated' : 'created'
        showToast(`Role '${createdRole.roleName}' ${action}`, 'success')

        // Trigger a refresh
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setDeleteState(false)
        setModData([])
        setModId([])
        setRoleName('')
        setRoleId(null)
      } else {
        showToast('Failed to Delete role.', 'warn')
      }
    } catch (error) {
      showToast(`${error.message}`, 'error')
    }
  }

  const handleOpenModal = (operation, roleId = null) => {
    const selectedRole = roles.find((mod) => mod.roleId === roleId)

    const fetchRoleData = async (roleId) => {
      setModalLoading(true)
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/UserRoles/Getrolebyid/${roleId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          }
        )
        if (response.ok) {
          const result = await response.json()
          const allModuleIds = result.modulePermissions.map(
            (mod) => mod.moduleID
          )
          setModId(allModuleIds) // No need to append since it's specific to the role
        }
        setModalLoading(false)
      } catch (error) {
        console.error(error)
        setModalLoading(false)
      }
    }

    switch (operation) {
      case 'create':
        setCreateState(true)
        setEditState(false)
        setDeleteState(false)
        setIsModalOpen(true)
        break

      case 'update':
        if (selectedRole) {
          setRoleName(selectedRole.roleName)
          setRoleId(roleId)
        }
        if (roleId) {
          fetchRoleData(roleId)
        }
        setCreateState(false)
        setEditState(true)
        setDeleteState(false)
        setIsModalOpen(true)
        break

      case 'delete':
        if (selectedRole) {
          setRoleName(selectedRole.roleName)
          setRoleId(roleId)
        }
        if (roleId) {
          fetchRoleData(roleId)
        }
        setCreateState(false)
        setEditState(false)
        setDeleteState(true)
        setIsModalOpen(true)
        break

      default:
        setCreateState(false)
        setEditState(false)
        setDeleteState(false)
        setIsModalOpen(false)
        break
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setCreateState(false)
    setEditState(false)
    setDeleteState(false)
    setRoleName('')
    setRoleId(null)
    setModId([])
  }
  const handleCheckboxChange = (moduleId) => {
    setModId((prevModId) => {
      if (prevModId.includes(moduleId)) {
        // If the moduleId is already in the array, remove it
        return prevModId.filter((id) => id !== moduleId)
      } else {
        // Otherwise, add it to the array
        return [...prevModId, moduleId]
      }
    })
  }

  // console.log(modData)

  if (loading) return <CustomLoading />
  if (error) return <div>Error: {error}</div>

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New Roles"
        handleOpenModal={() => handleOpenModal('create')}
        data={roles}
        filterFields={['roleName']}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">RoleName</th>
                  <th scope="col">CreatedDate</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <tr key={data.roleId}>
                    <td>{startIndex + index + 1}</td>
                    <td>{data.roleName || 'N/A'}</td>
                    <td>{data.createdDate || 'N/A'}</td>

                    <td className="d-flex gap-2">
                      <button
                        className="btn btn_modal"
                        onClick={() => handleOpenModal('update', data.roleId)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn_modal_sec"
                        onClick={() => handleOpenModal('delete', data.roleId)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? 'show' : ''}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? 'block' : 'none',
              background: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={handleCloseModal}
          >
            {modalLoading ? <CustomModalLoading /> : ''}
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  createState || editState
                    ? handleCreateOrEditRole
                    : handleDeleteRole
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {/* {roleId ? 'Update role name' : 'Create role name'} */}
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : deleteState
                        ? 'Delete'
                        : ''}{' '}
                      role name
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="txtMake" className="form-label">
                        Role Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                        required
                        disabled={deleteState}
                      />
                    </div>
                    <div className="row">
                      <label htmlFor="txtMake" className="form-label">
                        Modules
                      </label>
                      {modules.map((mod) => (
                        <div key={mod.moduleID} className="col-lg-6">
                          <label>
                            <input
                              type="checkbox"
                              value={mod.moduleID}
                              onChange={() =>
                                handleCheckboxChange(mod.moduleID)
                              } // Toggle the module ID
                              disabled={deleteState} // Disable in delete mode
                              checked={modId.includes(mod.moduleID)} // Reflect checked state based on modId array
                            />{' '}
                            {mod.moduleName}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        createState
                          ? 'btn btn_modal_sec'
                          : editState
                          ? 'btn btn_modal_sec'
                          : deleteState
                          ? 'btn btn_modal'
                          : ''
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        createState
                          ? 'btn btn_modal'
                          : editState
                          ? 'btn btn_modal'
                          : deleteState
                          ? 'btn btn_modal_sec'
                          : ''
                      }
                      type="submit"
                    >
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : deleteState
                        ? 'Delete'
                        : ''}{' '}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Roles
