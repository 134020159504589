import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'

const ActiveCars = () => {
  const data = useLoaderData()
  const [activeCars, setActiveCars] = useState(data)
  const [selectedActiveCars, setSelectedActiveCars] = useState([])

  const [numberPlate, setNumberPlate] = useState('')
  const [carMake, setCarMake] = useState('')
  const [carModel, setCarModel] = useState('')
  const [bookingNumber, setBookingNumber] = useState('')
  const [driverName, setDriverName] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalActiveCars = activeCars.length

  // Handle ActiveCar creation
  const handleCreateActiveCars = async (e) => {
    e.preventDefault()
    const newActiveCar = {
      numberPlate,
      carMake,
      carModel,
      bookingNumber,
      driverName,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/ACTIVECARs/CreateACTIVECARs`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newActiveCar),
        }
      )

      if (response.ok) {
        const createdActiveCar = await response.json()
        setActiveCars((prevActiveCars) => [...prevActiveCars, createdActiveCar])
        toast.success(`ActiveCar '${createdActiveCar.carMake}' created`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create ActiveCar.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new ActiveCar
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setCarMake('')
    setNumberPlate('')
    setCarModel(1)
    setIsEditMode(false)
    setSelectedActiveCars([])
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      setCarMake('')
      setNumberPlate('')
      setCarModel('')
      setIsEditMode(false)
      setIsModalOpen(false)
    }
  }

  // Handle ActiveCar update
  const handleEditActiveCar = async (e) => {
    e.preventDefault()
    const selectedActiveCar = activeCars.find(
      (activeCar) => activeCar.activeCarsID === selectedActiveCars[0]
    )
    const updatedActiveCar = {
      ...selectedActiveCar,
      numberPlate,
      carMake,
      carModel,
      bookingNumber,
      driverName,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/ACTIVECARs/UpdateACTIVECARs/${selectedActiveCar.activeCarsID}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedActiveCar),
        }
      )

      if (response.ok) {
        const updatedActiveCarData = await response.json()
        setActiveCars((prevActiveCars) =>
          prevActiveCars.map((activeCar) =>
            activeCar.activeCarsID === updatedActiveCarData.activeCarsID
              ? updatedActiveCarData
              : activeCar
          )
        )
        toast.success(`ACTIVECAR '${selectedActiveCar.carMake}' updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update ACTIVECAR.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating ACTIVECAR: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing ActiveCar
  const handleEditButtonClick = () => {
    const selectedActiveCar = activeCars.find(
      (activeCar) => activeCar.activeCarsID === selectedActiveCars[0]
    )
    setCarMake(selectedActiveCar.carMake)
    setNumberPlate(selectedActiveCar.numberPlate)
    setCarModel(selectedActiveCar.carModel)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details ActiveCar
  const handleDetailsActiveCar = () => {
    const selectedActiveCar = activeCars.find(
      (activeCar) => activeCar.activeCarsID === selectedActiveCars[0]
    )
    setCarMake(selectedActiveCar.carMake)
    setNumberPlate(selectedActiveCar.numberPlate)
  }

  const handleDetailActiveCar = (singleActiveCar) => {
    const selectedActiveCar = activeCars.find(
      (activeCar) => activeCar.activeCarsID === singleActiveCar
    )
    setCarMake(selectedActiveCar.carMake)
    setNumberPlate(selectedActiveCar.numberPlate)
  }

  // Handle ActiveCar deletion
  const handleDeleteActiveCar = async () => {
    const selectedActiveCar = { activeCarsIDs: selectedActiveCars }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/ACTIVECARs/BulkDelete`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(selectedActiveCar),
        }
      )

      if (response.ok) {
        setActiveCars((prevActiveCars) =>
          prevActiveCars.filter(
            (activeCar) => !selectedActiveCars.includes(activeCar.activeCarsID)
          )
        )
        toast.success(`Selected ACTIVECARs deleted`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        setSelectedActiveCars([])
      }
    } catch (error) {
      toast.error(`Error deleting ACTIVECAR: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
    setIsModalOpen(false)
    setCarMake('')
    setCarModel(1)
  }

  // Show modal for editing ActiveCar
  const handleDeleteButtonClick = () => {
    const selectedActiveCar = activeCars.find(
      (activeCar) => activeCar.activeCarsID === selectedActiveCars[0]
    )
    setCarMake(selectedActiveCar.carMake)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredActiveCars = activeCars.filter((activeCar) => {
    const activeCarDate = new Date(activeCar.activeCarCreatedDate) // Convert string date to Date

    // Check if ACTIVECAR carMake includes the search term (case insensitive)
    const matchesSearchTerm =
      activeCar.carMake?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activeCar.numberPlate?.toLowerCase().includes(searchTerm.toLowerCase())
    // Check date filtering conditions
    const matchesDateRange = (() => {
      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate)
        const toDateObj = new Date(fromDate)
        // If both fromDate and toDate are set, filter by the range
        return (
          activeCarDate.toDateString() === fromDateObj.toDateString() &&
          activeCarDate.toDateString() === toDateObj.toDateString()
        )
      } else if (fromDate) {
        const fromDateObj = new Date(fromDate)
        return activeCarDate.toDateString() === fromDateObj.toDateString()
      }
      // If neither fromDate nor toDate is set, match all dates
      return true
    })()

    // Return true only if both search term and date range conditions are met
    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (activeCarId) => {
    setSelectedActiveCars((prevSelected) => {
      // Normal click: toggle selection
      let newSelection = prevSelected.includes(activeCarId)
        ? prevSelected.filter((id) => id !== activeCarId)
        : [...prevSelected, activeCarId]

      // Check if the active button should be enabled
      const hasActiveCarWithAll = newSelection.some((id) => {
        const selectedActiveCar = activeCars.find(
          (activeCar) => activeCar.activeCarsID === id
        )
        return selectedActiveCar.carMake && selectedActiveCar.numberPlate
      })

      setIsActiveButtonEnabled(hasActiveCarWithAll)

      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * itemsPerPage)
  }

  // Calculate total pages based on filtered ACTIVECARs
  const totalPages = Math.ceil(filteredActiveCars.length / itemsPerPage)
  // Calculate the index range of ACTIVECARs to display
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  // ACTIVECARs to be displayed on the current page
  const currentActiveCars = filteredActiveCars
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentActiveCars.length}/${totalActiveCars}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Edit"
                    onClick={handleEditButtonClick}
                    disabled={selectedActiveCars.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsActiveCar}
                    title="Details"
                    disabled={selectedActiveCars.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>
                  {/* {////////////////////////////////////////////////////////////////////////////////////////////} */}

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'CarMake: '}</p>
                            <p className="ms-2 mb-0">{carMake}</p>
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            <p className="ms-2 mb-0">{'NumberPlate: '}</p>
                            <p className="ms-2 mb-0">{numberPlate}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedActiveCars.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Are you sure to want to delete{' '}
                              {selectedActiveCars.length === 1
                                ? ` '${carMake}' record?`
                                : `${selectedActiveCars.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteActiveCar}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add CarMake Modal /////////////////////////////////////////////////////////////////////////////////// */}
              <div
                id="CarMakemodelMake"
                tabIndex="-1"
                aria-labelledby="CarMakeModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode
                          ? handleEditActiveCar
                          : handleCreateActiveCars
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="CarMakeModalLabel">
                          {isEditMode
                            ? 'Edit Active Car'
                            : 'Add New Active Car'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Number Plate
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={numberPlate}
                            onChange={(e) => setNumberPlate(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Car Make
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={carMake}
                            onChange={(e) => setCarMake(e.target.value)}
                            required
                          />
                        </div>{' '}
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Car Model
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={carModel}
                            onChange={(e) => setCarModel(e.target.value)}
                            required
                          />
                        </div>{' '}
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Booking Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={bookingNumber}
                            onChange={(e) => setBookingNumber(e.target.value)}
                            required
                          />
                        </div>{' '}
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Driver Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={driverName}
                            onChange={(e) => setDriverName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode
                            ? 'Update Active Car'
                            : 'Create Create Car'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg- col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Active Car List */}
        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="activeCars-table-row-d1">
                  <input
                    type="checkbox"
                    checked={selectedActiveCars.length === activeCars.length}
                    onChange={() =>
                      setSelectedActiveCars(
                        selectedActiveCars.length === activeCars.length
                          ? []
                          : activeCars.map(
                              (activeCar) => activeCar.activeCarsID
                            )
                      )
                    }
                  />
                </th>
                <th scope="col" className="font activeCars-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="activeCars-table-row-d3">
                  Number Plate
                </th>{' '}
                <th scope="col" className="activeCars-table-row-d3">
                  Car Make
                </th>{' '}
                <th scope="col" className="activeCars-table-row-d3">
                  Car Model
                </th>{' '}
                <th scope="col" className="activeCars-table-row-d3">
                  Booking Number
                </th>
                <th scope="col" className="activeCars-table-row-d4">
                  Driver Name
                </th>
                <th scope="col" className="activeCars-table-row-d4">
                  Garage Total Bookings
                </th>
                <th scope="col">Created on</th>
              </tr>
            </thead>
            <tbody>
              {currentActiveCars.map((activeCar, index) => (
                <tr
                  className="activeCars-table-row"
                  title={`${
                    activeCar.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={activeCar.activeCarsID}
                  style={
                    activeCar.isActive ? { backgroundColor: '#D3D3D3' } : {}
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedActiveCars.includes(
                        activeCar.activeCarsID
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(activeCar.activeCarsID, index, e)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailActiveCar(activeCar.activeCarsID)
                    }
                  >
                    {activeCar.carMake?.length > 50
                      ? activeCar.carMake.substring(0, 50) + '...'
                      : activeCar.carMake}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailActiveCar(activeCar.activeCarsID)
                    }
                  >
                    {activeCar.numberPlate?.length > 100
                      ? activeCar.numberPlate.substring(0, 100) + '...'
                      : activeCar.numberPlate}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailActiveCar(activeCar.activeCarsID)
                    }
                  >
                    {activeCar.activeCarCreatedDate}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailActiveCar(activeCar.activeCarsID)
                    }
                  >
                    {activeCar.activeCarCreatedDate}
                  </td>{' '}
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailActiveCar(activeCar.activeCarsID)
                    }
                  >
                    {activeCar.activeCarCreatedDate}
                  </td>{' '}
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailActiveCar(activeCar.activeCarsID)
                    }
                  >
                    {activeCar.activeCarCreatedDate}
                  </td>{' '}
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailActiveCar(activeCar.activeCarsID)
                    }
                  >
                    {activeCar.activeCarCreatedDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <nav aria-label="Page navigation example">
          <div className="container text-center align-content-center d-flex">
            <div>Showing ACTIVECARs: {calculateDisplayRange()}</div>
          </div>
          <select
            name="activeCarPerPage"
            onChange={(e) => setItemPerPage(e.target.value)}
            className="form-control col-lg-4"
          >
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="45">45</option>
            <option value="60">60</option>
            <option value="75">75</option>
            <option value="90">90</option>
          </select>

          <ul className="pagination justify-content-end me-1">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {[...Array(totalPages)].map((_, index) => (
              <li
                className={`page-item ${
                  currentPage === index + 1 ? 'active' : ''
                }`}
                key={index}
              >
                <button
                  className="page-link"
                  onClick={() => handleSerialNumberChange(index)}
                >
                  {index + 1}
                </button>
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default ActiveCars

export const getAllActiveCars = async () => {
  const response = await fetch(`${API_BASE_URL}/api/FAQs/GetAllDashFAQs`)
  return response.json()
}
