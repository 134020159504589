import { useState, useRef, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import carPNG from './Car.png'

import './DriverReceivingSlip.css'
import './DriverReceivingSlip2.css'

const DriverReceivingSlip = () => {
  // Step 1: Create state to hold form data
  const [carPartsCondition, setCarPartsCondition] = useState({
    frontLeftTire: '',
    frontLeftDoor: '',
    backLeftTire: '',
    backLeftDoor: '',
    frontBonnet: '',
    frontBumper: '',
    backRightTire: '',
    backRightDoor: '',
    roof: '',
    backSideBoot: '',
    backRearBumper: '',
    rightFrontTire: '',
    rightFrontDoor: '',
  })

  const [formData, setFormData] = useState({
    metBy1: '',
    metBy2: '',
    name: '',
    mobile: '',
    actualMeetTime: '',
    email: '',
    reg: '',
    parkingStart: '',
    parkingEnd: '',
    vehicle: '',
    confirmAcceptance: '',
    extraServices: '',
    sscp: '',
    entry: '',
    exit: '',
    allPersonal: '',
    mileage: '',
    petrolLevel: '',
    batteryLevel: '',
    condition: '',
    initial: '',
    notes: '',
    customerSign: '',
  })

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? (checked ? value : '') : value,
    })
  }

  const handleChangeModal = (e) => {
    const { value } = e.target
    setCarPartsCondition((prevState) => ({
      ...prevState,
      [currentPart]: value, // Only set to the value of the selected checkbox
    }))
  }

  console.log(carPartsCondition)

  const [currentPart, setCurrentPart] = useState('')
  const conditionModalRef = useRef(null)
  const petrolModalRef = useRef(null)
  const batteryModalRef = useRef(null)
  const uniqueSignatureModalRef = useRef(null)
  const uniqueSignatureCanvasRef = useRef(null)

  const handleButtonClick = (partName) => {
    setCurrentPart(partName)
    openModal(conditionModalRef)
  }

  const openModal = (modalRef) => {
    if (modalRef.current) modalRef.current.style.display = 'block'
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Form submitted:', formData)
  }

  // State for drawing signature
  const [isDrawing, setIsDrawing] = useState(false)
  const [drawCtx, setDrawCtx] = useState(null)

  // Functions to handle modal opening and closing

  const closeModal = (modalRef) => {
    if (modalRef.current) modalRef.current.style.display = 'none'
  }

  // const handleChange = (e) => {
  //   const { name, checked } = e.target; // Get the name and checked state of the checkbox

  //   // Update the specific car part's condition

  // };

  // Canvas drawing setup and handlers
  // useEffect(() => {
  //   const canvas = uniqueSignatureCanvasRef.current;
  //   if (canvas) {
  //     const ctx = canvas.getContext("2d");
  //     setDrawCtx(ctx);

  //     const handleMouseDown = (e) => {
  //       setIsDrawing(true);
  //       ctx.beginPath();
  //       ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  //     };

  //     const handleMouseMove = (e) => {
  //       if (!isDrawing) return;
  //       ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  //       ctx.stroke();
  //     };

  //     const handleMouseUp = () => {
  //       setIsDrawing(false);
  //     };

  //     canvas.addEventListener("mousedown", handleMouseDown);
  //     canvas.addEventListener("mousemove", handleMouseMove);
  //     canvas.addEventListener("mouseup", handleMouseUp);

  //     return () => {
  //       canvas.removeEventListener("mousedown", handleMouseDown);
  //       canvas.removeEventListener("mousemove", handleMouseMove);
  //       canvas.removeEventListener("mouseup", handleMouseUp);
  //     };
  //   }
  // }, [isDrawing]);

  // Save signature data
  const saveUniqueSignature = () => {
    const signatureData =
      uniqueSignatureCanvasRef.current.toDataURL('image/png')
    // Perform additional actions, like setting it in state or uploading
  }
  // ...............................................................DriverReceivingSlip............

  const sigCanvas = useRef(null) // Reference to the signature canvas
  const [signatureURL, setSignatureURL] = useState(null) // State to store the signature URL

  // Clear the signature pad
  const clear = () => {
    sigCanvas.current.clear()
    setSignatureURL(null)
  }

  // Save the signature as an image URL
  const save = () => {
    if (sigCanvas.current.isEmpty()) {
      alert('Please provide a signature first.')
    } else {
      setSignatureURL(sigCanvas.current.toDataURL())
    }
  }
  // ............................................................................................
  // State to store the current part label
  const [currentPartLabel, setCurrentPartLabel] = useState('')
  // ..............................................................................

  return (
    <>
      <div className="main-container-detail">
        <div className="main-detail-option">
          <div className="detail-option-container">
            <div className="detail-options-section">
              <div className="detail-option">
                <label className="detail-option-label">
                  Met By: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="metBy1"
                  value={formData.metBy1}
                  onChange={(e) => handleChange(e)}
                  type="datetime-local"
                  placeholder=""
                  maxlength="1"
                />
                {/* {errors.userFirstNameError && (
                  <p className="booking-required">
                    {errors.userFirstNameError}
                  </p>
                )} */}
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Actual Meet Time: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="actualMeetTime"
                  type="datetime-local"
                  value={formData.actualMeetTime}
                  onChange={(e) => handleChange(e)}
                />
                {/* {errors.userFirstNameError && (
                  <p className="booking-required">
                    {errors.userFirstNameError}
                  </p>
                )} */}
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  SSCP: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="sscp"
                  type="text"
                  value={formData.sscp}
                  onChange={(e) => handleChange(e)}
                />
                {/* {errors.userFirstNameError && (
                  <p className="booking-required">
                    {errors.userFirstNameError}
                  </p>
                )} */}
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Entry: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="entry"
                  type="datetime-local"
                  value={formData.entry}
                  onChange={(e) => handleChange(e)}
                />
                {/* {errors.userFirstNameError && (
                  <p className="booking-required">
                    {errors.userFirstNameError}
                  </p>
                )} */}
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Exit: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="exit"
                  type="datetime-local"
                  value={formData.exit}
                  onChange={(e) => handleChange(e)}
                />
                {/* {errors.userFirstNameError && (
                  <p className="booking-required">
                    {errors.userFirstNameError}
                  </p>
                )} */}
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  All Personal Items Removed:{' '}
                  <div className="initial_col">
                    <label htmlFor="firstInitial">Initial: </label>
                    <div>
                      <input type="checkbox" name="firstInitial" />
                    </div>
                  </div>
                </label>
                {/* {errors.userFirstNameError && (
                  <p className="booking-required">
                    {errors.userFirstNameError}
                  </p>
                )} */}
              </div>
            </div>
            <div className="detail-options-section">
              <div className="detail-option">
                <label className="detail-option-label">
                  Mileage: <span className="booking-required">*</span>
                </label>

                <input
                  className="detail-option-select"
                  name="mileage"
                  type="text"
                  value={formData.mileage}
                  onChange={(e) => handleChange(e)}
                />
                {/* {errors.carRegistrationNoError && (
                  <p className="booking-required">
                    {errors.carRegistrationNoError}
                  </p>
                )} */}
              </div>

              <div className="detail-option">
                <div className="car-body">
                  <div className="container-car-main">
                    <div className="car-image-car">
                      {/* <button
                        className="car-part-btn front-left"
                        onClick={() => handleButtonClick('frontLeftTire')}
                      >
                        Front Left Tire
                      </button>
                      <button
                        className="car-part-btn front-left-door"
                        onClick={() => handleButtonClick('frontLeftDoor')}
                      >
                        Front Left Door
                      </button>
                      <button
                        className="car-part-btn back-left"
                        onClick={() => handleButtonClick('backLeftTire')}
                      >
                        Back Left Tire
                      </button>
                      <button
                        className="car-part-btn back-left-door"
                        onClick={() => handleButtonClick('backLeftDoor')}
                      >
                        Back Left Door
                      </button>
                      <button
                        className="car-part-btn front-sight"
                        onClick={() => handleButtonClick('frontBonnet')}
                      >
                        Front Bonnet
                      </button>
                      <button
                        className="car-part-btn front-sight-bumper"
                        onClick={() => handleButtonClick('frontBumper')}
                      >
                        Front Bumper
                      </button>
                      <button
                        className="car-part-btn back-right"
                        onClick={() => handleButtonClick('backRightTire')}
                      >
                        Back Right Tire
                      </button>
                      <button
                        className="car-part-btn back-right-door"
                        onClick={() => handleButtonClick('backRightDoor')}
                      >
                        Back Right Door
                      </button>
                      <button
                        className="car-part-btn top"
                        onClick={() => handleButtonClick('roof')}
                      >
                        The Roof
                      </button>
                      <button
                        className="car-part-btn back-side"
                        onClick={() => handleButtonClick('backSideBoot')}
                      >
                        Back Side Boot
                      </button>
                      <button
                        className="car-part-btn back-side-bumper"
                        onClick={() => handleButtonClick('backRearBumper')}
                      >
                        Back Rear Bumper
                      </button>
                      <button
                        className="car-part-btn right-front"
                        onClick={() => handleButtonClick('rightFrontTire')}
                      >
                        Right Front Tire
                      </button>
                      <button
                        className="car-part-btn right-front-door"
                        onClick={() => handleButtonClick('rightFrontDoor')}
                      >
                        Right Front Door
                      </button>
                      <button
                        className="car-part-btn battery-front"
                        onClick={() => openModal(batteryModalRef)}
                      >
                        Battery
                      </button> */}
                      <button
                        className="car-part-btn petrole-side"
                        onClick={() => openModal(petrolModalRef)}
                      >
                        Petrol
                      </button>
                    </div>
                  </div>
                </div>
                <img className="detail_item_img" src={carPNG} alt="" />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Condition:</label>
                <select className="detail-option-select" name="userTitle">
                  <option value="" disabled selected>
                    Select an Option
                  </option>
                  <option value="Poor">Poor</option>
                  <option value="Good">Good</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Clean">Clean</option>
                  <option value="Dirty">Dirty</option>
                  <option value="Very Dirty">Very Dirty</option>
                </select>
              </div>
            </div>{' '}
            <div className="detail-options-section">
              <div className="detail-option">
                <label className="detail-option-label">
                  Declaration:{' '}
                  {/* <span className="booking-required">*</span> */}
                  <div className="initial_col">
                    <label htmlFor="firstInitial">Initial: </label>
                    <div>
                      <input type="checkbox" name="firstInitial" />
                    </div>
                  </div>
                </label>
                {/* {errors.userFirstNameError && (
                  <p className="booking-required">
                    {errors.userFirstNameError}
                  </p>
                )} */}
              </div>

              <div className="detail-option">
                <label className="detail-option-label">
                  Notes: <span className="booking-required"> *</span>
                </label>

                <textarea
                  name=""
                  id=""
                  className="detail-option-select"
                ></textarea>
                {/* {errors.flyingFromError && (
                  <p className="booking-required">{errors.flyingFromError}</p>
                )} */}
              </div>

              <div className="detail-option">
                <label className="detail-option-label">
                  Customer Sign: <span className="booking-required"> *</span>
                </label>
                <textarea
                  name=""
                  id=""
                  className="detail-option-select"
                ></textarea>
                {/* {errors.flyingFromError && (
                  <p className="booking-required">{errors.flyingFromError}</p>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // <div className="slip-container-main">
    //   <form onSubmit={handleSubmit}>
    //     <div className="slip-container1">
    //       <div className="slip-container">
    //         <section className="header">
    //           <div className="header-toop">
    //             <div>
    //               <img
    //                 src="https://askaii.co.uk/static/media/Skaii_Logo_Final-1-removebg-preview.b95046600a24b857fc27.png"
    //                 alt="Company Logo"
    //                 style={{ width: '100px', height: '50px' }}
    //               />
    //             </div>

    //             <div className="top-input-container">
    //               <strong>Met By</strong>
    //               <input
    //                 name="metBy1"
    //                 value={formData.metBy1}
    //                 onChange={(e) => handleChange(e)}
    //                 type="text"
    //                 placeholder=""
    //                 // maxlength="1"
    //                 className="small-input"
    //               />
    //               <span>/</span>
    //               <input
    //                 name="metBy2"
    //                 value={formData.metBy2}
    //                 onChange={(e) => handleChange(e)}
    //                 type="text"
    //                 className="small-input"
    //                 placeholder=""
    //                 // maxlength="1"
    //               />
    //             </div>
    //           </div>
    //         </section>

    //         <section className="details">
    //           <div className="details-row">
    //             <div className="left-column">
    //               <p>
    //                 <strong>Name:</strong>
    //                 <input
    //                   name="name"
    //                   value={formData.name}
    //                   onChange={handleChange}
    //                   type="text"
    //                   className="top-inputs"
    //                 />
    //               </p>
    //               <p>
    //                 <strong>Email:</strong>
    //                 <input
    //                   name="email"
    //                   value={formData.email}
    //                   onChange={(e) => handleChange(e)}
    //                   type="text"
    //                   className="top-inputs"
    //                 />
    //               </p>
    //               <p>
    //                 <strong>Parking Start:</strong>
    //                 <input
    //                   name="parkingStart"
    //                   type="text"
    //                   className="top-inputs"
    //                   // value="11/07/2024 19:00 T2"
    //                   value={formData.parkingStart}
    //                   onChange={(e) => handleChange(e)}
    //                 />
    //               </p>
    //               <p>
    //                 <strong>Parking End:</strong>
    //                 <input
    //                   name="parkingEnd"
    //                   type="text"
    //                   className="top-inputs"
    //                   // value=" 14/07/2024 11:15 T2"
    //                   value={formData.parkingEnd}
    //                   onChange={(e) => handleChange(e)}
    //                 />
    //               </p>
    //             </div>
    //             <div className="right-column">
    //               <p>
    //                 <strong>Mobile:</strong>
    //                 <input
    //                   name="mobile"
    //                   type="text"
    //                   className="top-inputs"
    //                   // value=" 07770797978"
    //                   value={formData.mobile}
    //                   onChange={(e) => handleChange(e)}
    //                 />
    //               </p>
    //               <p>
    //                 <strong>Actual Meet Time:</strong>
    //                 <input
    //                   name="actualMeetTime"
    //                   type="time"
    //                   placeholder=":"
    //                   value={formData.actualMeetTime}
    //                   onChange={(e) => handleChange(e)}
    //                   className="small-input-right"
    //                 />
    //               </p>
    //               <p>
    //                 <strong>Reg:</strong>
    //                 <input
    //                   name="reg"
    //                   type="text"
    //                   className="top-inputs"
    //                   value={formData.reg}
    //                   onChange={(e) => handleChange(e)}
    //                 />
    //               </p>
    //             </div>
    //           </div>
    //           <div className="vehicle-info">
    //             <p>
    //               <strong>Vehicle:</strong>
    //               <input
    //                 name="vehicle"
    //                 type="text"
    //                 value={formData.vehicle}
    //                 onChange={(e) => handleChange(e)}
    //                 className="top-inputs-large"
    //                 // value="Volvo | Xc60 Ultimate B5 Mhev Awd Auto | Grey"
    //               />
    //             </p>
    //           </div>
    //           <section className="disclaimer">
    //             <div className="disclaimer-box">
    //               <p>
    //                 <strong>CUSTOMER TO INITIAL BELOW BOX</strong>TO CONFIRM
    //                 ACCEPTANCE OF T&Cs AVAILABLE AT
    //                 <a href="https://askaii.co.uk/">WWW.ASKAII.CO.UK</a>
    //               </p>
    //               <input
    //                 name="confirmAcceptance"
    //                 type="text"
    //                 className="top-inputs-large"
    //                 value={formData.confirmAcceptance}
    //                 onChange={(e) => handleChange(e)}
    //               />
    //             </div>
    //           </section>
    //           <div className="vehicle-info">
    //             <p>
    //               <strong>Extra Services:</strong>
    //               <input
    //                 name="extraServices"
    //                 type="text"
    //                 className="top-inputs-large"
    //                 // value="Volvo | Xc60 Ultimate B5 Mhev Awd Auto | Grey"
    //                 value={formData.extraServices}
    //                 onChange={(e) => handleChange(e)}
    //               />
    //             </p>
    //           </div>
    //         </section>

    //         <section className="services">
    //           <div className="services-row">
    //             <p>
    //               <strong>SSCP £:</strong>
    //               <input
    //                 name="sscp"
    //                 type="text"
    //                 value={formData.sscp}
    //                 onChange={(e) => handleChange(e)}
    //                 className="small-input"
    //               />
    //             </p>
    //             <p>
    //               <strong>Entry:</strong>

    //               <input
    //                 name="entry"
    //                 type="time"
    //                 value={formData.entry}
    //                 onChange={(e) => handleChange(e)}
    //                 className="small-input-right"
    //                 placeholder=":"
    //               />
    //             </p>
    //             <p>
    //               <strong>Exit:</strong>
    //               <input
    //                 name="exit"
    //                 type="time"
    //                 value={formData.exit}
    //                 onChange={(e) => handleChange(e)}
    //                 className="small-input-right"
    //                 placeholder=":"
    //               />
    //             </p>
    //           </div>
    //           <div className="personal-items">
    //             <p>
    //               <strong>ALL Personal Items Removed:</strong> Initial
    //               <input
    //                 name="allPersonal"
    //                 type="text"
    //                 className="initial-input"
    //                 value={formData.allPersonal}
    //                 onChange={(e) => handleChange(e)}
    //               />
    //             </p>
    //             <p className="warning-text">
    //               (The company does not accept liability for personal items)
    //               <a href="#">See Terms</a>
    //             </p>
    //           </div>
    //         </section>
    //       </div>
    //     </div>
    //     <div className="slip-container1">
    //       <div className="top-vehicle-check">
    //         <h6>VHICLE CHECKS ARE CURSORY ONLY</h6>
    //       </div>
    //       <div className="car-veryfication-section">
    //         <div className="mileage-main">
    //           <input
    //             name="mileage"
    //             type="text"
    //             value={formData.mileage}
    //             onChange={(e) => handleChange(e)}
    //             className="mileageinput"
    //           />
    //         </div>
    //         <div className="car-body">
    //           <div className="container-car-main">
    //             <div className="car-image-car">
    //               <button
    //                 className="car-part-btn front-left"
    //                 onClick={() => handleButtonClick('frontLeftTire')}
    //               >
    //                 Front Left Tire
    //               </button>
    //               <button
    //                 className="car-part-btn front-left-door"
    //                 onClick={() => handleButtonClick('frontLeftDoor')}
    //               >
    //                 Front Left Door
    //               </button>
    //               <button
    //                 className="car-part-btn back-left"
    //                 onClick={() => handleButtonClick('backLeftTire')}
    //               >
    //                 Back Left Tire
    //               </button>
    //               <button
    //                 className="car-part-btn back-left-door"
    //                 onClick={() => handleButtonClick('backLeftDoor')}
    //               >
    //                 Back Left Door
    //               </button>
    //               <button
    //                 className="car-part-btn front-sight"
    //                 onClick={() => handleButtonClick('frontBonnet')}
    //               >
    //                 Front Bonnet
    //               </button>
    //               <button
    //                 className="car-part-btn front-sight-bumper"
    //                 onClick={() => handleButtonClick('frontBumper')}
    //               >
    //                 Front Bumper
    //               </button>
    //               <button
    //                 className="car-part-btn back-right"
    //                 onClick={() => handleButtonClick('backRightTire')}
    //               >
    //                 Back Right Tire
    //               </button>
    //               <button
    //                 className="car-part-btn back-right-door"
    //                 onClick={() => handleButtonClick('backRightDoor')}
    //               >
    //                 Back Right Door
    //               </button>
    //               <button
    //                 className="car-part-btn top"
    //                 onClick={() => handleButtonClick('roof')}
    //               >
    //                 The Roof
    //               </button>
    //               <button
    //                 className="car-part-btn back-side"
    //                 onClick={() => handleButtonClick('backSideBoot')}
    //               >
    //                 Back Side Boot
    //               </button>
    //               <button
    //                 className="car-part-btn back-side-bumper"
    //                 onClick={() => handleButtonClick('backRearBumper')}
    //               >
    //                 Back Rear Bumper
    //               </button>
    //               <button
    //                 className="car-part-btn right-front"
    //                 onClick={() => handleButtonClick('rightFrontTire')}
    //               >
    //                 Right Front Tire
    //               </button>
    //               <button
    //                 className="car-part-btn right-front-door"
    //                 onClick={() => handleButtonClick('rightFrontDoor')}
    //               >
    //                 Right Front Door
    //               </button>
    //               <button
    //                 className="car-part-btn battery-front"
    //                 onClick={() => openModal(batteryModalRef)}
    //               >
    //                 Battery
    //               </button>
    //               <button
    //                 className="car-part-btn petrole-side"
    //                 onClick={() => openModal(petrolModalRef)}
    //               >
    //                 Petrol
    //               </button>
    //             </div>

    //             <div
    //               className="car-modal"
    //               id="petrolModal"
    //               ref={petrolModalRef}
    //             >
    //               <div className="car-modal-content">
    //                 <span
    //                   className="car-close-btn"
    //                   onClick={() => closeModal(petrolModalRef)}
    //                 >
    //                   ×
    //                 </span>
    //                 <h2>Petrol Level</h2>
    //                 <label for="petrol">Petrol Level (%):</label>
    //                 <input
    //                   type="number"
    //                   id="petrol"
    //                   min="0"
    //                   max="100"
    //                   name="petrolLevel"
    //                   value={formData.petrolLevel}
    //                   onChange={(e) => handleChange(e)}
    //                 />
    //                 <div className="car-modal-actions">
    //                   <button
    //                     className="car-save-btn"
    //                     //  onclick="saveData()"
    //                     onClick={() => closeModal(petrolModalRef)}
    //                   >
    //                     Save
    //                   </button>
    //                   <button
    //                     className="car-submit-btn"
    //                     // onclick="closePetrolModal()"
    //                     onClick={() => closeModal(petrolModalRef)}
    //                   >
    //                     Close
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="car-modal" ref={batteryModalRef}>
    //               <div className="car-modal-content">
    //                 <span
    //                   className="car-close-btn"
    //                   // onclick="closeBatteryModal()"
    //                   onClick={() => closeModal(batteryModalRef)}
    //                 >
    //                   ×
    //                 </span>
    //                 <h2>Battery Level</h2>
    //                 <label for="battery">Battery Level (%):</label>
    //                 <input
    //                   type="number"
    //                   id="battery"
    //                   min="0"
    //                   max="100"
    //                   name="batteryLevel"
    //                   value={formData.batteryLevel}
    //                   onChange={(e) => handleChange(e)}
    //                 />
    //                 <div className="car-modal-actions">
    //                   <button
    //                     className="car-save-btn"
    //                     onClick={() => closeModal(batteryModalRef)}
    //                   >
    //                     Save
    //                   </button>
    //                   <button
    //                     className="car-submit-btn"
    //                     // onclick="closePetrolModal()"
    //                     onClick={() => closeModal(batteryModalRef)}
    //                   >
    //                     Close
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="car-modal" ref={conditionModalRef}>
    //               <div className="car-modal-content">
    //                 <span
    //                   className="car-close-btn"
    //                   onClick={() => closeModal(conditionModalRef)}
    //                 >
    //                   ×
    //                 </span>
    //                 <div className="car-part" id="carPartLabel">
    //                   {currentPartLabel} {/* Show which part was clicked */}
    //                 </div>

    //                 <h2>Check Condition of {currentPart}</h2>
    //                 <div className="car-checkbox-group">
    //                   <label>
    //                     <input
    //                       type="radio"
    //                       name="condition"
    //                       value="scratch"
    //                       checked={carPartsCondition[currentPart] === 'scratch'} // Control checkbox state
    //                       onChange={handleChangeModal}
    //                     />
    //                     Scratch
    //                   </label>
    //                   <label>
    //                     <input
    //                       type="radio"
    //                       name="condition"
    //                       value="dent"
    //                       checked={carPartsCondition[currentPart] === 'dent'} // Control checkbox state
    //                       onChange={handleChangeModal}
    //                     />
    //                     Dent
    //                   </label>
    //                   <label>
    //                     <input
    //                       type="radio"
    //                       name="condition"
    //                       value="broken"
    //                       checked={carPartsCondition[currentPart] === 'broken'} // Control checkbox state
    //                       onChange={handleChangeModal}
    //                     />
    //                     Broken
    //                   </label>
    //                 </div>

    //                 <div className="car-modal-actions">
    //                   <button
    //                     className="car-save-btn"
    //                     onClick={() => closeModal(conditionModalRef)}
    //                   >
    //                     Save
    //                   </button>
    //                   <button
    //                     className="car-submit-btn"
    //                     onClick={() => closeModal(conditionModalRef)}
    //                   >
    //                     Close
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="form-container">
    //         <table className="condition-table">
    //           <tr>
    //             <td>Condition:</td>
    //             <td>
    //               <input
    //                 type="checkbox"
    //                 name="condition"
    //                 value={'conditionP'}
    //                 onChange={(e) => handleChange(e)}
    //                 checked={formData.condition === 'conditionP'} // Check if this value is selected
    //               />{' '}
    //               P
    //             </td>
    //             <td>
    //               <input
    //                 type="checkbox"
    //                 name="condition"
    //                 value={'conditionG'}
    //                 onChange={(e) => handleChange(e)}
    //                 checked={formData.condition === 'conditionG'} // Check if this value is selected
    //               />{' '}
    //               G
    //             </td>
    //             <td>
    //               <input
    //                 type="checkbox"
    //                 name="condition"
    //                 value={'conditionVG'}
    //                 onChange={(e) => handleChange(e)}
    //                 checked={formData.condition === 'conditionVG'} // Check if this value is selected
    //               />{' '}
    //               VG
    //             </td>
    //             <td>
    //               <input
    //                 type="checkbox"
    //                 name="condition"
    //                 value="conditionClean"
    //                 onChange={(e) => handleChange(e)}
    //                 checked={formData.condition === 'conditionClean'} // Check if this value is selected
    //               />{' '}
    //               Clean
    //             </td>
    //             <td>
    //               <input
    //                 type="checkbox"
    //                 name="condition"
    //                 value="conditionDirty"
    //                 onChange={(e) => handleChange(e)}
    //                 checked={formData.condition === 'conditionDirty'} // Check if this value is selected
    //               />{' '}
    //               Dirty
    //             </td>
    //             <td>
    //               <input
    //                 type="checkbox"
    //                 name="condition"
    //                 value={'conditionVDirty'}
    //                 onChange={(e) => handleChange(e)}
    //                 checked={formData.condition === 'conditionVDirty'} // Check if this value is selected
    //               />{' '}
    //               V Dirty
    //             </td>
    //           </tr>
    //         </table>

    //         <div className="declaration-section">
    //           <p className="declaration">
    //             <span className="highlight">DECLARATION:</span> I AGREE THAT I
    //             HAVE READ & AM WILLING TO BE BOUND BY THE TERMS & CONDITIONS OF
    //             askaii PARKING AND AGREE THAT ALL INFORMATION AS STATED ABOVE IS
    //             CORRECT.
    //             <br />
    //             <span className="highlight">
    //               I AGREE THAT askaii WILL NOT ACCEPT ANY CLAIM UNLESS CLEAR
    //               PHOTOGRAPHIC EVIDENCE FROM THE COLLECTION POINT IS PROVIDED.
    //             </span>
    //             <br />
    //             I AGREE TO PAY ALL FEES FOR CHANGES/ADDITIONAL SERVICES*
    //             <br />
    //             SEE REVERSE FOR KEY TERMS
    //           </p>
    //         </div>

    //         <div className="signature-section">
    //           <table>
    //             <tr>
    //               <td className="label">Initial</td>
    //               <td className="input-box">
    //                 <input type="text" className="initial-field" />
    //               </td>
    //             </tr>
    //           </table>
    //         </div>
    //         <div className="botom-signature">
    //           <div className="unique-notes-section">
    //             <label>Notes</label>
    //             <textarea
    //               className="notestextarea2"
    //               type="text"
    //               name="notes"
    //               value={formData.notes}
    //               onChange={(e) => handleChange(e)}
    //             ></textarea>
    //           </div>

    //           <div className="unique-notes-section">
    //             <label>Customer Sign / T&C's Accepted</label>

    //             <SignatureCanvas
    //               ref={sigCanvas}
    //               penColor="black"
    //               canvasProps={{
    //                 width: 250,
    //                 height: 100,
    //                 className: 'signatureCanvas',
    //                 style: {
    //                   border: '1px solid #000',
    //                   borderRadius: '5px',
    //                   backgroundColor: '#FFF',
    //                 },
    //               }}
    //             />

    //             <div style={{ marginTop: '10px' }}>
    //               <button onClick={clear} style={{ marginRight: '5px' }}>
    //                 Clear
    //               </button>
    //               <button onClick={save}>Save</button>
    //             </div>

    //             {/* {signatureURL && (
    //               <div style={{ marginTop: "20px" }}>
    //                 <h3>Saved Signature</h3>
    //                 <img
    //                   src={signatureURL}
    //                   alt="Saved signature"
    //                   style={{ border: "1px solid #000" }}
    //                 />
    //               </div>
    //             )} */}
    //           </div>

    //           <div
    //             ref={uniqueSignatureModalRef}
    //             className="unique-modal-signature"
    //           >
    //             <div className="unique-modal-content-signature">
    //               <span
    //                 className="unique-close-signature"
    //                 onClick={() => closeModal(uniqueSignatureModalRef)}
    //               >
    //                 &times;
    //               </span>
    //               <h2>Draw your Signature</h2>
    //               <div className="unique-signature-section">
    //                 <canvas
    //                   ref={uniqueSignatureCanvasRef}
    //                   className="unique-canvas-signature"
    //                   width="500"
    //                   height="200"
    //                 ></canvas>
    //               </div>
    //               <button
    //                 className="unique-signature-btn"
    //                 onClick={() => {
    //                   saveUniqueSignature()
    //                   closeModal(uniqueSignatureModalRef)
    //                 }}
    //               >
    //                 Save Signature
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <button type="submit">Submit</button>
    //   </form>
    // </div>
  )
}

export default DriverReceivingSlip
