import React, { useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import { toast, Slide, ToastContainer } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'
import profile from './../../../Assets/img/Profile 2.png'
import { verifyToken } from '../../..'
import useFetch from '../../../CustomHooks/useFetch'
import './Captains.css'

function Captains() {
  const [token, setToken] = useState('')
  const apiURL = `${API_BASE_URL}/api/Driver/GetAllDashDrivers`
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const data = useLoaderData()
  const [captains, setCaptains] = useState(data)
  const [selectedCaptains, setSelectedCaptains] = useState([])

  const [driverName, setDriverName] = useState('')
  const [licenseNumber, setLicenseNumber] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [isAvailable, setIsAvailable] = useState('')
  const [captainStatus, setCaptainStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(4)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalCaptains = captains.length

  const { triggerData } = useFetch(apiURL, token, selectedCaptains)
  console.log(triggerData)

  useEffect(() => {
    if (triggerData) {
      setCaptains(triggerData)
    }
  }, [captains, triggerData, selectedCaptains])

  function handleStatusChange(e) {
    setCaptainStatus(e.target.value === 'true')
  }

  const handleCreateCaptain = async (e) => {
    e.preventDefault()
    const newCaptain = {
      driverName: driverName,
      LicenseNumber: licenseNumber,
      PhoneNumber: phoneNumber,
      Address: address,
      isActive: captainStatus,
    }

    console.log(newCaptain)
    try {
      const response = await fetch(`${API_BASE_URL}/api/Driver/CreateDriver`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(newCaptain),
      })

      const createdCaptain = await response.json()
      console.log(createdCaptain)
      if (response.ok) {
        toast.success(`Captain '${createdCaptain.driverName}' created`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Captain.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedCaptains([])
    setDriverName('')
    setLicenseNumber('')
    setPhoneNumber('')
    setCaptainStatus(true)
  }

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  const handleEditCaptain = async (e) => {
    e.preventDefault()
    const selectedCaptain = captains.find(
      (captain) => captain.driverID === selectedCaptains[0]
    )

    const updatedCaptain = {
      ...selectedCaptain,
      driverName,
      licenseNumber,
      phoneNumber,
      isActive: captainStatus,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/VehicleController/UpdateColorDetails/${selectedCaptain.driverID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(updatedCaptain),
        }
      )

      if (response.ok) {
        console.log(updatedCaptain)
        console.log(captains)
        toast.success(`Captain '${selectedCaptain.driverName}' updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update Captain.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Captain: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleEditButtonClick = () => {
    const selectedCaptain = captains.find(
      (captain) => captain.driverID === selectedCaptains[0]
    )
    setDriverName(selectedCaptain.driverName)
    setLicenseNumber(selectedCaptain.licenseNumber)
    setPhoneNumber(selectedCaptains.phoneNumber)
    setCaptainStatus(selectedCaptain.isActive)

    setIsEditMode(true)
    setIsModalOpen(true)
  }

  const handleDetailsCaptain = () => {
    const selectedCaptain = captains.find(
      (captain) => captain.driverID === selectedCaptains[0]
    )
    setDriverName(selectedCaptain.driverName)
    setLicenseNumber(selectedCaptain.licenseNumber)
    setPhoneNumber(selectedCaptains.phoneNumber)
    setCaptainStatus(selectedCaptain.isActive)
  }

  const handleDetailCaptain = (singleCaptain) => {
    const selectedCaptain = captains.find(
      (captain) => captain.driverID === singleCaptain
    )
    setDriverName(selectedCaptain.driverName)
    setLicenseNumber(selectedCaptain.licenseNumber)
    setPhoneNumber(selectedCaptains.phoneNumber)
    setCaptainStatus(selectedCaptain.isActive)
  }

  const handleDeleteCaptain = async () => {
    const selectedCaptain = { driverID: selectedCaptains }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Vehicle/BulkDeleteCaptain`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(selectedCaptain),
        }
      )

      if (response.ok) {
        toast.success(`Selected Captains deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedCaptains([])
      }
    } catch (error) {
      toast.error(`Error deleting Captains: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setDriverName('')
    setLicenseNumber('')
    setPhoneNumber('')
  }

  const handleDeleteButtonClick = () => {
    const selectedCaptain = captains.find(
      (captain) => captain.driverID === selectedCaptains[0]
    )
    setDriverName(selectedCaptain.driverName)
  }

  const handleToggleCaptainStatus = async (isActive) => {
    try {
      const selectedCaptain = {
        driverID: selectedCaptains,
        isActive: isActive,
      }
      const response = await fetch(
        `${API_BASE_URL}/api/Vehicle/BulkActiveCaptain`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(selectedCaptain),
        }
      )

      if (response.ok) {
        toast.success(`Selected Captains updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })

        setSelectedCaptains([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating Captain: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredCaptains = captains.filter((captain) => {
    const matchesSearchTerm =
      captain.driverName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      captain.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      captain.licenseNumber?.toLowerCase().includes(searchTerm.toLowerCase())

    const captainDate = new Date(captain.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedCaptainDate = formatDate(captainDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedCaptainDate >= formattedFromDate &&
          formattedCaptainDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedCaptainDate === formattedFromDate
      }

      return true
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (driverID) => {
    setSelectedCaptains((prevSelected) => {
      let newSelection
      newSelection = prevSelected.includes(driverID)
        ? prevSelected.filter((id) => id !== driverID)
        : [...prevSelected, driverID]
      return newSelection
    })
  }

  const totalPages = Math.ceil(filteredCaptains.length / Number(itemsPerPage))
  const startIndex = (currentPage - 1) * Number(itemsPerPage)

  const endIndex = startIndex + Number(itemsPerPage)

  const currentCaptains = filteredCaptains.reverse().slice(startIndex, endIndex)

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * Number(itemsPerPage))
  }

  const calculateDisplayRange = () => {
    return `${currentCaptains.length}/${totalCaptains}`
  }
  console.log(currentCaptains)

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>{' '}
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedCaptains.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsCaptain}
                    title="Details"
                    disabled={selectedCaptains.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Captain Name: '}</p>
                            <p className="ms-2 mb-0">{driverName}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Captain Type: '}</p>
                            <p className="ms-2 mb-0">{phoneNumber}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Captain Country: '}</p>
                            <p className="ms-2 mb-0">
                              {/* {captainCountry},{captainCity} */}
                            </p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Captain City: '}</p>
                            {/* <p className="ms-2 mb-0">{captainCity}</p> */}
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Captain Country: '}</p>
                            {/* <p className="ms-2 mb-0">{captainCountry}</p> */}
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedCaptains.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedCaptains.length === 1
                                ? ` '${driverName}' record?`
                                : `${selectedCaptains.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteCaptain}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as active"
                    onClick={() => handleToggleCaptainStatus(true)}
                    disabled={
                      (selectedCaptains.length === 0 &&
                        !isActiveButtonEnabled) ||
                      captains
                        .filter((captain) =>
                          selectedCaptains.includes(captain.driverID)
                        )
                        .every((captain) => captain.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-on"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as inactive"
                    onClick={() => handleToggleCaptainStatus(false)}
                    disabled={
                      (selectedCaptains.length === 0 &&
                        !isActiveButtonEnabled) ||
                      captains
                        .filter((captain) =>
                          selectedCaptains.includes(captain.driverID)
                        )
                        .every((captain) => !captain.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-off"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div
                id="CaptainmodelMake"
                tabIndex="-1"
                aria-labelledby="CaptainModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode ? handleEditCaptain : handleCreateCaptain
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="CaptainModalLabel">
                          {isEditMode ? 'Edit Captain' : 'Add New Captain'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="d-flex align-items-center img-input-main">
                            <div id="profilePic-3" className="profile-pic">
                              <img id="imagePreview" alt="" />
                            </div>
                            <input
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              className="input-driver-img"
                              // Optional: Handle image preview or upload logic if needed
                            />
                          </div>
                        </form>

                        <div className="d-flex mt-4">
                          <div>
                            <label htmlFor="Name" className="form-label">
                              Full Name
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                aria-label="First Name"
                                aria-describedby="basic-addon1"
                                value={driverName}
                                onChange={(e) => setDriverName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="ms-2">
                            <label htmlFor="Phone" className="form-label">
                              Phone #
                            </label>
                            <div className="input-group mb-3 ">
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="+928*****"
                                aria-label="Phone #"
                                aria-describedby="basic-addon1"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div>
                            <label htmlFor="Name" className="form-label">
                              Email
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="ms-2">
                            <label htmlFor="Phone" className="form-label">
                              Address
                            </label>
                            <div className="input-group mb-3 ">
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <label htmlFor="License" className="form-label">
                            License #
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              data-bs-title="Marked as primary"
                            >
                              {/* <input type="radio" /> */}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="*** *** ***"
                              aria-label="License"
                              aria-describedby="basic-addon1"
                              value={licenseNumber}
                              onChange={(e) => setLicenseNumber(e.target.value)}
                            />
                          </div>
                        </div>

                        <div>
                          <label htmlFor="Status" className="form-label">
                            Status
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type="radio"
                              name="Active"
                              value="1"
                              className="me-2"
                              checked={captainStatus === true}
                              onChange={() => setCaptainStatus(true)}
                            />
                            <label htmlFor="Active">Active</label>
                            <input
                              type="radio"
                              name="Active"
                              value="2"
                              className="me-2 ms-2"
                              checked={captainStatus === false}
                              onChange={() => setCaptainStatus(false)}
                            />
                            <label htmlFor="In Active">In Active</label>
                          </div>
                        </div>

                        <label htmlFor="Available" className="form-label">
                          Available / Not Available
                        </label>
                        <div className="input-group">
                          <input
                            type="radio"
                            name="yes"
                            value="1"
                            className="me-2"
                            checked={isAvailable === '1'}
                            onChange={() => setIsAvailable('1')}
                          />
                          <label htmlFor="yes">Yes</label>
                          <input
                            type="radio"
                            name="yes"
                            value="2"
                            className="me-2 ms-2"
                            checked={isAvailable === '2'}
                            onChange={() => setIsAvailable('2')}
                          />
                          <label htmlFor="NO">NO</label>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update Captain' : 'Create Captain'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="captain-main-container scroll-bar">
          {currentCaptains.map((captain) => (
            <div className="col-lg-6 col-md-12 mt-2 ">
              <div className="testimonials-main captains-main h-100">
                <input
                  type="checkbox"
                  checked={selectedCaptains.includes(captain.driverID)}
                  onChange={(e) => handleCheckboxChange(captain.driverID)}
                />
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12">
                    <div className="testimonials-left text-center">
                      <img
                        src={profile}
                        alt="client-photo"
                        className="rounded"
                      />
                      <h5 className=" mt-3">{captain.driverName}</h5>
                      <p className="mb-0">{captain.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="testimonials-right">
                      <div className="license d-flex align-items-center mb-1">
                        <h5 className="me-2 mb-0">License #</h5>
                        <p className="mb-0">{captain.licenseNumber}</p>
                        <div className="icon-btns d-flex justify-content-between">
                          {/* <!-- Button Edit modal --> */}
                          <button
                            type="button"
                            className="details-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#EditModal"
                          >
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Edit"
                            >
                              <i
                                className="fa-solid fa-pencil"
                                title="Edit"
                              ></i>
                            </span>
                          </button>
                          {/* <div
                          className="modal fade"
                          id="EditModal"
                          tabindex="-1"
                          aria-labelledby="EditModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h1
                                  className="modal-title fs-5"
                                  id="EditModalLabel"
                                >
                                  Do you want to Edit?
                                </h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <form>
                                  <div className="d-flex align-items-center">
                                    <div
                                      id="profilePic-3"
                                      className="profile-pic"
                                    >
                                      <img id="imagePreview" alt="" />
                                    </div>
                                    <input
                                      type="file"
                                      id="fileInput"
                                      accept="image/*"
                                      className="d-none"
                                    />

                                    <div
                                      className="ms-4 profile-pic"
                                      id="profilePic"
                                    >
                                      <button
                                        type="button"
                                        className="change-btn"
                                      >
                                        {' '}
                                        Change{' '}
                                      </button>
                                    </div>

                                    <button
                                      type="button"
                                      className="remove-btn ms-3"
                                    >
                                      Remove{' '}
                                    </button>
                                  </div>
                                </form>

                                <div className="d-flex mt-4">
                                  <div>
                                    <label for="Name" className="form-label">
                                      Full Name
                                    </label>
                                    <div className="input-group mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Full Name"
                                        aria-label="First Name"
                                        aria-describedby="basic-addon1"
                                      />
                                    </div>
                                  </div>

                                  <div className="ms-2">
                                    <label for="Phone" className="form-label">
                                      Phone #
                                    </label>
                                    <div className="input-group mb-3 ">
                                      <input
                                        type="tel"
                                        className="form-control"
                                        placeholder="+928*****"
                                        aria-label="Phone #"
                                        aria-describedby="basic-addon1"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <label for="License" className="form-label">
                                    License #
                                  </label>
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="right"
                                      data-bs-title="Marked as primary"
                                    >
                                      <input type="radio" />
                                    </span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="*** *** ***"
                                      aria-label="License"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>

                                <div>
                                  <label for="Status" className="form-label">
                                    Status
                                  </label>
                                  <div className="input-group mb-3">
                                    <input
                                      type="radio"
                                      name="Active"
                                      value="1"
                                      className="me-2"
                                    />
                                    <label for="Active">Active</label>
                                    <input
                                      type="radio"
                                      name="Active"
                                      value="2"
                                      className="me-2 ms-2"
                                    />
                                    <label for="In Active">In Active</label>
                                  </div>
                                </div>

                                <label for="Available" className="form-label">
                                  Available / Not Available
                                </label>
                                <div className="input-group">
                                  <input
                                    type="radio"
                                    name="yes"
                                    value="1"
                                    className="me-2"
                                  />
                                  <label for="yes">Yes</label>
                                  <input
                                    type="radio"
                                    name="yes"
                                    value="2"
                                    className="me-2 ms-2"
                                  />
                                  <label for="NO">NO</label>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="cancel-btn"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button type="button" className="site-btn">
                                  Update changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> */}
                          {/* <!-- Button Delete modal --> */}
                          <button
                            type="button"
                            className="details-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#DeleteModal"
                          >
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Delete"
                            >
                              <i
                                className="fa-solid fa-trash"
                                title="Delete"
                              ></i>
                            </span>
                          </button>

                          <div
                            className="modal fade"
                            id="DeleteModal"
                            tabindex="-1"
                            aria-labelledby="DeleteModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-scrollable">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h1
                                    className="modal-title fs-5"
                                    id="DeleteModalLabel"
                                  >
                                    Confirmation
                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div className="modal-body">
                                  <div className="mb-3">
                                    <label className="fs-5">
                                      Do you want to Delete?{' '}
                                    </label>
                                    <br />
                                  </div>

                                  <div className="form-check">
                                    <input
                                      className="form-check-input fs-7"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label fs-7"
                                      for="flexCheckDefault"
                                    >
                                      Never ask again
                                    </label>
                                  </div>
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="cancel-btn"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button type="button" className="site-btn">
                                    Update changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="available text-center d-flex">
                        {captain.isAvailable ? (
                          <button
                            type="button"
                            className="available-btn me-3"
                            // disabled={captain.isAvailable}
                          >
                            Available
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="remove-btn "
                            // disabled={captain.isAvailable === false}
                          >
                            {' '}
                            Not Available
                          </button>
                        )}
                      </div>

                      <div>
                        <h4 className="big fs-6 mt-3">
                          Captain's Complete Bookings
                        </h4>
                        <div
                          className="captains-booking mt-2 mb-3"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          data-bs-title="Complete Bookings"
                        >
                          <span>100</span>
                        </div>
                      </div>

                      <div className="rating-email mt-4 d-inline">
                        <div
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Good Rating"
                        >
                          <span>
                            <i className="fa-solid fa-star"></i>
                          </span>
                          <span>
                            <i className="fa-solid fa-star"></i>
                          </span>
                          <span>
                            <i className="fa-solid fa-star"></i>
                          </span>
                          <span>
                            <i className="fa-solid fa-star"></i>
                          </span>
                          <span>
                            <i className="fa-solid fa-star"></i>
                          </span>
                        </div>

                        <p>{captain.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing Captains: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Captains
