import React from 'react'
import { NavLink } from 'react-router-dom'

function Back() {
  return (
    <li className="nav-item">
      <NavLink to="/" className="btn-3 text-decoration-none">
        <i className="fa-solid fa-arrow-circle-left"></i> Go Back
      </NavLink>
    </li>
  )
}

export default Back
