import React, { useEffect, useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { toast, Slide, ToastContainer } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

function Terminals() {
  const { airportID } = useParams()
  const navigate = useNavigate()
  // const data = useLoaderData()

  const [terminals, setTerminals] = useState([])
  const [selectedTerminals, setSelectedTerminals] = useState([])

  const [terminalName, setTerminalName] = useState('')
  const [terminalType, setTerminalType] = useState('')
  const [terminalDescription, setTerminalDescription] = useState('')
  const [terminalCode, setTerminalCode] = useState('')
  const [terminalStatus, setTerminalStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalTerminals = terminals.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  useEffect(() => {
    const fetchTerminals = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Terminal/GetAllbyAirPort/${airportID}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        const inbounds = data.filter(
          (terminal) => terminal.terminalType === 'InBound'
        )
        const outbounds = data.filter(
          (terminal) => terminal.terminalType === 'OutBound'
        )

        setTerminals([...inbounds, ...outbounds])
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
    fetchTerminals()
  }, [airportID])

  function handleBack() {
    navigate(-1)
  }

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value
    setTerminalType(selectedValue)
  }
  function handleStatusChange(e) {
    setTerminalStatus(e.target.value === 'true')
  }

  // Handle Terminal creation
  const handleCreateTerminal = async (e) => {
    e.preventDefault()
    const newTerminal = {
      terminalName,
      terminalType,
      terminalDescription,
      terminalCode,
      isActive: terminalStatus,
      isDeleted: false,
    }
    console.log(newTerminal)

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Terminal/CreateTerminalDetails/${airportID}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(newTerminal),
        }
      )

      if (response.ok) {
        const createdTerminal = await response.json()
        setTerminals((prevTerminals) => [...prevTerminals, createdTerminal])
        toast.success(`Terminal '${createdTerminal.terminalName}' created`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Terminal.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new Terminal
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedTerminals([])

    setTerminalName('')
    setTerminalType('')
    setTerminalDescription('')
    setTerminalCode('')

    setTerminalStatus(true)
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle Terminal update
  const handleEditTerminal = async (e) => {
    e.preventDefault()
    const selectedTerminal = terminals.find(
      (terminal) => terminal.terminalID === selectedTerminals[0]
    )

    console.log(selectedTerminal)
    const updatedTerminal = {
      ...selectedTerminal,
      terminalName,
      terminalType,
      terminalDescription,
      terminalCode,
      isActive: terminalStatus,
      isDeleted: false,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Terminal/UpdateTerminalDetails/${selectedTerminal.terminalID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedTerminal),
        }
      )

      if (response.ok) {
        const updatedTerminalData = await response.json()
        setTerminals((prevTerminals) =>
          prevTerminals.map((terminal) =>
            terminal.terminalID === updatedTerminalData.terminalID
              ? updatedTerminalData
              : terminal
          )
        )
        console.log(updatedTerminal)
        console.log(terminals)
        toast.success(`Terminal '${selectedTerminal.terminalName}' updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update Terminal.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Terminal: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing Terminal
  const handleEditButtonClick = () => {
    const selectedTerminal = terminals.find(
      (terminal) => terminal.terminalID === selectedTerminals[0]
    )
    console.log(selectedTerminal)
    setTerminalName(selectedTerminal.terminalName)
    setTerminalType(selectedTerminal.terminalType)
    setTerminalDescription(selectedTerminal.terminalDescription)
    setTerminalCode(selectedTerminal.terminalCode)
    setTerminalStatus(selectedTerminal.isActive)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Terminal
  const handleDetailsTerminal = () => {
    const selectedTerminal = terminals.find(
      (terminal) => terminal.terminalID === selectedTerminals[0]
    )
    setTerminalName(selectedTerminal.terminalName)
    setTerminalType(selectedTerminal.terminalType)
    setTerminalDescription(selectedTerminal.terminalDescription)
    setTerminalCode(selectedTerminal.terminalCode)

    setTerminalStatus(selectedTerminal.isActive)
  }

  const handleDetailTerminal = (singleTerminal) => {
    const selectedTerminal = terminals.find(
      (terminal) => terminal.terminalID === singleTerminal
    )
    setTerminalName(selectedTerminal.terminalName)
    setTerminalType(selectedTerminal.terminalType)
    setTerminalDescription(selectedTerminal.terminalDescription)
    setTerminalCode(selectedTerminal.terminalCode)

    setTerminalStatus(selectedTerminal.isActive)
  }

  // Handle Terminal deletion
  const handleDeleteTerminal = async () => {
    const selectedTerminal = { terminalID: selectedTerminals }
    console.log(selectedTerminal)
    try {
      const response = await fetch(`${API_BASE_URL}/api/Terminal/BulkDelete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(selectedTerminal),
      })

      if (response.ok) {
        setTerminals((prevTerminals) =>
          prevTerminals.filter(
            (terminal) => !selectedTerminals.includes(terminal.terminalID)
          )
        )
        toast.success(`Selected Terminals deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedTerminals([])
      }
    } catch (error) {
      toast.error(`Error deleting Terminals: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setTerminalName('')
    setTerminalType('')
    setTerminalDescription('')
    setTerminalCode('')
  }

  // Show modal for deleting Terminal
  const handleDeleteButtonClick = () => {
    const selectedTerminal = terminals.find(
      (terminal) => terminal.terminalID === selectedTerminals[0]
    )
    setTerminalName(selectedTerminal.terminalName)
  }

  // Handle Terminal activation/deactivation
  const handleToggleTerminalStatus = async (isActive) => {
    try {
      const validTerminals = selectedTerminals.filter((id) => {
        const terminal = terminals.find(
          (terminal) => terminal.terminalID === id
        )
        return terminal.terminalName
      })
      const selectedTerminal = {
        terminalID: selectedTerminals,
        isActive: isActive,
      }
      const response = await fetch(`${API_BASE_URL}/api/Terminal/BulkActive`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(selectedTerminal),
      })

      if (response.ok) {
        setTerminals((prevTerminals) =>
          prevTerminals.map((terminal) =>
            validTerminals.includes(terminal.terminalID)
              ? { ...terminal, isActive }
              : terminal
          )
        )
        toast.success(`Selected Terminals updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })

        setSelectedTerminals([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating Terminal: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredTerminals = terminals.filter((terminal) => {
    const matchesSearchTerm =
      terminal.terminalName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      terminal.terminalType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      terminal.terminalAddress
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      terminal.terminalCountry
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      terminal.terminalState
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      terminal.terminalCity?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      terminal.terminalZipCode?.toLowerCase().includes(searchTerm.toLowerCase())

    const terminalDate = new Date(terminal.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      // Helper function to format date to 'dd/mm/yyyy'
      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedTerminalDate = formatDate(terminalDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedTerminalDate >= formattedFromDate &&
          formattedTerminalDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedTerminalDate === formattedFromDate // Exact match for fromDate
      }

      return true // If no fromDate or toDate, show all data
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (terminalId) => {
    setSelectedTerminals((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(terminalId)
        ? prevSelected.filter((id) => id !== terminalId)
        : [...prevSelected, terminalId]

      const hasTerminalWithBoth = newSelection.some((id) => {
        const selectedTerminal = terminals.find(
          (terminal) => terminal.terminalID === id
        )
        return selectedTerminal.terminalName && selectedTerminal.terminalCode
      })

      setIsActiveButtonEnabled(hasTerminalWithBoth)
      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(filteredTerminals.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentTerminals = filteredTerminals
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentTerminals.length}/${totalTerminals}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleBack}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i class="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedTerminals.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsTerminal}
                    title="Details"
                    disabled={selectedTerminals.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Terminal Name: '}</p>
                            <p className="ms-2 mb-0">{terminalName}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Terminal Type: '}</p>
                            <p className="ms-2 mb-0">{terminalType}</p>
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Terminal Country: '}</p>
                            <p className="ms-2 mb-0">{terminalCode}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedTerminals.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedTerminals.length === 1
                                ? ` '${terminalName}' record?`
                                : `${selectedTerminals.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteTerminal}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as active"
                    onClick={() => handleToggleTerminalStatus(true)}
                    disabled={
                      (selectedTerminals.length === 0 &&
                        !isActiveButtonEnabled) ||
                      terminals
                        .filter((terminal) =>
                          selectedTerminals.includes(terminal.terminalID)
                        )
                        .every((terminal) => terminal.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-on"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as inactive"
                    onClick={() => handleToggleTerminalStatus(false)}
                    disabled={
                      (selectedTerminals.length === 0 &&
                        !isActiveButtonEnabled) ||
                      terminals
                        .filter((terminal) =>
                          selectedTerminals.includes(terminal.terminalID)
                        )
                        .every((terminal) => !terminal.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-off"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div
                id="TerminalmodelMake"
                tabIndex="-1"
                aria-labelledby="TerminalModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode ? handleEditTerminal : handleCreateTerminal
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="TerminalModalLabel">
                          {isEditMode ? 'Edit Terminal' : 'Add New Terminal'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Terminal Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={terminalName}
                            onChange={(e) => setTerminalName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Terminal Type
                          </label>
                          {/* <input
                            type="text"
                            className="form-control"
                            value={terminalType}
                            onChange={(e) => setTerminalType(e.target.value)}
                            required
                          /> */}
                          <select
                            name="terminalType"
                            value={terminalType}
                            onChange={handleSelectChange}
                            className="form-control"
                          >
                            <option value="" disabled>
                              Select Terminal
                            </option>
                            <option value="InBound">Inbound Terminal</option>
                            <option value="OutBound">Outbound Terminal</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Terminal Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={terminalDescription}
                            onChange={(e) =>
                              setTerminalDescription(e.target.value)
                            }
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Terminal Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={terminalCode}
                            onChange={(e) => setTerminalCode(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3 active-inactive-all">
                          <div>
                            <input
                              type="radio"
                              id="active"
                              name="terminalStatus"
                              value="true"
                              onChange={handleStatusChange}
                              checked={terminalStatus === true}
                            />
                            <label htmlFor="active">Active</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="inactive"
                              name="terminalStatus"
                              value="false"
                              onChange={handleStatusChange}
                              checked={terminalStatus === false}
                            />
                            <label htmlFor="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update Terminal' : 'Create Terminal'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      selectedTerminals.length === terminals.length &&
                      terminals.length >= 1
                    }
                    onChange={() =>
                      setSelectedTerminals(
                        selectedTerminals.length === terminals.length
                          ? []
                          : terminals.map((terminal) => terminal.terminalID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Terminal Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Terminal Type
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Terminal Address
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Terminal Country
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTerminals.map((terminal, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${
                    terminal.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={terminal.terminalID}
                  style={
                    terminal.isActive ? { backgroundColor: '#D3D3D3' } : {}
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedTerminals.includes(terminal.terminalID)}
                      onChange={(e) =>
                        handleCheckboxChange(terminal.terminalID, index, e)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailTerminal(terminal.terminalID)}
                  >
                    {terminal.terminalName}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailTerminal(terminal.terminalID)}
                  >
                    {terminal.terminalType}
                  </td>
                  <td>{terminal.terminalDescription}</td>
                  <td>{terminal.terminalCode}</td>
                  <td>{terminal.terminalState}</td>
                  <td>{terminal.terminalCity}</td>
                  <td>{terminal.terminalZipCode}</td>
                  <td>{terminal.createdDate}</td>
                  {/* <td>{terminal.createdDate}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <div className="pagination-container">
              <div className="pagination-left">
                <div className="items-per-page-label">Items per page:</div>
                <select
                  name="faqPerPage"
                  onChange={(e) => setItemPerPage(e.target.value)}
                  className="items-per-page-select form-control"
                >
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="60">60</option>
                  <option value="75">75</option>
                  <option value="90">90</option>
                </select>
              </div>

              <div className="pagination-right">
                <div className="faq-display-range">
                  Showing Terminals: {calculateDisplayRange()}
                </div>
                <ul className="pagination-list pagination ">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handlePrevious}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      className={`page-item ${
                        currentPage === index + 1 ? 'active' : ''
                      }`}
                      key={index}
                    >
                      <button
                        className="page-link"
                        onClick={() => handleSerialNumberChange(index)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Terminals
