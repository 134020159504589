import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'

const DelayedCars = () => {
  const data = useLoaderData()
  const [delayedCars, setDelayedCars] = useState(data)
  const [selectedDelayedCars, setSelectedDelayedCars] = useState([])

  const [numberPlate, setNumberPlate] = useState('')
  const [carMake, setCarMake] = useState('')
  const [carModel, setCarModel] = useState('')
  const [bookingNumber, setBookingNumber] = useState('')
  const [driverName, setDriverName] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalDelayedCars = delayedCars.length

  // Handle DelayedCar creation
  const handleCreateDelayedCars = async (e) => {
    e.preventDefault()
    const newDelayedCar = {
      numberPlate,
      carMake,
      carModel,
      bookingNumber,
      driverName,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/DELAYEDCARs/CreateDELAYEDCARs`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newDelayedCar),
        }
      )

      if (response.ok) {
        const createdDelayedCar = await response.json()
        setDelayedCars((prevDelayedCars) => [
          ...prevDelayedCars,
          createdDelayedCar,
        ])
        toast.success(`DelayedCar '${createdDelayedCar.carMake}' created`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create DelayedCar.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new DelayedCar
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setCarMake('')
    setNumberPlate('')
    setCarModel(1)
    setIsEditMode(false)
    setSelectedDelayedCars([])
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      setCarMake('')
      setNumberPlate('')
      setCarModel('')
      setIsEditMode(false)
      setIsModalOpen(false)
    }
  }

  // Handle DelayedCar update
  const handleEditDelayedCar = async (e) => {
    e.preventDefault()
    const selectedDelayedCar = delayedCars.find(
      (delayedCar) => delayedCar.delayedCarsID === selectedDelayedCars[0]
    )
    const updatedDelayedCar = {
      ...selectedDelayedCar,
      numberPlate,
      carMake,
      carModel,
      bookingNumber,
      driverName,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/DELAYEDCARs/UpdateDELAYEDCARs/${selectedDelayedCar.delayedCarsID}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedDelayedCar),
        }
      )

      if (response.ok) {
        const updatedDelayedCarData = await response.json()
        setDelayedCars((prevDelayedCars) =>
          prevDelayedCars.map((delayedCar) =>
            delayedCar.delayedCarsID === updatedDelayedCarData.delayedCarsID
              ? updatedDelayedCarData
              : delayedCar
          )
        )
        toast.success(`DELAYEDCAR '${selectedDelayedCar.carMake}' updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update DELAYEDCAR.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating DELAYEDCAR: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing DelayedCar
  const handleEditButtonClick = () => {
    const selectedDelayedCar = delayedCars.find(
      (delayedCar) => delayedCar.delayedCarsID === selectedDelayedCars[0]
    )
    setCarMake(selectedDelayedCar.carMake)
    setNumberPlate(selectedDelayedCar.numberPlate)
    setCarModel(selectedDelayedCar.carModel)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details DelayedCar
  const handleDetailsDelayedCar = () => {
    const selectedDelayedCar = delayedCars.find(
      (delayedCar) => delayedCar.delayedCarsID === selectedDelayedCars[0]
    )
    setCarMake(selectedDelayedCar.carMake)
    setNumberPlate(selectedDelayedCar.numberPlate)
  }

  const handleDetailDelayedCar = (singleDelayedCar) => {
    const selectedDelayedCar = delayedCars.find(
      (delayedCar) => delayedCar.delayedCarsID === singleDelayedCar
    )
    setCarMake(selectedDelayedCar.carMake)
    setNumberPlate(selectedDelayedCar.numberPlate)
  }

  // Handle DelayedCar deletion
  const handleDeleteDelayedCar = async () => {
    const selectedDelayedCar = { delayedCarsIDs: selectedDelayedCars }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/DELAYEDCARs/BulkDelete`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(selectedDelayedCar),
        }
      )

      if (response.ok) {
        setDelayedCars((prevDelayedCars) =>
          prevDelayedCars.filter(
            (delayedCar) =>
              !selectedDelayedCars.includes(delayedCar.delayedCarsID)
          )
        )
        toast.success(`Selected DELAYEDCARs deleted`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        setSelectedDelayedCars([])
      }
    } catch (error) {
      toast.error(`Error deleting DELAYEDCAR: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
    setIsModalOpen(false)
    setCarMake('')
    setCarModel(1)
  }

  // Show modal for editing DelayedCar
  const handleDeleteButtonClick = () => {
    const selectedDelayedCar = delayedCars.find(
      (delayedCar) => delayedCar.delayedCarsID === selectedDelayedCars[0]
    )
    setCarMake(selectedDelayedCar.carMake)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredDelayedCars = delayedCars.filter((delayedCar) => {
    const delayedCarDate = new Date(delayedCar.delayedCarCreatedDate) // Convert string date to Date

    // Check if DELAYEDCAR carMake includes the search term (case insensitive)
    const matchesSearchTerm =
      delayedCar.carMake?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      delayedCar.numberPlate?.toLowerCase().includes(searchTerm.toLowerCase())
    // Check date filtering conditions
    const matchesDateRange = (() => {
      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate)
        const toDateObj = new Date(fromDate)
        // If both fromDate and toDate are set, filter by the range
        return (
          delayedCarDate.toDateString() === fromDateObj.toDateString() &&
          delayedCarDate.toDateString() === toDateObj.toDateString()
        )
      } else if (fromDate) {
        const fromDateObj = new Date(fromDate)
        return delayedCarDate.toDateString() === fromDateObj.toDateString()
      }
      // If neither fromDate nor toDate is set, match all dates
      return true
    })()

    // Return true only if both search term and date range conditions are met
    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (delayedCarId) => {
    setSelectedDelayedCars((prevSelected) => {
      // Normal click: toggle selection
      let newSelection = prevSelected.includes(delayedCarId)
        ? prevSelected.filter((id) => id !== delayedCarId)
        : [...prevSelected, delayedCarId]

      // Check if the active button should be enabled
      const hasDelayedCarWithAll = newSelection.some((id) => {
        const selectedDelayedCar = delayedCars.find(
          (delayedCar) => delayedCar.delayedCarsID === id
        )
        return selectedDelayedCar.carMake && selectedDelayedCar.numberPlate
      })

      setIsActiveButtonEnabled(hasDelayedCarWithAll)

      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * itemsPerPage)
  }

  // Calculate total pages based on filtered DELAYEDCARs
  const totalPages = Math.ceil(filteredDelayedCars.length / itemsPerPage)
  // Calculate the index range of DELAYEDCARs to display
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  // DELAYEDCARs to be displayed on the current page
  const currentDelayedCars = filteredDelayedCars
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentDelayedCars.length}/${totalDelayedCars}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Edit"
                    onClick={handleEditButtonClick}
                    disabled={selectedDelayedCars.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsDelayedCar}
                    title="Details"
                    disabled={selectedDelayedCars.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>
                  {/* {////////////////////////////////////////////////////////////////////////////////////////////} */}

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'CarMake: '}</p>
                            <p className="ms-2 mb-0">{carMake}</p>
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            <p className="ms-2 mb-0">{'NumberPlate: '}</p>
                            <p className="ms-2 mb-0">{numberPlate}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedDelayedCars.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Are you sure to want to delete{' '}
                              {selectedDelayedCars.length === 1
                                ? ` '${carMake}' record?`
                                : `${selectedDelayedCars.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteDelayedCar}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add CarMake Modal /////////////////////////////////////////////////////////////////////////////////// */}
              <div
                id="CarMakemodelMake"
                tabIndex="-1"
                aria-labelledby="CarMakeModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode
                          ? handleEditDelayedCar
                          : handleCreateDelayedCars
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="CarMakeModalLabel">
                          {isEditMode
                            ? 'Edit Delayed Car'
                            : 'Add New Delayed Car'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Number Plate
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={numberPlate}
                            onChange={(e) => setNumberPlate(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Car Make
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={carMake}
                            onChange={(e) => setCarMake(e.target.value)}
                            required
                          />
                        </div>{' '}
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Car Model
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={carModel}
                            onChange={(e) => setCarModel(e.target.value)}
                            required
                          />
                        </div>{' '}
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Booking Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={bookingNumber}
                            onChange={(e) => setBookingNumber(e.target.value)}
                            required
                          />
                        </div>{' '}
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Driver Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={driverName}
                            onChange={(e) => setDriverName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode
                            ? 'Update Delayed Car'
                            : 'Create Create Car'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg- col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Delayed Car List */}
        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="delayedCars-table-row-d1">
                  <input
                    type="checkbox"
                    checked={selectedDelayedCars.length === delayedCars.length}
                    onChange={() =>
                      setSelectedDelayedCars(
                        selectedDelayedCars.length === delayedCars.length
                          ? []
                          : delayedCars.map(
                              (delayedCar) => delayedCar.delayedCarsID
                            )
                      )
                    }
                  />
                </th>
                <th scope="col" className="font delayedCars-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="delayedCars-table-row-d3">
                  Number Plate
                </th>{' '}
                <th scope="col" className="delayedCars-table-row-d3">
                  Car Make
                </th>{' '}
                <th scope="col" className="delayedCars-table-row-d3">
                  Car Model
                </th>{' '}
                <th scope="col" className="delayedCars-table-row-d3">
                  Booking Number
                </th>
                <th scope="col" className="delayedCars-table-row-d4">
                  Driver Name
                </th>
                <th scope="col" className="delayedCars-table-row-d4">
                  Garage Total Bookings
                </th>
                <th scope="col">Created on</th>
              </tr>
            </thead>
            <tbody>
              {currentDelayedCars.map((delayedCar, index) => (
                <tr
                  className="delayedCars-table-row"
                  title={`${
                    delayedCar.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={delayedCar.delayedCarsID}
                  style={
                    delayedCar.isActive ? { backgroundColor: '#D3D3D3' } : {}
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedDelayedCars.includes(
                        delayedCar.delayedCarsID
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(delayedCar.delayedCarsID, index, e)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailDelayedCar(delayedCar.delayedCarsID)
                    }
                  >
                    {delayedCar.carMake?.length > 50
                      ? delayedCar.carMake.substring(0, 50) + '...'
                      : delayedCar.carMake}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailDelayedCar(delayedCar.delayedCarsID)
                    }
                  >
                    {delayedCar.numberPlate?.length > 100
                      ? delayedCar.numberPlate.substring(0, 100) + '...'
                      : delayedCar.numberPlate}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailDelayedCar(delayedCar.delayedCarsID)
                    }
                  >
                    {delayedCar.delayedCarCreatedDate}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailDelayedCar(delayedCar.delayedCarsID)
                    }
                  >
                    {delayedCar.delayedCarCreatedDate}
                  </td>{' '}
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailDelayedCar(delayedCar.delayedCarsID)
                    }
                  >
                    {delayedCar.delayedCarCreatedDate}
                  </td>{' '}
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailDelayedCar(delayedCar.delayedCarsID)
                    }
                  >
                    {delayedCar.delayedCarCreatedDate}
                  </td>{' '}
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={() =>
                      handleDetailDelayedCar(delayedCar.delayedCarsID)
                    }
                  >
                    {delayedCar.delayedCarCreatedDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <nav aria-label="Page navigation example">
          <div className="container text-center align-content-center d-flex">
            <div>Showing DELAYEDCARs: {calculateDisplayRange()}</div>
          </div>
          <select
            name="delayedCarPerPage"
            onChange={(e) => setItemPerPage(e.target.value)}
            className="form-control col-lg-4"
          >
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="45">45</option>
            <option value="60">60</option>
            <option value="75">75</option>
            <option value="90">90</option>
          </select>

          <ul className="pagination justify-content-end me-1">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {[...Array(totalPages)].map((_, index) => (
              <li
                className={`page-item ${
                  currentPage === index + 1 ? 'active' : ''
                }`}
                key={index}
              >
                <button
                  className="page-link"
                  onClick={() => handleSerialNumberChange(index)}
                >
                  {index + 1}
                </button>
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default DelayedCars

export const getAllDelayedCars = async () => {
  const response = await fetch(`${API_BASE_URL}/api/FAQs/GetAllDashFAQs`)
  return response.json()
}
