import React, { useState } from 'react'
import { API_BASE_URL } from '../../../Config/Config.js'
import { useLoaderData } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'

const ContactUs = () => {
  const data = useLoaderData()

  const [contacts, setContacts] = useState(data)
  const [selectedContacts, setSelectedContacts] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )

  const totalContacts = contacts.length

  const [contactUsId, setContactUsId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')
  const [date, setDate] = useState(null)
  const [isDeleted, setIsDeleted] = useState(false)

  // const [faqs, setFaqs] = useState(data)
  // const [selectedFAQs, setSelectedFAQs] = useState([])
  // const [answer, setAnswer] = useState('')
  // const [question, setQuestion] = useState('')
  // const [category, setCategory] = useState(1)
  // const [isModalOpen, setIsModalOpen] = useState(false)
  // const [isEditMode, setIsEditMode] = useState(false)
  // const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  // const [searchTerm, setSearchTerm] = useState('')
  // const [fromDate, setFromDate] = useState('')
  // const [toDate, setToDate] = useState('')
  // const [currentPage, setCurrentPage] = useState(1)
  // const [serialNumber, setSerialNumber] = useState((currentPage - 1) * 15)
  // const itemsPerPage = 15
  // const totalFAQs = faqs.length

  // Handle FAQ creation
  // const handleCreateFAQ = async (e) => {
  //   e.preventDefault()
  //   const newFAQ = { question, answer, category }

  // Handle Contact creation
  const handleCreateContact = async (e) => {
    e.preventDefault()
    const newContact = {
      firstName,
      lastName,
      email,
      phone,
      message,
      subject,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/ContactUs/CerateContactUs`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newContact),
        }
      )

      if (response.ok) {
        const createdContact = await response.json()
        setContacts((prevContacts) => [...prevContacts, createdContact])
        toast.success(`Contact '${createdContact.lastName}' created`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Contact.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
    console.log(firstName, lastName, email, phone, message, subject, date)
  }

  // Show modal for adding new Contact
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setLastName('')
    setFirstName('')
    setEmail('')
    setPhone('')
    setMessage('')
    setSubject('')
    setIsEditMode(false)
    setSelectedContacts([])
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setMessage('')
      setSubject('')
      setIsEditMode(false)
      setIsModalOpen(false)
    }
  }

  // // Handle Contact update contact
  // const handleEditContact = async (e) => {
  //   e.preventDefault()
  //   const selectedContact = contacts.find(
  //     (contact) => contact.contactUsId === selectedContacts[0]
  //   )
  //   const updatedContact = {
  //     ...selectedContact,
  //     firstName,
  //     lastName,
  //     email,
  //     phone,
  //     message,
  //     subject,
  //     date,
  //   }

  //   try {
  //     const response = await fetch(
  //       // `${API_BASE_URL}/api/FAQs/UpdateFAQs/${selectedContact.faQsID}`,
  //       `${API_BASE_URL}/api/ContactUs/UpdateContactUs/${selectedContact.contactUsId}`,

  //       {
  //         method: 'PUT',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(updatedContact),
  //       }
  //     )

  //     if (response.ok) {
  //       const updatedContactData = await response.json()
  //       setContacts((prevContacts) =>
  //         prevContacts.map((contact) =>
  //           contact.contactUsId === updatedContactData.contactUsId
  //             ? updatedContactData
  //             : contact
  //         )
  //       )
  //       toast.success(`Contact '${selectedContact.lastName}' updated`, {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         transition: Slide,
  //       })
  //       resetModal()
  //     } else {
  //       toast.warn(`Failed to update Contact.`, {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         transition: Slide,
  //       })
  //     }
  //   } catch (error) {
  //     toast.error(`Error updating Contact: ${error}`, {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'light',
  //       transition: Slide,
  //     })
  //   }
  // }

  // // Show modal for editing Contact
  // const handleEditButtonClick = () => {
  //   const selectedContact = contacts.find(
  //     (contact) => contact.contactUsId === selectedContacts[0]
  //   )
  //   setLastName(selectedContact.lastName)
  //   setFirstName(selectedContact.firstName)
  //   setEmail(selectedContact.email)
  //   setPhone(selectedContact.phone)
  //   setMessage(selectedContact.message)
  //   setSubject(selectedContact.subject)
  //   setIsEditMode(true)
  //   setIsModalOpen(true)
  // }

  // Handle Contact update
  const handleEditContact = async (e) => {
    e.preventDefault()

    // Find the selected contact
    const selectedContact = contacts.find(
      (contact) => contact.contactUsId === selectedContacts[0]
    )

    if (!selectedContact) {
      toast.error(`No contact selected for update`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
      return
    }

    // Prepare the updated contact object
    const updatedContact = {
      ...selectedContact,
      firstName,
      lastName,
      email,
      phone,
      message,
      subject,
      date, // Ensure date is correctly formatted or optional
    }

    try {
      // Send the PUT request to update the contact
      const response = await fetch(
        `${API_BASE_URL}/api/ContactUs/UpdateContactUs/${selectedContact.contactUsId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedContact),
        }
      )

      if (response.ok) {
        const updatedContactData = await response.json()

        // Update the state with the newly updated contact
        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact.contactUsId === updatedContactData.contactUsId
              ? updatedContactData
              : contact
          )
        )

        // Show success message
        toast.success(`Contact '${updatedContactData.lastName}' updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })

        // Reset modal and form values
        resetModal()
      } else {
        const errorMessage = await response.text()
        toast.warn(`Failed to update Contact: ${errorMessage}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Contact: ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing contact
  const handleEditButtonClick = () => {
    const selectedContact = contacts.find(
      (contact) => contact.contactUsId === selectedContacts[0]
    )

    if (!selectedContact) return

    // Set form fields with the selected contact's details
    setFirstName(selectedContact.firstName)
    setLastName(selectedContact.lastName)
    setEmail(selectedContact.email)
    setPhone(selectedContact.phone)
    setMessage(selectedContact.message)
    setSubject(selectedContact.subject)
    setDate(selectedContact.date)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Contact
  // const handleDetailContact = () => {
  //   const selectedContact = contacts.find(
  //     (contact) => contact.contactUsId === selectedContacts[0]
  //   )
  // setFirstName(selectedContact.firstName)
  // setLastName(selectedContact.lastName)
  // setEmail(selectedContact.email)
  // setPhone(selectedContact.phone)
  // setMessage(selectedContact.message)
  // setSubject(selectedContact.subject)
  // }

  // handle for Details Contact
  const handleDetailContact = () => {
    const selectedContact = contacts.find(
      (Contact) => Contact.contactUsId === selectedContacts[0]
    )
    setFirstName(selectedContact.firstName)
    setLastName(selectedContact.lastName)
    setEmail(selectedContact.email)
    setPhone(selectedContact.phone)
    setMessage(selectedContact.message)
    setSubject(selectedContact.subject)
  }

  const handleDetailsContact = (singleContact) => {
    const selectedContact = contacts.find(
      (contact) => contact.contactUsId === singleContact
    )
    setFirstName(selectedContact.firstName)
    setLastName(selectedContact.lastName)
    setEmail(selectedContact.email)
    setPhone(selectedContact.phone)
    setMessage(selectedContact.message)
    setSubject(selectedContact.subject)
  }

  // const handleDetailContact = (singleContact) => {
  //   const selectedContact = contacts.find((contact) => contact.contactUsId === singleContact)
  //   setFirstName(selectedContact.firstName)
  //   setLastName(selectedContact.lastName)
  // }

  // Handle Contact deletion
  console.log(selectedContacts)
  const handleDeleteContact = async () => {
    // Assuming selectedContacts is an array of contactUsId
    const selectedContact = { contactUsIds: selectedContacts }

    try {
      const response = await fetch(`${API_BASE_URL}/api/ContactUs/BulkDelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedContact), // Send the corrected payload
      })

      if (response.ok) {
        // Filter out the deleted contacts from the list
        setContacts((prevContacts) =>
          prevContacts.filter(
            (contact) => !selectedContacts.includes(contact.contactUsId)
          )
        )
        // Show success message using toast
        toast.success(`Selected Contacts deleted`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
        // Clear selected contacts
        setSelectedContacts([])
      } else {
        // If response is not OK, log the error message for debugging
        const errorMessage = await response.text()
        toast.error(`Failed to delete contacts: ${errorMessage}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      // Handle any other errors (e.g., network issues)
      toast.error(`Error deleting Contact: ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }

    // Close modal and reset form values
    setIsModalOpen(false)
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setMessage('')
    setSubject('')
  }

  // Show modal for editing Contact
  const handleDeleteButtonClick = () => {
    const selectedContact = contacts.find(
      (contact) => contact.contactUsId === selectedContacts[0]
    )
    setFirstName(selectedContact.firstName)
  }

  // Handle Contact activation/deactivation
  const handleToggleContactStatus = async (isActive) => {
    try {
      // Filter selected Contacts to include only those with both a lastName and an firstName
      const validContacts = selectedContacts.filter((id) => {
        const contact = contacts.find((contact) => contact.contactUsId === id)
        return contact.lastName && contact.firstName
      })
      const selectedContact = {
        contactUsId: selectedContacts,
        isActive: isActive,
      }
      // await Promise.all(
      const response = await fetch(`${API_BASE_URL}/api/FAQs/BulkActive`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedContact),
      })

      if (response.ok) {
        // Update state
        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            validContacts.includes(contact.contactUsId)
              ? { ...contact, isActive }
              : contact
          )
        )
        toast.success(`Selected Contacts updated`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        })

        setSelectedContacts([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating Contact: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredContacts = contacts.filter((contact) => {
    const contactDate = new Date(contact.contactCreatedDate) // Convert string date to Date object

    // Check if Contact lastName includes the search term (case insensitive)
    const matchesSearchTerm =
      contact.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.message?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.subject?.toLowerCase().includes(searchTerm.toLowerCase())

    // Check date filtering conditions
    const matchesDateRange = (() => {
      if (fromDate && toDate) {
        // If both fromDate and toDate are set, filter by the range
        return (
          contactDate >= new Date(fromDate) && contactDate <= new Date(toDate)
        )
      } else if (fromDate) {
        // If only fromDate is set, filter from that date onward
        return contactDate >= new Date(fromDate)
      } else if (toDate) {
        // If only toDate is set, filter up to that date
        return contactDate <= new Date(toDate)
      }
      // If neither fromDate nor toDate is set, match all dates
      return true
    })()

    // Return true only if both search term and date range conditions are met
    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const [lastSelectedIndex, setLastSelectedIndex] = useState(null) // Tracks last clicked index for Shift-click functionality
  const handleCheckboxChange = (contactId, index, event) => {
    setSelectedContacts((prevSelected) => {
      let newSelection

      if (event.shiftKey && lastSelectedIndex !== null) {
        // Shift-click functionality: select a range
        const start = Math.min(lastSelectedIndex, index)
        const end = Math.max(lastSelectedIndex, index)

        // Get all Contact IDs within the range
        const rangeSelection = contacts
          .slice(start, end + 1)
          .map((contact) => contact.contactUsId)

        // Merge the new range selection with the previous selection
        newSelection = [...new Set([...prevSelected, ...rangeSelection])]
      } else {
        // Normal click: toggle selection
        newSelection = prevSelected.includes(contactId)
          ? prevSelected.filter((id) => id !== contactId)
          : [...prevSelected, contactId]
      }

      // Check if the active button should be enabled
      const hasContactWithBoth = newSelection.some((id) => {
        const selectedContact = contacts.find(
          (contact) => contact.contactUsId === id
        )
        return selectedContact.lastName && selectedContact.firstName
      })

      setIsActiveButtonEnabled(hasContactWithBoth)

      // Update the last selected index for future Shift-click selections
      setLastSelectedIndex(index)

      return newSelection // This will update the state correctly
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  // Calculate total pages based on filtered Contacts
  const totalPages = Math.ceil(filteredContacts.length / itemsPerPage)
  // Calculate the index range of Contacts to display
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  // Contacts to be displayed on the current page
  const currentContacts = filteredContacts.reverse().slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    const endContact = Math.min(currentPage * itemsPerPage, totalContacts)
    return `${endContact}/${totalContacts}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  console.log(contacts)
  return (
    <>
      <div className="section-1">
        {/* <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="left">
              <button
                type="button"
                className="btn site-btn"
                data-bs-toggle="modal"
                data-bs-target="#ContactmodelMake"
              >
                Add Contact
              </button>

              <div
                className="modal fade"
                id="ContactmodelMake"
                tabindex="-1"
                aria-labelledby="ContactModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="ContactModalLabel">
                        Add New Contact Us
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3">
                          <label for="First-Name" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="First-Name"
                            placeholder="First Name"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="Last Name" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Last Name"
                            placeholder="Last Name"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="Email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="Email"
                            placeholder=" Email"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="Phone" className="form-label">
                            Phone
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="Phone"
                            placeholder="Phone"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="Date/Time" className="form-label">
                            Date/Time
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="Date"
                            placeholder="Date/Time"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="Message" className="form-label">
                            Message
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Message"
                            placeholder="Message"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn site-btn">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 mb-3">
            <div className="right d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input type="date" className="form-control" placeholder="date" />
              <label className="mx-1">To: </label>
              <input type="date" className="form-control" placeholder="date" />
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedContacts.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailContact}
                    title="Details"
                    disabled={selectedContacts.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>
                  {/* {////////////////////////////////////////////////////////////////////////////////////////////} */}

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">firstName:</h6> */}
                            <p className="ms-2 mb-0">{'FirstName: '}</p>
                            <p className="ms-2 mb-0">{firstName}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            {/* <h6 className="mb-0">lastName:</h6> */}
                            <p className="ms-2 mb-0">{'LastName: '}</p>
                            <p className="ms-2 mb-0">{lastName}</p>
                          </div>

                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">firstName:</h6> */}
                            <p className="ms-2 mb-0">{'Email: '}</p>
                            <p className="ms-2 mb-0">{email}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">firstName:</h6> */}
                            <p className="ms-2 mb-0">{'Phone: '}</p>
                            <p className="ms-2 mb-0">{phone}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">firstName:</h6> */}
                            <p className="ms-2 mb-0">{'Subject: '}</p>
                            <p className="ms-2 mb-0">{subject}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                          <div className="personal-dtails  mb-2 d-flex">
                            {/* <h6 className="mb-0">firstName:</h6> */}
                            <p className="ms-2 mb-0">{'Message: '}</p>
                            <p className="ms-2 mb-0">{message}</p>
                            <div className="ms-2 mb-0"></div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedContacts.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            // aria-label="Close"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedContacts.length === 1
                                ? ` '${firstName}' record?`
                                : `${selectedContacts.length} records?`}{' '}
                              {/* records? */}
                            </label>
                            <br />
                          </div>

                          {/* <div className="form-check">
                            <input
                              className="form-check-input fs-7"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label fs-7"
                              for="flexCheckDefault"
                            >
                              Never ask again
                            </label>
                          </div> */}
                        </div>

                        <div className="modal-footer">
                          {/* <button type="button" className="site-btn invisible">
                            Update changes
                          </button> */}
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteContact}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add lastName Modal /////////////////////////////////////////////////////////////////////////////////// */}
              <div
                id="QuestionmodelMake"
                tabIndex="-1"
                aria-labelledby="QuestionModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode ? handleEditContact : handleCreateContact
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="QuestionModalLabel">
                          {isEditMode
                            ? 'Edit Selected Contact'
                            : 'Add New Contact'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            placeholder="First Name"
                            className="form-control"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=" Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Email
                          </label>
                          <input
                            type="Email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Phone
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Subject
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Message
                          </label>
                          <textarea
                            type="text"
                            placeholder="Message"
                            className="form-control"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          // onClick={() => setIsModalOpen(false)}
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update Contact' : 'Create Contact'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Search Input */}
          <div className="col-lg-3 col-md-6">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-5 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    // data-bs-toggle="modal"
                    // data-bs-target="#quotesmodelMake"
                    onClick={handleResetFilter}
                    // disabled={selectedContacts.length > 0}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* add new model */}

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={selectedContacts.length === contacts.length}
                    onChange={() =>
                      setSelectedContacts(
                        selectedContacts.length === contacts.length
                          ? []
                          : contacts.map((contact) => contact.contactUsId)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font">
                  Sr No.
                </th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">Created On</th>
              </tr>
            </thead>
            <tbody>
              {currentContacts?.map((contact, index) => (
                <tr
                  className="faqs-table-row"
                  title="Click to view details"
                  key={contact.contactUsId}
                  style={contact.isActive ? { backgroundColor: '#D3D3D3' } : {}}
                >
                  <td>
                    {' '}
                    <input
                      type="checkbox"
                      checked={selectedContacts.includes(contact.contactUsId)}
                      onChange={(e) =>
                        handleCheckboxChange(contact.contactUsId, index, e)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailsContact(contact.contactUsId)}
                  >
                    {contact.firstName}
                  </td>
                  <td> {contact.lastName}</td>
                  <td> {contact.email}</td>
                  <td> {contact.phone}</td>
                  <td>{contact.subject}</td>
                  <td>{contact.message}</td>
                  <td>{contact.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end me-1">
            <li className="page-item disabled">
              <a className="page-link">Previous</a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav> */}
        {/* Pagination */}
        <nav aria-label="Page navigation example">
          <div className="container text-center align-content-center d-flex">
            <div>Showing Contacts: {calculateDisplayRange()}</div>
          </div>
          <select
            name="faqPerPage"
            onChange={(e) => setItemPerPage(e.target.value)}
            className="form-control col-lg-4"
          >
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="45">45</option>
            <option value="60">60</option>
            <option value="75">75</option>
            <option value="90">90</option>
          </select>
          <ul className="pagination justify-content-end me-1">
            {/* <li>Showing Contacts: {calculateDisplayRange()}</li> */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {[...Array(totalPages)].map((_, index) => (
              <li
                className={`page-item ${
                  currentPage === index + 1 ? 'active' : ''
                }`}
                key={index}
              >
                <button
                  className="page-link"
                  onClick={() => handleSerialNumberChange(index)}
                >
                  {index + 1}
                </button>
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default ContactUs
export const getAllContactUs = async () => {
  const response = await fetch(
    // `https://akhtarabbas909-001-site1.jtempurl.com/api/Discount/GetAllDiscounts`
    `${API_BASE_URL}/api/ContactUs/GetAll`
  )
  return response.json()
}
