import React, { useState, useEffect } from 'react'
import './Testimonials.css'
import dummyImage from './../../../Assets/img/Profile 2.png'
import { Link, useLoaderData } from 'react-router-dom'
import { toast, Slide, ToastContainer } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

const Testimonials = () => {
  const data = useLoaderData()
  const [testimonials, setTestimonials] = useState(data)
  console.log(data)
  const [selectedTestimonials, setSelectedTestimonials] = useState([])

  const [testimonialName, setTestimonialName] = useState('')
  const [testimonialType, setTestimonialType] = useState('')
  const [testimonialAddress, setTestimonialAddress] = useState('')
  const [testimonialCountry, setTestimonialCountry] = useState('')
  const [testimonialCity, setTestimonialCity] = useState('')
  const [testimonialZipCode, setTestimonialZipCode] = useState('')
  const [testimonialStatus, setTestimonialStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalTestimonials = testimonials.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      console.log(parsedCookie)
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  // Handle Testimonial creation
  const handleCreateTestimonial = async (e) => {
    e.preventDefault()
    const newTestimonial = {
      testimonialName,
      testimonialType,
      testimonialAddress,
      testimonialCountry,
      testimonialCity,
      testimonialZipCode,
      isActive: true,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/testimonial/CreateTestimonial`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(newTestimonial),
        }
      )

      if (response.ok) {
        const createdTestimonial = await response.json()
        setTestimonials((prevTestimonials) => [
          ...prevTestimonials,
          createdTestimonial,
        ])
        toast.success(
          `Testimonial '${createdTestimonial.testimonialName}' created`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to create Testimonial.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new Testimonial
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedTestimonials([])

    setTestimonialName('')
    setTestimonialType('')
    setTestimonialAddress('')
    setTestimonialCountry('')
    setTestimonialCity('')
    setTestimonialZipCode('')
    setTestimonialStatus(null)
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle Testimonial update
  const handleEditTestimonial = async (e) => {
    e.preventDefault()
    const selectedTestimonial = testimonials.find(
      (testimonial) => testimonial.testimonialID === selectedTestimonials[0]
    )

    console.log(selectedTestimonial)
    const updatedTestimonial = {
      ...selectedTestimonial,
      testimonialName,
      testimonialType,
      testimonialCountry,
      testimonialAddress,
      testimonialCity,
      testimonialZipCode,
      isActive: true,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Testimonial/Update/${selectedTestimonial.testimonialID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedTestimonial),
        }
      )

      if (response.ok) {
        const updatedTestimonialData = await response.json()
        setTestimonials((prevTestimonials) =>
          prevTestimonials.map((testimonial) =>
            testimonial.testimonialID === updatedTestimonialData.testimonialID
              ? updatedTestimonialData
              : testimonial
          )
        )
        console.log(updatedTestimonial)
        console.log(testimonials)
        toast.success(
          `Testimonial '${selectedTestimonial.testimonialName}' updated`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to update Testimonial.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Testimonial: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing Testimonial
  const handleEditButtonClick = () => {
    const selectedTestimonial = testimonials.find(
      (testimonial) => testimonial.testimonialID === selectedTestimonials[0]
    )
    console.log(selectedTestimonial)
    setTestimonialName(selectedTestimonial.testimonialName)
    setTestimonialType(selectedTestimonial.testimonialType)
    setTestimonialAddress(selectedTestimonial.testimonialAddress)
    setTestimonialCountry(selectedTestimonial.testimonialCountry)
    setTestimonialCity(selectedTestimonial.testimonialCity)
    setTestimonialZipCode(selectedTestimonial.testimonialZipCode)
    setTestimonialStatus(selectedTestimonial.isActive)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Testimonial
  const handleDetailsTestimonial = () => {
    const selectedTestimonial = testimonials.find(
      (testimonial) => testimonial.testimonialID === selectedTestimonials[0]
    )
    setTestimonialName(selectedTestimonial.testimonialName)
    setTestimonialType(selectedTestimonial.testimonialType)
    setTestimonialAddress(selectedTestimonial.testimonialAddress)
    setTestimonialCountry(selectedTestimonial.testimonialCountry)
    setTestimonialCity(selectedTestimonial.testimonialCity)
    setTestimonialZipCode(selectedTestimonial.testimonialZipCode)
    setTestimonialStatus(selectedTestimonial.isActive)
  }

  const handleDetailTestimonial = (singleTestimonial) => {
    const selectedTestimonial = testimonials.find(
      (testimonial) => testimonial.testimonialID === singleTestimonial
    )
    setTestimonialName(selectedTestimonial.testimonialName)
    setTestimonialType(selectedTestimonial.testimonialType)
    setTestimonialAddress(selectedTestimonial.testimonialAddress)
    setTestimonialCountry(selectedTestimonial.testimonialCountry)
    setTestimonialCity(selectedTestimonial.testimonialCity)
    setTestimonialZipCode(selectedTestimonial.testimonialZipCode)
    setTestimonialStatus(selectedTestimonial.isActive)
  }

  // Handle Testimonial deletion
  const handleDeleteTestimonial = async () => {
    const selectedTestimonial = { testimonialID: selectedTestimonials }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Testimonial/BulkDelete`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(selectedTestimonial),
        }
      )

      if (response.ok) {
        setTestimonials((prevTestimonials) =>
          prevTestimonials.filter(
            (testimonial) =>
              !selectedTestimonials.includes(testimonial.testimonialID)
          )
        )
        toast.success(`Selected Testimonials deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedTestimonials([])
      }
    } catch (error) {
      toast.error(`Error deleting Testimonials: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setTestimonialName('')
    setTestimonialType('')
    setTestimonialAddress('')
    setTestimonialCountry('')
    setTestimonialCity('')
    setTestimonialZipCode('')
  }

  // Show modal for deleting Testimonial
  const handleDeleteButtonClick = () => {
    const selectedTestimonial = testimonials.find(
      (testimonial) => testimonial.testimonialID === selectedTestimonials[0]
    )
    setTestimonialName(selectedTestimonial.testimonialName)
  }

  // Handle Testimonial activation/deactivation
  const handleToggleTestimonialStatus = async (isActive) => {
    try {
      const validTestimonials = selectedTestimonials.filter((id) => {
        const testimonial = testimonials.find(
          (testimonial) => testimonial.testimonialID === id
        )
        return testimonial.testimonialName && testimonial.testimonialAddress
      })
      const selectedTestimonial = {
        testimonialID: selectedTestimonials,
        isActive: isActive,
      }
      const response = await fetch(
        `${API_BASE_URL}/api/Testimonial/BulkActive`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(selectedTestimonial),
        }
      )

      if (response.ok) {
        setTestimonials((prevTestimonials) =>
          prevTestimonials.map((testimonial) =>
            validTestimonials.includes(testimonial.testimonialID)
              ? { ...testimonial, isActive }
              : testimonial
          )
        )
        toast.success(`Selected Testimonials updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })

        setSelectedTestimonials([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating Testimonial: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredTestimonials = testimonials.filter((testimonial) => {
    const testimonialDate = new Date(testimonial.createdDate)

    const matchesSearchTerm =
      testimonial.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      testimonial.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      testimonial.phoneNumber
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      testimonial.descriptionOfExperience
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      testimonial.dateOfExperience
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      testimonial.positiveAspects
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      testimonial.areasForImprovement
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      testimonial.suggestions?.toLowerCase().includes(searchTerm.toLowerCase())

    const matchesDateRange = (() => {
      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate)
        const toDateObj = new Date(fromDate)
        return (
          testimonialDate.toDateString() === fromDateObj.toDateString() &&
          testimonialDate.toDateString() === toDateObj.toDateString()
        )
      } else if (fromDate) {
        const fromDateObj = new Date(fromDate)
        return testimonialDate.toDateString() === fromDateObj.toDateString()
      }
      return true
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (testimonialId) => {
    setSelectedTestimonials((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(testimonialId)
        ? prevSelected.filter((id) => id !== testimonialId)
        : [...prevSelected, testimonialId]

      const hasTestimonialWithBoth = newSelection.some((id) => {
        const selectedTestimonial = testimonials.find(
          (testimonial) => testimonial.testimonialID === id
        )
        return (
          selectedTestimonial.testimonialName &&
          selectedTestimonial.testimonialCountry
        )
      })

      setIsActiveButtonEnabled(hasTestimonialWithBoth)
      return newSelection
    })
  }

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(filteredTestimonials.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentTestimonials = filteredTestimonials
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentTestimonials.length}/${totalTestimonials}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  return (
    <>
      <div className="section-1">
        <div className="row justify-content-between">
          <div className="col-lg-4 col-md-4">
            <div className="left">
              <button
                type="button"
                className="site-btn"
                data-bs-toggle="modal"
                data-bs-target="#AddModal"
              >
                <span>Add New Testimonial</span>
              </button>

              <div
                className="modal fade"
                id="AddModal"
                tabIndex="-1"
                aria-labelledby="AddModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="AddModalLabel">
                        Add New Testimonial
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body">
                      {/* <form onSubmit={handleSubmit}> */}
                      <div className="d-flex align-items-center">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                              // onChange={handleImageUpload}
                            />
                            <label htmlFor="imageUpload"></label>
                          </div>
                          <div className="avatar-preview">
                            <div
                              id="imagePreview"
                              style={{
                                backgroundImage:
                                  'url(https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png)',
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="firstName" className="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="First Name"
                          // value={name}
                          // onChange={(e) => setName(e.target.value)}
                          required
                        />
                        {/* <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          /> */}
                      </div>
                      <div className="mt-4">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          // value={email}
                          // onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mt-4">
                        <label htmlFor="phone" className="form-label">
                          Phone
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          placeholder="Phone"
                          // value={phoneNumber}
                          // onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                        {/* <input
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                          /> */}
                      </div>
                      <br />
                      <div>
                        <label>Overall Satisfaction (1-5):</label>
                        <input
                          type="number"
                          min="1"
                          max="5"
                          required
                          // value={overallSatisfaction}
                          // onChange={(e) =>
                          //   setOverallSatisfaction(parseInt(e.target.value))
                          // }
                        />
                        {/* <input
                            type="number"
                            min="1"
                            max="5"
                            value={overallSatisfaction}
                            onChange={(e) =>
                              setOverallSatisfaction(parseInt(e.target.value))
                            }
                            required
                          /> */}
                      </div>
                      <div className="mt-4">
                        <label htmlFor="comments" className="form-label">
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="comments"
                          placeholder="Comments"
                          // value={descriptionOfExperience}
                          // onChange={(e) =>
                          //   setDescriptionOfExperience(e.target.value)
                          // }
                          required
                        ></textarea>
                        {/* <textarea
                            value={descriptionOfExperience}
                            onChange={(e) =>
                              setDescriptionOfExperience(e.target.value)
                            }
                            required
                          /> */}
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="site-btn">
                          Submit
                        </button>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-8 mb-3">
            <div className="search-input Admin-search">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>

        <div className="card-display">
          {currentTestimonials?.map((feedback) => (
            <div key={feedback.feedbackID} className="testimonials-main">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="testimonials-left">
                    <img
                      src={dummyImage}
                      alt={feedback.name || 'Dummy Image'}
                      className="rounded mt-4"
                    />
                    <h5 className="text-center mt-3">{feedback.name}</h5>
                  </div>
                </div>

                <div className="col-lg-8 col-md-8 col-12">
                  <div className="testimonials-right">
                    <div className="testi-buttons-all">
                      <div className="icon-btns d-flex justify-content-between testi-button">
                        <div className="toggle-button-testi">
                          <label className="switch-testimonials">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <button
                          type="button"
                          className="details-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#EditModal"
                        >
                          <span
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Edit"
                          >
                            <i className="fa-solid fa-pencil" title="Edit"></i>
                          </span>
                        </button>

                        <button
                          // onClick={() => deleteFeedback(feedback.feedbackID)}
                          className="details-btn"
                        >
                          <span
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete"
                          >
                            <i className="fa-solid fa-trash" title="Delete"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                    <p>{feedback.descriptionOfExperience}</p>
                    <div className="rating-email">
                      <p className="icon-mail-phone">
                        <i className="email-icon fa-solid fa-envelope email_icon_phone1"></i>{' '}
                        {feedback.email}
                      </p>
                      <p className="icon-mail-phone">
                        <i className="fa-solid fa-phone email_icon_phone1"></i>{' '}
                        {feedback.phoneNumber}
                      </p>
                      <div className="testimonial-profile">
                        <div className="testi-date-box">
                          <p>Aug 31,2024</p>
                        </div>
                        <div className="testi-star-box">
                          {'⭐️'.repeat(feedback.overallSatisfaction)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing Testimonials: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Testimonials

export async function getAllTestimonials() {
  const response = await fetch(
    `${API_BASE_URL}/api/CustomerFeedback/GetAllDashCustomerFeedback`
  )

  return response.json()
}
