import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import { verifyToken } from '../../../index.js'
import useFetch from '../../../CustomHooks/useFetch'
function VehicleColors() {
  const [token, setToken] = useState('')
  const apiURL = `${API_BASE_URL}/api/Vehicle/GetAllDashcolors`
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const data = useLoaderData()
  const [vehicleColors, setVehicleColors] = useState(data)
  const [selectedVehicleColors, setSelectedVehicleColors] = useState([])

  const [vehicleColorName, setVehicleColorName] = useState('')
  const [vehicleColorDescription, setVehicleColorDescription] = useState('')
  const [vehicleColorType, setVehicleColorType] = useState('')
  const [vehicleColorStatus, setVehicleColorStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalVehicleColors = vehicleColors.length

  const { triggerData } = useFetch(apiURL, token, selectedVehicleColors)
  console.log(triggerData)

  useEffect(() => {
    if (triggerData) {
      setVehicleColors(triggerData)
    }
  }, [vehicleColors, triggerData, selectedVehicleColors])

  function handleStatusChange(e) {
    setVehicleColorStatus(e.target.value === 'true')
  }

  const handleCreateVehicleColor = async (e) => {
    e.preventDefault()
    const newVehicleColor = {
      vehicleColorName: vehicleColorName,
      vehicleColorDescription: vehicleColorDescription,
      vehicleColorType: vehicleColorType,
    }

    console.log(newVehicleColor)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/VehicleController/CreateColorDetails`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(newVehicleColor),
        }
      )

      const createdVehicleColor = await response.json()
      console.log(createdVehicleColor)
      if (response.ok) {
        toast.success(
          `VehicleColor '${createdVehicleColor.vehicleColorName}' created`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to create VehicleColor.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedVehicleColors([])
    setVehicleColorName('')
    setVehicleColorDescription('')
    setVehicleColorType('')
    setVehicleColorStatus(true)
  }

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  const handleEditVehicleColor = async (e) => {
    e.preventDefault()
    const selectedVehicleColor = vehicleColors.find(
      (vehicleColor) => vehicleColor.vehicleColorID === selectedVehicleColors[0]
    )

    const updatedVehicleColor = {
      ...selectedVehicleColor,
      vehicleColorName,
      vehicleColorDescription,
      vehicleColorType,
      isActive: vehicleColorStatus,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/VehicleController/UpdateColorDetails/${selectedVehicleColor.vehicleColorID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(updatedVehicleColor),
        }
      )

      if (response.ok) {
        console.log(updatedVehicleColor)
        console.log(vehicleColors)
        toast.success(
          `VehicleColor '${selectedVehicleColor.vehicleColorName}' updated`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to update VehicleColor.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating VehicleColor: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleEditButtonClick = () => {
    const selectedVehicleColor = vehicleColors.find(
      (vehicleColor) => vehicleColor.vehicleColorID === selectedVehicleColors[0]
    )
    setVehicleColorName(selectedVehicleColor.vehicleColorName)
    setVehicleColorDescription(selectedVehicleColor.vehicleColorDescription)
    setVehicleColorType(selectedVehicleColors.vehicleColorType)
    setVehicleColorStatus(selectedVehicleColor.isActive)

    setIsEditMode(true)
    setIsModalOpen(true)
  }

  const handleDetailsVehicleColor = () => {
    const selectedVehicleColor = vehicleColors.find(
      (vehicleColor) => vehicleColor.vehicleColorID === selectedVehicleColors[0]
    )
    setVehicleColorName(selectedVehicleColor.vehicleColorName)
    setVehicleColorDescription(selectedVehicleColor.vehicleColorDescription)
    setVehicleColorType(selectedVehicleColors.vehicleColorType)
    setVehicleColorStatus(selectedVehicleColor.isActive)
  }

  const handleDetailVehicleColor = (singleVehicleColor) => {
    const selectedVehicleColor = vehicleColors.find(
      (vehicleColor) => vehicleColor.vehicleColorID === singleVehicleColor
    )
    setVehicleColorName(selectedVehicleColor.vehicleColorName)
    setVehicleColorDescription(selectedVehicleColor.vehicleColorDescription)
    setVehicleColorType(selectedVehicleColors.vehicleColorType)
    setVehicleColorStatus(selectedVehicleColor.isActive)
  }

  const handleDeleteVehicleColor = async () => {
    const selectedVehicleColor = { vehicleColorID: selectedVehicleColors }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Vehicle/BulkDeleteVehicleColor`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(selectedVehicleColor),
        }
      )

      if (response.ok) {
        toast.success(`Selected VehicleColors deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedVehicleColors([])
      }
    } catch (error) {
      toast.error(`Error deleting VehicleColors: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setVehicleColorName('')
    setVehicleColorDescription('')
    setVehicleColorType('')
  }

  const handleDeleteButtonClick = () => {
    const selectedVehicleColor = vehicleColors.find(
      (vehicleColor) => vehicleColor.vehicleColorID === selectedVehicleColors[0]
    )
    setVehicleColorName(selectedVehicleColor.vehicleColorName)
  }

  const handleToggleVehicleColorStatus = async (isActive) => {
    try {
      const selectedVehicleColor = {
        vehicleColorID: selectedVehicleColors,
        isActive: isActive,
      }
      const response = await fetch(
        `${API_BASE_URL}/api/Vehicle/BulkActiveVehicleColor`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(selectedVehicleColor),
        }
      )

      if (response.ok) {
        toast.success(`Selected VehicleColors updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })

        setSelectedVehicleColors([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating VehicleColor: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredVehicleColors = vehicleColors.filter((vehicleColor) => {
    const matchesSearchTerm =
      vehicleColor.vehicleColorName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      vehicleColor.vehicleColorType
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      vehicleColor.vehicleColorDescription
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())

    const vehicleColorDate = new Date(vehicleColor.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedVehicleColorDate = formatDate(vehicleColorDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedVehicleColorDate >= formattedFromDate &&
          formattedVehicleColorDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedVehicleColorDate === formattedFromDate
      }

      return true
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (vehicleColorId) => {
    setSelectedVehicleColors((prevSelected) => {
      let newSelection
      newSelection = prevSelected.includes(vehicleColorId)
        ? prevSelected.filter((id) => id !== vehicleColorId)
        : [...prevSelected, vehicleColorId]
      return newSelection
    })
  }

  const totalPages = Math.ceil(
    filteredVehicleColors.length / Number(itemsPerPage)
  )
  const startIndex = (currentPage - 1) * Number(itemsPerPage)

  const endIndex = startIndex + Number(itemsPerPage)

  const currentVehicleColors = filteredVehicleColors
    .reverse()
    .slice(startIndex, endIndex)

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * Number(itemsPerPage))
  }

  const calculateDisplayRange = () => {
    return `${currentVehicleColors.length}/${totalVehicleColors}`
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>{' '}
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedVehicleColors.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsVehicleColor}
                    title="Details"
                    disabled={selectedVehicleColors.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Vehicle Color Name: '}
                            </p>
                            <p className="ms-2 mb-0">{vehicleColorName}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Vehicle Color Description: '}
                            </p>
                            <p className="ms-2 mb-0">
                              {vehicleColorDescription}
                            </p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Vehicle Color Type: '}
                            </p>
                            <p className="ms-2 mb-0">{vehicleColorType}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Vehicle Color Status: '}
                            </p>
                            <p className="ms-2 mb-0">
                              {vehicleColorStatus ? 'True' : 'False'}
                            </p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedVehicleColors.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedVehicleColors.length === 1
                                ? ` '${vehicleColorName}' record?`
                                : `${selectedVehicleColors.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteVehicleColor}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as active"
                    onClick={() => handleToggleVehicleColorStatus(true)}
                    disabled={
                      (selectedVehicleColors.length === 0 &&
                        !isActiveButtonEnabled) ||
                      vehicleColors
                        .filter((vehicleColor) =>
                          selectedVehicleColors.includes(
                            vehicleColor.vehicleColorID
                          )
                        )
                        .every((vehicleColor) => vehicleColor.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-on"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as inactive"
                    onClick={() => handleToggleVehicleColorStatus(false)}
                    disabled={
                      (selectedVehicleColors.length === 0 &&
                        !isActiveButtonEnabled) ||
                      vehicleColors
                        .filter((vehicleColor) =>
                          selectedVehicleColors.includes(
                            vehicleColor.vehicleColorID
                          )
                        )
                        .every((vehicleColor) => !vehicleColor.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-off"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div
                id="VehicleColormodelMake"
                tabIndex="-1"
                aria-labelledby="VehicleColorModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode
                          ? handleEditVehicleColor
                          : handleCreateVehicleColor
                      }
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="VehicleColorModalLabel">
                          {isEditMode
                            ? 'Edit VehicleColor'
                            : 'Add New VehicleColor'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Vehicle Colour Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={vehicleColorName}
                            onChange={(e) =>
                              setVehicleColorName(e.target.value)
                            }
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Vehicle Colour Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={vehicleColorDescription}
                            onChange={(e) =>
                              setVehicleColorDescription(e.target.value)
                            }
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Vehicle Colour Type
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={vehicleColorType}
                            onChange={(e) =>
                              setVehicleColorType(e.target.value)
                            }
                            required
                          />
                        </div>

                        <div className="mb-3 active-inactive-all">
                          <div>
                            <input
                              type="radio"
                              id="active"
                              name="vehicleColorStatus"
                              value="true"
                              onChange={handleStatusChange}
                              checked={vehicleColorStatus === true}
                            />
                            <label htmlFor="active">Active</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="inactive"
                              name="vehicleColorStatus"
                              value="false"
                              onChange={handleStatusChange}
                              checked={vehicleColorStatus === false}
                            />
                            <label htmlFor="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode
                            ? 'Update Vehicle Color'
                            : 'Create Vehicle Color'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      selectedVehicleColors.length === vehicleColors.length
                    }
                    onChange={() =>
                      setSelectedVehicleColors(
                        selectedVehicleColors.length === vehicleColors.length
                          ? []
                          : vehicleColors.map(
                              (vehicleColor) => vehicleColor.vehicleColorID
                            )
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Vehicle Colour Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Vehicle Colour Description
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Vehicle Colour Type
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentVehicleColors.map((vehicleColor, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${
                    vehicleColor.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={vehicleColor.vehicleColorID}
                  style={
                    vehicleColor.isActive ? { backgroundColor: '#D3D3D3' } : {}
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedVehicleColors.includes(
                        vehicleColor.vehicleColorID
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(
                          vehicleColor.vehicleColorID,
                          index,
                          e
                        )
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailVehicleColor(vehicleColor.vehicleColorID)
                    }
                  >
                    {vehicleColor.vehicleColorName}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailVehicleColor(vehicleColor.vehicleColorID)
                    }
                  >
                    {vehicleColor.vehicleColorDescription}
                  </td>
                  <td>{vehicleColor.vehicleColorType}</td>
                  <td>{vehicleColor.createdDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing VehicleColors: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default VehicleColors
export const getAllVehicleColors = async () => {
  const response = await fetch(`${API_BASE_URL}`)

  return response.json()
}
