import React from 'react'
import './Navbar.css'
import Askaii_logo from '../../Assets/img/Skaii Logo Final-01.png'
import { NavLink } from 'react-router-dom'

function Logo() {
  return (
    <>
      <NavLink to="/" className="navbar-brand d-block">
          <div className="logo">
            <img src={Askaii_logo} alt="logo" />
          </div>
        </NavLink>
    </>
  )
}

export default Logo