import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../Components/Navbar/Logo'
import Back from './Back'
import NavbarLink from '../Components/NabarLink/NavbarLink'

function AdminPortalNavbar() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 p-0">
          <>
            <div
              id="bdSidebar"
              className="for-width sidebar-bg-color d-flex flex-column text-white offcanvas-md offcanvas-start"
            >
              <Logo />
              <ul className="mynav nav nav-pills flex-column mb-auto p-3">
                {/* Go Back Link */}
                <NavbarLink
                  name="Go Back"
                  logo={<i className="fa-solid fa-arrow-left"></i>}
                  to="/"
                />
                {/* Booking Slots */}
                <NavbarLink
                  name="Booking Slots"
                  logo={<i className="fa-solid fa-calendar"></i>}
                  to="/admin-portal/booking-slots"
                />
                {/* Configuration */}
                {/* <NavbarLink
                  name="Configuration"
                  logo={<i className="fa-solid fa-cog"></i>}
                  to="/admin-portal/configuration"
                /> */}
                {/* FAQs */}
                {/* <NavbarLink
                  name="FAQs"
                  logo={<i className="fa-solid fa-question-circle"></i>}
                  to="/admin-portal/faqs"
                /> */}
                {/* Testimonials */}
                {/* <NavbarLink
                  name="Testimonials"
                  logo={<i className="fa-solid fa-sort-alpha-asc"></i>}
                  to="/admin-portal/testimonials"
                /> */}
                {/* Captains */}
                {/* <NavbarLink
                  name="Captains"
                  logo={<i className="fa-solid fa-user"></i>}
                  to="/admin-portal/captains"
                /> */}
                {/* Contact Us */}
                {/* <NavbarLink
                  name="Contact Us"
                  logo={<i className="fa-solid fa-address-book"></i>}
                  to="/admin-portal/contact-us"
                /> */}
              </ul>

              <hr />
              <div className="Privacy-terms d-flex align-items-center p-3">
                <i className="fa-solid fa-shield-halved me-2"></i>
                <p className="mb-0">
                  {/* <a href="#" className="text-white text-decoration-none">
              Terms of Service
            </a> */}
                  <NavLink
                    to="news-letter/terms-of-services"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    {' '}
                    - Terms Of Services
                  </NavLink>
                  {/* <a href="#" className="text-white text-decoration-none">
              - Privacy Policy
            </a> */}
                  {/* <li> */}
                  <NavLink
                    to="news-letter/privacy-policy"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    - Privacy Policy
                  </NavLink>
                  {/* </li> */}
                </p>
              </div>
            </div>

            <div className="right-bg-contant">
              <div className="p-2 d-md-none d-flex text-white sidebar-bg-color forsmallscreen">
                <a
                  href="#"
                  className="text-white"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#bdSidebar"
                >
                  <i className="fa-solid fa-bars"></i>
                </a>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default AdminPortalNavbar
