import React, { useEffect, useState } from 'react'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../Config/Config'
import Cookies from 'js-cookie'

function LandDriverBookings() {
  const [landDriverBookings, setLandDriverBookings] = useState([])
  const [selectedLandDriverBookings, setSelectedLandDriverBookings] = useState(
    []
  )
  const [detailsLandDriverBookings, setDetailsLandDriverBookings] = useState([])
  const [drivers, setDrivers] = useState([])
  const [driverName, setDriverName] = useState()

  const [bookingDetails, setBookingDetails] = useState()
  const [inboundterminals, setInboundTerminals] = useState([])
  const [outboundterminals, setOutboundTerminals] = useState([])

  const [isAssignDrivermodal, setIsAssignDrivermodal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [isDetailsModal, setIsDetailsModal] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )

  const totalLandDriverBookings = landDriverBookings.length
  useEffect(() => {
    const fetchLandDriverBookings = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/GetReceivingAssignedBookings`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token.authToken,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setLandDriverBookings(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchLandDriverBookings()
  }, [selectedLandDriverBookings, token.authToken])

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Driver/GetAllDrivers`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token.authToken,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setDrivers(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchDrivers()
  }, [selectedLandDriverBookings, token.authToken])

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  useEffect(() => {
    const fetchTerminals = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Terminal/GetAllbyAirPort/${detailsLandDriverBookings?.airportID}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        const inbounds = data.filter(
          (terminal) => terminal.terminalType === 'InBound'
        )
        const outbounds = data.filter(
          (terminal) => terminal.terminalType === 'OutBound'
        )
        setInboundTerminals(inbounds)
        setOutboundTerminals(outbounds)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
    fetchTerminals()
  }, [detailsLandDriverBookings?.airportID])

  // Handle LandDriverBooking creation
  const handleAssignLandDriverBooking = async (e) => {
    e.preventDefault()
    const selectedLandDriverBooking = landDriverBookings.find(
      (landDriverBooking) =>
        landDriverBooking.bookingID === selectedLandDriverBookings[0]
    )
    const newLandDriverBooking = {
      DriverID: Number(driverName),
      BookingID: selectedLandDriverBooking.bookingID,
      CreatedDate: null,
    }
    console.log(newLandDriverBooking)

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Booking/AssignReceivingDriver`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: token.authToken,
          },
          body: JSON.stringify(newLandDriverBooking),
        }
      )

      if (response.ok) {
        const createdLandDriverBooking = await response.json()
        toast.success(
          `LandDriverBooking '${createdLandDriverBooking.landDriverBookingName}' created`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to Assign.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new LandDriverBooking
  const handleAddButtonClick = () => {
    setIsAssignDrivermodal(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsAssignDrivermodal(false)
    setIsEditModal(false)
    setIsDetailsModal(false)
    setSelectedLandDriverBookings([])
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle LandDriverBooking update
  const handleEditLandDriverBooking = async (e) => {
    e.preventDefault()
    const selectedLandDriverBooking = landDriverBookings.find(
      (landDriverBooking) =>
        landDriverBooking.bookingID === selectedLandDriverBookings[0]
    )

    // console.log(currentLandDriverBookings, '===itsme===')
    const updatedLandDriverBooking = {
      ...selectedLandDriverBooking,
      endDateTime: detailsLandDriverBookings.endDateTime,
      inboundTerminalID: detailsLandDriverBookings.inboundTerminalID,
      outboundTerminalID: detailsLandDriverBookings.outboundTerminalID,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/LandDriverBooking/Update/${selectedLandDriverBooking.bookingID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedLandDriverBooking),
        }
      )

      if (response.ok) {
        toast.success(
          `Received UnAssigned Booking '${selectedLandDriverBooking.landDriverBookingName}' Updated`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to update LandDriverBooking.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating LandDriverBooking: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing LandDriverBooking
  const handleEditButtonClick = () => {
    const selectedLandDriverBooking = landDriverBookings.find(
      (landDriverBooking) =>
        landDriverBooking.bookingID === selectedLandDriverBookings[0]
    )

    setIsEditModal(selectedLandDriverBooking)
    setBookingDetails(selectedLandDriverBooking)
  }

  // handle for Details LandDriverBooking
  const handleDetailsLandDriverBooking = () => {
    const selectedLandDriverBooking = landDriverBookings.find(
      (landDriverBooking) =>
        landDriverBooking.bookingID === selectedLandDriverBookings[0]
    )
    setIsDetailsModal(true)
    setDetailsLandDriverBookings(selectedLandDriverBooking)
  }

  const handleDetailLandDriverBooking = (singleLandDriverBooking) => {
    const selectedLandDriverBooking = landDriverBookings.find(
      (landDriverBooking) =>
        landDriverBooking.bookingID === singleLandDriverBooking
    )
    setDetailsLandDriverBookings(selectedLandDriverBooking)
    setIsDetailsModal(true)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredLandDriverBookings = landDriverBookings.filter(
    (landDriverBooking) => {
      const matchesSearchTerm = landDriverBooking.quoteCode
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())

      const landDriverBookingDate = new Date(landDriverBooking.createdDate)

      const matchesDateRange = (() => {
        const fromDateObj = fromDate ? new Date(fromDate) : null
        const toDateObj = toDate ? new Date(toDate) : null

        // Helper function to format date to 'dd/mm/yyyy'
        const formatDate = (date) => {
          if (!date) return null
          const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
          const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
          const year = date.getFullYear()
          return `${day}/${month}/${year}`
        }

        const formattedLandDriverBookingDate = formatDate(landDriverBookingDate)
        const formattedFromDate = formatDate(fromDateObj)
        const formattedToDate = formatDate(toDateObj)

        if (fromDateObj && toDateObj) {
          return (
            formattedLandDriverBookingDate >= formattedFromDate &&
            formattedLandDriverBookingDate <= formattedToDate
          ) // Inclusive range
        } else if (fromDateObj) {
          return formattedLandDriverBookingDate === formattedFromDate // Exact match for fromDate
        }

        return true // If no fromDate or toDate, show all data
      })()

      return matchesSearchTerm && matchesDateRange
    }
  )

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (bookingID) => {
    setSelectedLandDriverBookings((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(bookingID)
        ? prevSelected.filter((id) => id !== bookingID)
        : [...prevSelected, bookingID]

      return newSelection
    })
  }
  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(filteredLandDriverBookings.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentLandDriverBookings = filteredLandDriverBookings
    .reverse()
    .slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentLandDriverBookings.length}/${totalLandDriverBookings}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  // console.log(currentLandDriverBookings)
  console.log(bookingDetails)
  // console.log(landDriverBookings)

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleAddButtonClick}
                    disabled={selectedLandDriverBookings.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="assignDriver"
                    >
                      <i
                        className="fa-regular fa-calendar-check"
                        title="Calender"
                      ></i>
                    </span>
                  </button>
                </div>
                <div
                  id="assignDriver"
                  tabIndex="-1"
                  aria-labelledby="assignDriver"
                  className={`modal fade ${
                    isAssignDrivermodal ? 'show d-block' : ''
                  }`}
                  onClick={handleBackdropClick}
                  aria-hidden={!isAssignDrivermodal}
                  style={
                    isAssignDrivermodal
                      ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                      : {}
                  }
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="modal-content">
                      <form
                        id="add-new-user-form"
                        onSubmit={handleAssignLandDriverBooking}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" id="assignDriver">
                            Assign Driver
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Assign Driver
                            </label>
                            <select
                              name="faqPerPage"
                              onChange={(e) => setDriverName(e.target.value)}
                              className=" form-control"
                            >
                              <option disabled>Select Driver</option>
                              {drivers.map((driver) => (
                                <>
                                  <option
                                    key={driver.driverID}
                                    value={driver.driverID}
                                  >
                                    {driver.driverName}
                                  </option>
                                </>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn site-btn">
                            Assign Booking
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedLandDriverBookings.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>

                <div
                  id="LandDriverBookingmodelMake"
                  tabIndex="-1"
                  aria-labelledby="LandDriverBookingModalLabel"
                  className={`modal fade ${isEditModal ? 'show d-block' : ''}`}
                  onClick={handleBackdropClick}
                  aria-hidden={!isEditModal}
                  style={
                    isEditModal
                      ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                      : {}
                  }
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="modal-content">
                      <form
                        id="add-new-user-form"
                        onSubmit={handleEditLandDriverBooking}
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="LandDriverBookingModalLabel"
                          >
                            Edit Booking
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Booking End Date
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              value={bookingDetails?.endDateTime}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              InBound Terminal
                            </label>
                            <select
                              className="form-control"
                              value={bookingDetails?.inboundTerminalID}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                            >
                              {inboundterminals.map((inbound) => (
                                <option>{inbound.terminalName}</option>
                              ))}
                            </select>
                          </div>{' '}
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              OutBound Terminal
                            </label>
                            <select
                              className="form-control"
                              value={bookingDetails?.outboundTerminalID}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                            >
                              {outboundterminals.map((outbound) => (
                                <option>{outbound.terminalName}</option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Product Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={bookingDetails?.productName}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="txtMake" className="form-label">
                              Product Description
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={bookingDetails?.productDescription}
                              onChange={(e) =>
                                setBookingDetails(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn site-btn">
                            Update UnAssigned Booking
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsLandDriverBooking}
                    title="Details"
                    disabled={selectedLandDriverBookings.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isDetailsModal ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isDetailsModal}
                    style={
                      isDetailsModal
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Booking Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Quote Code: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.quoteCode}
                            </p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Customer Name: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.customerName}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Customer Email: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.customerEmail}
                            </p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Phone Number: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.phoneNumber}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Company Name: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.companyName}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Airport Name: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.airportName}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Booking Start Time: '}
                            </p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.startDateTime}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Booking End Time: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.endDateTime}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Flying From: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.flyingFrom}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Flying To: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.flyingTo}
                            </p>
                          </div>{' '}
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Created Date: '}</p>
                            <p className="ms-2 mb-0">
                              {detailsLandDriverBookings.createdDate}
                            </p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      landDriverBookings.length <= 0
                        ? false
                        : selectedLandDriverBookings.length ===
                          landDriverBookings.length
                      // landDriverBookings.length >= 1
                      // selectedLandDriverBookings.length === landDriverBookings.length ? true : false
                      // landDriverBookings.length
                    }
                    onChange={() =>
                      setSelectedLandDriverBookings(
                        selectedLandDriverBookings.length ===
                          landDriverBookings.length
                          ? []
                          : landDriverBookings.map(
                              (landDriverBooking) => landDriverBooking.bookingID
                            )
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Quote Code
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Customer Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Customer Email
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Phone Number
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Company Name
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Airport Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  startDateTime
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  endDateTime
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  flyingFrom
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  flyingTo
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentLandDriverBookings.map((landDriverBooking, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${
                    landDriverBooking.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={landDriverBooking.bookingID}
                  style={
                    landDriverBooking.isActive
                      ? { backgroundColor: '#D3D3D3' }
                      : {}
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedLandDriverBookings.includes(
                        landDriverBooking.bookingID
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(landDriverBooking.bookingID)
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailLandDriverBooking(landDriverBooking.bookingID)
                    }
                  >
                    {landDriverBooking.quoteCode}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailLandDriverBooking(landDriverBooking.bookingID)
                    }
                  >
                    {landDriverBooking.customerName}
                  </td>
                  <td>{landDriverBooking.customerEmail}</td>
                  <td>{landDriverBooking.phoneNumber}</td>
                  <td>{landDriverBooking.companyName}</td>
                  <td>{landDriverBooking.airportName}</td>
                  <td>{landDriverBooking.startDateTime}</td>
                  <td>{landDriverBooking.endDateTime}</td>
                  <td>{landDriverBooking.flyingFrom}</td>
                  <td>{landDriverBooking.flyingTo}</td>
                  <td>{landDriverBooking.createdDate}</td>
                  <td className="driver_form_logo">
                    <i class="fa-solid fa-rectangle-list"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing LandDriverBookings: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default LandDriverBookings
