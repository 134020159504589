import { useEffect, useState } from 'react'
import useFetch from '../../../CustomHooks/useFetch'
import { API_BASE_URL } from '../../../Config/Config'
import { verifyToken } from '../../..'
import CustomLoading from '../../../Components/CustomHandling/LoadingAndError'
import { Slide, toast } from 'react-toastify'
import PageHeaderAndFooter from '../../../Components/PageHeaderAndFooter/PageHeaderAndFooter'

const ActiveUsers = () => {
  const showToast = (message, type = 'info', options = {}) => {
    const baseOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: 'light',
      transition: Slide,
      ...options,
    }

    switch (type) {
      case 'success':
        toast.success(message, baseOptions)
        break
      case 'warn':
        toast.warn(message, baseOptions)
        break
      case 'error':
        toast.error(message, baseOptions)
        break
      case 'info':
      default:
        toast.info(message, baseOptions)
        break
    }
  }

  const activeUsersURL = `${API_BASE_URL}/api/User/GetAllActiveUsers`
  const rolesURL = `${API_BASE_URL}/api/UserRoles/GetAllRoles`
  const [token, setToken] = useState('')

  const [userData, setUserData] = useState({
    activeUserName: '',
    userId: null,
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    companyName: '',
    // promotion: '',
    roleId: null,
  })
  // const [roles, setRoles] = useState([])
  const [refreshDependency, setRefreshDependency] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [createState, setCreateState] = useState(false)
  const [editState, setEditState] = useState(false)
  const [blockState, setBlockState] = useState(false)
  const [deleteState, setDeleteState] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const {
    data: activeUsers,
    loading,
    error,
  } = useFetch(activeUsersURL, refreshDependency)

  const { data: roles } = useFetch(rolesURL, refreshDependency)

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleValidateUser = () => {
    if (userData.password === userData.confirmPassword) {
      return true
    } else {
      showToast(`Password does not match`, 'error')
      return false
    }
  }
  const handleCreateOrEditActiveUser = async (e) => {
    e.preventDefault()

    const newActiveUser = {
      usersID: userData.userId || null,
      userName: userData.activeUserName || null,
      password: editState ? 'dummydata1234' : userData.password,
      firstName: userData.firstName || null,
      lastName: userData.lastName || null,
      email: userData.email || null,
      phoneNo: userData.phoneNo || null,
      userTypeID: 0,
      companyName: userData.companyName || null,
      promotion: true,
      createdDate: null,
      createdBy: null,
      modifiedDate: null,
      modifiedBy: null,
      deletedDate: null,
      deletedBy: null,
      discountCode: null,
      roleId: userData.roleId || null,
    }

    const url = `${API_BASE_URL}/api/user/CreateUser`
    if (editState || handleValidateUser()) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(newActiveUser),
        })

        const result = await response.json()

        if (response.ok) {
          showToast(
            createState
              ? 'User successfully created.'
              : 'User successfully updated.',
            'success'
          )

          // Reset states and refresh
          setRefreshDependency((prev) => prev + 1)
          setIsModalOpen(false)
          setCreateState(false)
          setEditState(false)
        } else {
          // Warning toasts for errors
          Object.keys(result.errors).forEach((key) => {
            result.errors[key].forEach((message) => {
              showToast(`${key}: ${message}`, 'warn')
            })
          })
        }
      } catch (error) {
        // Error toast for network issues or unexpected errors
        showToast(`${error.message}`, 'error')
      }
    }
  }

  const handleUpdateActiveUser = async (e) => {
    e.preventDefault()
    const url = `${API_BASE_URL}/api/user/UpdateUserStatus`
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          userID: userData.userId,
          isActive: null,
          isDeleted: deleteState ? true : null,
          isBlocked: blockState ? true : null,
        }),
      })

      const updateActiveUser = await response.json()
      console.log(updateActiveUser)
      if (response.ok) {
        console.log(updateActiveUser)

        const action = deleteState ? 'deleted' : 'blocked'
        showToast(`User '${userData.firstName}' ${action}`, 'success')
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setDeleteState(false)
        setBlockState(false)
        setExpandedRows([])
      } else {
        showToast('Failed to Delete User.', 'warn')
      }
    } catch (error) {
      showToast(`${error.message}`, 'error')
    }
  }
  // console.log(activeUsers)

  const handleOpenModal = (actionType, userID) => {
    const selectedActiveUser = activeUsers.find((mod) => mod.userID === userID)
    console.log(selectedActiveUser)

    const resetUserData = () => ({
      activeUserName: '',
      userId: null,
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNo: '',
      companyName: '',
      roleId: null,
    })

    const populateUserData = (user) => ({
      activeUserName: user.userName,
      userId: user.userID,
      password: '',
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNo: user.phoneNo.trim(),
      companyName: user.companyName,
      roleId: user.roleID,
    })

    // Reset modal states before setting new ones
    setCreateState(false)
    setEditState(false)
    setDeleteState(false)
    setIsModalOpen(false)

    switch (actionType) {
      case 'create':
        setUserData(resetUserData())
        setCreateState(true)
        setIsModalOpen(true)
        break

      case 'edit':
        if (selectedActiveUser) {
          setUserData(populateUserData(selectedActiveUser))
          setEditState(true)
          setIsModalOpen(true)
        } else {
          console.error('User not found for editing')
        }
        break

      case 'block':
        if (selectedActiveUser) {
          setUserData(populateUserData(selectedActiveUser))
          setBlockState(true)
          setIsModalOpen(true)
        } else {
          console.error('User not found for editing')
        }
        break

      case 'delete':
        if (selectedActiveUser) {
          setUserData(populateUserData(selectedActiveUser))
          setDeleteState(true)
          setIsModalOpen(true)
        } else {
          console.error('User not found for deletion')
        }
        break

      default:
        console.error('Invalid action type')
        setCreateState(false)
        setEditState(false)
        setDeleteState(false)
        setIsModalOpen(false)
        break
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setCreateState(false)
    setEditState(false)
    setBlockState(false)
    setDeleteState(false)
  }

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]))
  }

  if (loading) return <CustomLoading />
  if (error) return <div>Error: {error}</div>

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New User"
        handleOpenModal={() => handleOpenModal('create', null)}
        data={activeUsers}
        filterFields={['firstName', 'companyName', 'userName']}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Created Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <>
                    <tr
                      key={data.userID}
                      className={`${
                        expandedRows.includes(index) ? 'table-info' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{startIndex + index + 1}</td>
                      <td>{data.firstName + ' ' + data.lastName || 'N/A'}</td>
                      <td>{data.companyName || 'N/A'}</td>
                      <td>{data.userName || 'N/A'}</td>
                      <td>{data.createdDate || 'N/A'}</td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr className="bg-light">
                        <td colSpan="9">
                          <div className="row g-2 p-3">
                            <div className="col-md-6">
                              <strong>Email: </strong> {data.email || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Phone No: </strong>
                              {data.phoneNo || 'N/A'}
                            </div>{' '}
                            <div className="col-md-6">
                              <strong>Role Name: </strong>
                              {data.roleName || 'N/A'}
                            </div>{' '}
                            <div className="col-md-6"></div>
                            <td className="d-flex gap-2">
                              <button
                                className="btn btn_modal"
                                onClick={() =>
                                  handleOpenModal('edit', data.userID)
                                }
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn_modal_sec"
                                onClick={() =>
                                  handleOpenModal('delete', data.userID)
                                }
                              >
                                Delete
                              </button>
                              <button
                                className="btn btn_modal_sec "
                                onClick={() =>
                                  handleOpenModal('block', data.userID)
                                }
                              >
                                Block
                              </button>
                            </td>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? 'show' : ''}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? 'block' : 'none',
              background: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={handleCloseModal}
          >
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  createState || editState
                    ? handleCreateOrEditActiveUser
                    : deleteState || blockState
                    ? handleUpdateActiveUser
                    : ''
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {/* {userID ? 'Update activeUser name' : 'Create activeUser name'} */}
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : deleteState
                        ? 'Delete'
                        : blockState
                        ? 'Block'
                        : ''}{' '}
                      User
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body row">
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="activeUserName" className="form-label">
                        User Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="activeUserName"
                        value={userData.activeUserName}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>

                    {createState ? (
                      <>
                        <div className="mb-3 col-lg-6">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>

                        <div className="mb-3 col-lg-6">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={userData.firstName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={userData.lastName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="phoneNo" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNo"
                        value={userData.phoneNo}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="roleId" className="form-label">
                        User Types
                      </label>
                      <select
                        className="form-control"
                        name="roleId"
                        value={userData.roleId || ''}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      >
                        <option value="" disabled>
                          Select User Type
                        </option>
                        {roles.map((role) => (
                          <option key={role.roleId} value={role.roleId}>
                            {role.roleName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="companyName" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        value={userData.companyName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        createState
                          ? 'btn btn_modal_sec'
                          : editState
                          ? 'btn btn_modal_sec'
                          : blockState || deleteState
                          ? 'btn btn_modal'
                          : ''
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        createState
                          ? 'btn btn_modal'
                          : editState
                          ? 'btn btn_modal'
                          : blockState || deleteState
                          ? 'btn btn_modal_sec'
                          : ''
                      }
                      type="submit"
                    >
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : blockState
                        ? 'Block'
                        : deleteState
                        ? 'Delete'
                        : ''}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ActiveUsers
