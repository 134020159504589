import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import './Pages/Admin-Portal/FAQs/FAQs.css'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import App from './App'
import Home from './Pages/Home/Home'

import Quotes, { getAllQuotes } from './Pages/Booking/Quotes/Quotes.jsx'
import ActiveBookings, {
  getAllActiveBookings,
} from './Pages/Booking/Active-Bookings/ActiveBookings.jsx'
import CancelledBookings, {
  getAllCancelledBookings,
} from './Pages/Booking/Cancelled-Bookings/CancelledBookings'
import CompletedBookings, {
  getAllCompletedBookings,
} from './Pages/Booking/Completed-Bookings/CompletedBookings.jsx'
import DiscountCode, {
  getAllDiscountCode,
} from './Pages/Booking/Discount-Code/DiscountCode.jsx'

import Configuration, {
  getAllConfigurations,
} from './Pages/Admin-Portal/Configuration/Configuration'
import FAQs, { getAllFAQS } from './Pages/Admin-Portal/FAQs/FAQs'
import Testimonials, {
  getAllTestimonials,
} from './Pages/Admin-Portal/Testimonials/Testimonials'
import Captains, {
  getAllCaptains,
} from './Pages/Admin-Portal/Captains/Captains'
import ContactUs, {
  getAllContactUs,
} from './Pages/Admin-Portal/Contact-Us/ContactUs'

import DelayedCars, {
  getAllDelayedCars,
} from './Pages/Garage/Delayed-Cars/DelayedCars'
import ActiveCars, {
  getAllActiveCars,
} from './Pages/Garage/Active-Cars/ActiveCars'
import CompletedCars, {
  getAllCompletedCars,
} from './Pages/Garage/Completed-Cars/CompletedCars'

import Inbox from './Pages/Communication-Tab/Inbox/Inbox.jsx'
import Outbox from './Pages/Communication-Tab/Outbox/Outbox.jsx'

// import InActiveUsers, {getAllInActiveUsers,} from './Pages/User-Management/In-Active-Users/InActiveUsers.jsx'
import ActiveUsers from './Pages/User-Registration/Users/ActiveUsers.jsx'
import BlockedUsers from './Pages/User-Management/Blocked-Users/BlockedUsers.jsx'
import Roles from './Pages/User-Management/Roles/Roles.jsx'
import Modules from './Pages/User-Management/Modules/Modules.jsx'
// import OurTeam from './Pages/User-Management/Our-Team/OurTeam.jsx'
import UserPermission from './Pages/User-Management/User-Permission/UserPermission.jsx'

import UserTypes from './Pages/User-Registration/User-Types/UserTypes.jsx'

import Airports, { getAllAirports } from './Pages/Airport/Airports/Airports.jsx'
import Terminals from './Pages/Airport/Terminals/Terminals.jsx'

import VehicleDetails from './Pages/Vehicle-Configuration/Vehicle Details/VehicleDetails.jsx'
import VehicleMake, {
  getAllVehicleMakes,
} from './Pages/Vehicle-Configuration/Vehicle-Make/VehicleMake.jsx'
import VehicleModel from './Pages/Vehicle-Configuration/Vehicle-Model/VehicleModel.jsx'
import VehicleColor, {
  getAllVehicleColor,
} from './Pages/Vehicle-Configuration/Vehicle-Color/VehicleColor.jsx'
import MyVehicle from './Pages/Vehicle-Configuration/My-Vehicle/MyVehicle.jsx'
import Services, { getAllServices } from './Pages/Services/Services/Services'

import NewsLetter from './Pages/News-Letter/News-letter/NewsLetter'

import Billing from './Pages/Billing-Method/Billing/Billing'

import A_P_I from './Pages/Setting/API/A_P_I'
import Webhook from './Pages/Setting/Webhook/Webhook'
import TermsOfServices from './Pages/News-Letter/Terms-Of-Services/TermsOfServices'
import PrivacyPolicy from './Pages/News-Letter/Privacy-Policy/PrivacyPolicy'

import Login from './Pages/Forms/login'
import Signup from './Pages/Forms/signup'
import ProtectedRoutes from './utils/ProtectedRoutes'
import Yards, { getAllYards } from './Pages/Airport/Yards/Yards'
import Lanes from './Pages/Airport/Lanes/Lanes'
import Rows from './Pages/Airport/Rows/Rows.jsx'
import { API_BASE_URL } from './Config/Config'
import ReceivedAssignedBookings from './Pages/Booking/Received-Bookings/MeetAssBooks.jsx'
import MeetUnBooks from './Pages/Booking/Received-Bookings/MeetUnBooks.jsx'
import DriverReceivedBookings from './Pages/Driver-Bookings/ReceivedBookings.jsx'
import DriverDispatchedBookings from './Pages/Driver-Bookings/DispatchedBookings.jsx'
import DriverReceivingSlip from './Pages/Driver/ReceivingSlipPage/DriverReceivingSlip'
import DisptachedUnAssignedBookings from './Pages/Booking/Discpatched-Bookings/DIspatchedUnAssigned'
import DispatchedAssignedBookings from './Pages/Booking/Discpatched-Bookings/DispatchedAssigned'
import Cookies from 'js-cookie'
import BookingSlots from './Pages/Admin-Portal/Booking-Slots/BookingSlots'
import BookingsLayout from './Layouts/BookingsLayout'
import AdminPortalLayout from './Layouts/AdminPortalLayout'
import GarageLayout from './Layouts/GarageLayout'
import CommunicationTabLayout from './Layouts/CommunicationTabLayout'
import UserManagementLayout from './Layouts/UserManagementLayout'
import AirportLayout from './Layouts/AirportLayout'
import VehicleConfigurationLayout from './Layouts/VehicleConfigurationLayout'
import ServicesLayout from './Layouts/ServicesLayout'
import DeletedQuotes from './Pages/Booking/Deleted-Quotes/DeletedQuotes'
import CustomerBookingsLayout from './Layouts/CustomerBookingsLayout'
import DriverBookingsLayout from './Layouts/DriverBookingsLayout.jsx'
import CustomerQuotes from './Pages/Customer-Bookings/Customer-Quotes/CustomerQuotes'
import DeletedUsers from './Pages/User-Management/Deleted-Users/DeletedUsers'

export const verifyToken = async () => {
  const cookieValue = Cookies.get('userSession')
  if (cookieValue) {
    const parsedCookie = JSON.parse(cookieValue)
    // console.log(parsedCookie.authToken)
    return parsedCookie.authToken
  }
  return null
}

const fetchWithAuth = async (endpoint) => {
  const authToken = await verifyToken()

  return fetch(`${API_BASE_URL}${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
  })
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<App />} />
        <Route path="/bookings" element={<BookingsLayout />}>
          {/* Index Route (Default Child for Parent) */}
          <Route
            index
            path="quotes"
            element={<Quotes />}
            loader={() => fetchWithAuth('/api/Booking/GetAllDashQuote')}
          />

          {/* Other Child Routes */}
          <Route
            path="deleted-quotes"
            element={<DeletedQuotes />}
            loader={() => fetchWithAuth('/api/Booking/GetDeletedQuotes')}
          />
          <Route
            path="active-bookings"
            element={<ActiveBookings />}
            loader={() => fetchWithAuth('/api/Booking/GetAdminActiveBookings')}
          />
          <Route path="meet-unassigned-bookings" element={<MeetUnBooks />} />
          <Route
            path="meet-assigned-bookings"
            element={<ReceivedAssignedBookings />}
          />
          <Route
            path="dispatched-unassigned-bookings"
            element={<DisptachedUnAssignedBookings />}
          />
          <Route
            path="dispatched-assigned-bookings"
            element={<DispatchedAssignedBookings />}
          />
          <Route
            path="cancelled-bookings"
            element={<CancelledBookings />}
            loader={() => fetchWithAuth('/booking/AllCancelBookings')}
          />
          <Route
            path="completed-bookings"
            element={<CompletedBookings />}
            loader={() => fetchWithAuth('/api/Booking/AllCompletedBookings')}
          />
          <Route
            path="discountcode"
            element={<DiscountCode />}
            loader={() => fetchWithAuth('/api/Booking/GetAdminActiveBookings')}
          />
          <Route
            path=":bookingID/driver-receiving-slip"
            element={<DriverReceivingSlip />}
          />
        </Route>

        <Route path="/customer-bookings" element={<CustomerBookingsLayout />}>
          {/* Index Route (Default Child for Parent) */}
          <Route
            index
            path="quotes"
            element={<CustomerQuotes />}
            loader={() => fetchWithAuth('/api/Booking/GetAllDashQuote')}
          />

          {/* Other Child Routes */}

          <Route
            path="active-bookings"
            element={<ActiveBookings />}
            loader={() => fetchWithAuth('/api/Booking/GetAdminActiveBookings')}
          />
          <Route
            path="cancelled-bookings"
            element={<CancelledBookings />}
            loader={() => fetchWithAuth('/api/booking/AllCancelBookings')}
          />
          <Route
            path="completed-bookings"
            element={<CompletedBookings />}
            loader={() => fetchWithAuth('/api/booking/AllCompletedBookings')}
          />
        </Route>

        <Route path="/driver-bookings" element={<DriverBookingsLayout />}>
          {/* Index Route (Default Child for Parent) */}
          <Route
            index
            path="driver-received-bookings"
            element={<DriverReceivedBookings />}
          />

          <Route
            path="driver-dispatched-bookings"
            element={<DriverDispatchedBookings />}
          />
        </Route>

        <Route path="/admin-portal" element={<AdminPortalLayout />}>
          {/* Index Route (Default Child for Parent) */}
          <Route
            index
            path="booking-slots"
            element={<BookingSlots />}
            loader={() =>
              fetchWithAuth('/api/BookingPrice/GetAllBookingPrices')
            }
          />

          {/* Other Child Routes */}
          <Route
            path="configuration"
            element={<Configuration />}
            loader={getAllConfigurations}
          />
          <Route path="faqs" element={<FAQs />} loader={getAllFAQS} />
          <Route
            path="testimonials"
            element={<Testimonials />}
            loader={getAllTestimonials}
          />
          <Route
            path="captains"
            element={<Captains />}
            loader={() => fetchWithAuth('/api/Driver/GetAllDashDrivers')}
          />
          <Route
            path="contact-us"
            element={<ContactUs />}
            loader={getAllContactUs}
          />
        </Route>

        <Route path="/garage" element={<GarageLayout />}>
          {/* Index Route (Default Child for Parent) */}
          <Route
            index
            path="active-cars"
            element={<ActiveCars />}
            // loader={getAllActiveCars}
          />
          {/* Other Child Routes */}
          <Route
            path="completed-cars"
            element={<CompletedCars />}
            loader={getAllCompletedCars}
          />
          <Route
            path="delayed-cars"
            element={<DelayedCars />}
            loader={getAllDelayedCars}
          />
        </Route>

        <Route path="/communication-tab" element={<CommunicationTabLayout />}>
          {/* Index Route (Default Child for Parent) */}
          <Route index element={<Inbox />} />

          {/* Other Child Routes */}
          <Route path="outbox" element={<Outbox />} />
        </Route>

        <Route path="/user-management" element={<UserManagementLayout />}>
          <Route index path="active-users" element={<ActiveUsers />} />
          <Route path="roles" element={<Roles />} />
          <Route path="modules" element={<Modules />} />
          <Route path="blocked-users" element={<BlockedUsers />} />
          <Route path="deleted-users" element={<DeletedUsers />} />
          {/* <Route path="user-permission" element={<UserPermission />} /> */}
          {/* Index Route (Default Child for Parent) */}
          {/* <Route element={<InActiveUsers />} loader={getAllInActiveUsers} /> */}

          {/* Other Child Routes */}
          {/* <Route path="our-team" element={<OurTeam />} /> */}

          {/* Other Child Routes */}
          <Route path="user-types" element={<UserTypes />} />
        </Route>

        <Route path="/airport" element={<AirportLayout />}>
          {/* Index Route (Default Child for Parent) */}
          <Route index path="airports" element={<Airports />} />

          {/* Other Child Routes */}
          <Route path="airports/:airportID/terminals" element={<Terminals />} />
          <Route
            path="yards"
            element={<Yards />}
            loader={() => fetchWithAuth('/api/Yard/GetAllYards')}
          />
          <Route path=":yardID/lane" element={<Lanes />} />
          <Route path=":yardID/:yardLaneID/rows" element={<Rows />} />
        </Route>

        <Route
          path="vehicle-configuration"
          element={<VehicleConfigurationLayout />}
        >
          {/* Index Route */}
          {/* Vehicle Details */}
          {/* <Route
            element={<VehicleDetails />}
            loader={() => fetchWithAuth('/api/Vehicle/GetAllVehicleDetails')}
          /> */}

          {/* Vehicle Make */}
          <Route
            index
            path="vehicle-make"
            element={<VehicleMake />}
            loader={() => fetchWithAuth('/api/Vehicle/GetAllDashMake')}
          />
          {/* Vehicle Models */}
          <Route
            path="vehicle-make/:vehicleMakeID/vehicle-models"
            element={<VehicleModel />}
          />
          {/* Vehicle Color */}
          <Route
            path="vehicle-color"
            element={<VehicleColor />}
            loader={() => fetchWithAuth('/api/Vehicle/GetAllDashcolors')}
          />
          {/* My Vehicle */}
          {/* <Route path="my-vehicle" element={<MyVehicle />} /> */}
        </Route>

        <Route path="services" element={<ServicesLayout />}>
          {/* Index Route */}
          <Route index element={<Services />} loader={getAllServices} />
          {/* Services */}
          <Route
            path="services"
            element={<Services />}
            loader={getAllServices}
          />
        </Route>
      </Route>
    </>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Slide}
    />
  </React.StrictMode>
)

reportWebVitals()
