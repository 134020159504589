import Header from './Components/Header/Header.jsx'
import Navbar from './Components/Navbar/Navbar.jsx'
import { Outlet, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { API_BASE_DOMAIN } from './Config/Config'
import Login from './Pages/Forms/login'
import Signup from './Pages/Forms/signup'
import Home from './Pages/Home/Home'
import './Components/Navbar/Navbar.css'

function App() {
  const [searchTerm, setSearchTerm] = useState('')

  const highlightSearchTerm = (term) => {
    const body = document.querySelector('.container-fluid')

    if (!body) return

    removeHighlights(body)

    if (!term.trim()) return

    const regex = new RegExp(term, 'gi')
    traverseDOM(body, regex)
  }

  const traverseDOM = (node, regex) => {
    if (node.nodeType === 3) {
      // Text node
      const match = node.data.match(regex)
      if (match) {
        const span = document.createElement('span')
        span.innerHTML = node.data.replace(
          regex,
          (m) => `<span class="highlight">${m}</span>`
        )
        node.replaceWith(span)
      }
    } else if (node.nodeType === 1 && node.childNodes) {
      for (let i = 0; i < node.childNodes.length; i++) {
        traverseDOM(node.childNodes[i], regex)
      }
    }
  }

  const removeHighlights = (node) => {
    const highlighted = node.querySelectorAll('.highlight')
    highlighted.forEach((el) => {
      const parent = el.parentNode
      parent.replaceChild(document.createTextNode(el.innerText), el)
      parent.normalize()
    })
  }

  useEffect(() => {
    highlightSearchTerm(searchTerm)
  }, [searchTerm])

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 p-0 navbar_height">
            <Navbar />
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Home />
          </div>
        </div>
      </div>
    </>
  )
}

export default App
