import React from 'react'

function Inbox() {
  return (
    <>
      {/* <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="left">
              <button
                type="button"
                className="btn site-btn"
                data-bs-toggle="modal"
                data-bs-target="#InboxmodelMake"
              >
                Add Inbox
              </button>

              <div
                className="modal fade"
                id="InboxmodelMake"
                tabindex="-1"
                aria-labelledby="InboxModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="InboxModalLabel">
                        Add New Inbox
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3">
                          <label for="txtMake" className="form-label">
                            From Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="txtMake"
                            placeholder="From Email"
                          />
                        </div>
                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Message
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtMakeType"
                            placeholder="Message"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Priority
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtMakeType"
                            placeholder="Priority"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn site-btn">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 mb-3">
            <div className="right d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="date"
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="date"
              />
            </div>
          </div>
        </div> */}
           <div className="section-1">
        <div className="row">
          <div className="d-flex flex-row flex-wrap">
            <div className="p-2">
              {' '}
              <button
                type="button"
                title="Add New"
                className="btn btn-light details-btnn"
                data-bs-toggle="modal"
                data-bs-target="#quotesmodelMake"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Booking"
                >
                  <i className="fa-solid fa-plus" type="button"></i>
                </span>
              </button>
            </div>
            <div
              className="modal fade"
              id="quotesmodelMake"
              tabindex="-1"
              aria-labelledby="quotesModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="quotesModalLabel">
                      Add New Quote
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body ">
                    <form>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Customer Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Customer Email"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Discount Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Discount Code"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Start Date Time
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          placeholder="Start Date Time"
                        />
                      </div>

                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          End Date Time
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          placeholder="End Date Time"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Total Cost
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Total Cost"
                        />
                      </div>

                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Flying From</option>
                        <option value="1">Afghanistan</option>
                        <option value="2">Albania</option>
                        <option value="3">Pakistan</option>
                      </select>

                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          {' '}
                          Inbound Flight Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Inbound Flight Number"
                        />
                      </div>

                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Outbound Flight Number{' '}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Outbound Flight Number"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          {' '}
                          Outbound Terminal ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Outbound Terminal ID"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Inbound Terminal ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Inbound Terminal ID"
                        />
                      </div>

                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Location
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Location"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Campany Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Campany Name"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Source
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Source"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Airport Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Airport Name"
                        />
                      </div>

                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Discount Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Discount Code"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Product Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" Product Name"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Product Descrption
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Descrption"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Product Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Descrption"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMake" className="form-label">
                          Airport ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Airport ID"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          FlightTo{' '}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="FlightTo"
                        />
                      </div>
                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          InBoundFlight
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="InBoundFlight"
                        />
                      </div>

                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          OutBoundFlight
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="OutBoundFlight"
                        />
                      </div>

                      <div className="mb-3">
                        <label>IsRigister</label>
                        <br />
                        <input
                          type="radio"
                          id="yes"
                          name="IsRigister"
                          value="yes"
                        />
                        <label for="yes">Yes</label>
                        <input
                          type="radio"
                          id="no"
                          name="IsRigister"
                          value="no"
                        />
                        <label for="no">No</label>
                      </div>
                      <div className="mb-3">
                        <label>IsPromotion</label>
                        <br />
                        <input
                          type="radio"
                          id="yes"
                          name="IsPromotion"
                          value="yes"
                        />
                        <label for="yes">Yes</label>
                        <input
                          type="radio"
                          id="no"
                          name="IsPromotion"
                          value="no"
                        />
                        <label for="no">No</label>
                      </div>
                      <div className="mb-3">
                        <label>Is Flyn from Difrent</label>
                        <br />
                        <input
                          type="radio"
                          id="yes"
                          name="Difrent"
                          value="yes"
                        />
                        <label for="yes">Yes</label>
                        <input type="radio" id="no" name="Difrent" value="no" />
                        <label for="no">No</label>
                      </div>
                      {/* <div className="mb-3">
                        <label>Car Clean?</label>
                        <br />
                        <input
                          type="radio"
                          id="yes"
                          name="option"
                          value="yes"
                        />
                        <label for="yes">Yes</label>
                        <input type="radio" id="no" name="option" value="no" />
                        <label for="no">No</label>
                      </div> */}

                      {/* <div className="mb-3">
                        <label>E Charge?</label>
                        <br />
                        <input
                          type="radio"
                          id="yes"
                          name="option"
                          value="yes"
                        />
                        <label for="yes">Yes</label>
                        <input type="radio" id="no" name="option" value="no" />
                        <label for="no">No</label>
                      </div> */}

                      <div className="mb-3">
                        <label>Services</label>
                        <br />
                        <input
                          type="radio"
                          id="yes"
                          name="option"
                          value="yes"
                        />
                        <label for="yes">Yes</label>
                        <input type="radio" id="no" name="option" value="no" />
                        <label for="no">No</label>
                      </div>

                      <div className="mb-3">
                        <label for="txtMakeType" className="form-label">
                          Airport Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Airport Name"
                        />
                      </div>

                      <div className="mb-3">
                        <label>DifferentFromAirport?</label>
                        <br />
                        <input
                          type="radio"
                          id="yes"
                          name="option"
                          value="yes"
                        />
                        <label for="yes">Yes</label>
                        <input type="radio" id="no" name="option" value="no" />
                        <label for="no">No</label>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="cancel-btn"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" className="btn site-btn">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2">
              <button
                type="button"
                className="btn btn-light details-btnn me-2 me-2"
                data-bs-toggle="modal"
                data-bs-target="#BookingModal"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Booking"
                >
                  <i
                    className="fa-regular fa-calendar-check"
                    title="Calender"
                  ></i>
                </span>
              </button>

              <div
                className="modal fade"
                id="BookingModal"
                tabindex="-1"
                aria-labelledby="DeleteModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="DeleteModalLabel">
                        Confirmation
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <div className="mb-3">
                        <label className="fs-5">Do you want to Booking?</label>
                        <br />
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input fs-7"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label fs-7"
                          for="flexCheckDefault"
                        >
                          Never ask again
                        </label>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                      <button type="button" className="site-btn">
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <button
                type="button"
                className="btn btn-light details-btnn me-2 me-2"
                data-bs-toggle="modal"
                data-bs-target="#EditModal"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Edit"
                >
                  <i className="fa-solid fa-pencil" title="Edit"></i>
                </span>
              </button>

              {/* <div
                className="modal fade"
                id="EditModal"
                tabindex="-1"
                aria-labelledby="EditModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="EditModalLabel">
                        Do you want to Edit?
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3">
                          <label for="txtMake" className="form-label">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMake" className="form-label">
                            Phone Number
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Phone Number"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMake" className="form-label">
                            Customer Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Customer Email"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMake" className="form-label">
                            Quote Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Quote Code"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMake" className="form-label">
                            Start Date Time
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            placeholder="Start Date Time"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            End Date Time
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            placeholder="End Date Time"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Total Cost
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Total Cost"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Airport Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Airport Name"
                          />
                        </div>

                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Flying From</option>
                          <option value="1">Afghanistan</option>
                          <option value="2">Albania</option>
                          <option value="3">Pakistan</option>
                        </select>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            {' '}
                            Inbound Flight Number
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Inbound Flight Number"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Outbound Flight Number{' '}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Outbound Flight Number"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            {' '}
                            Outbound Terminal ID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Outbound Terminal ID"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Inbound Terminal ID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Inbound Terminal ID"
                          />
                        </div>

                        <div className="mb-3">
                          <label>Car Clean?</label>
                          <br />
                          <input
                            type="radio"
                            id="yes"
                            name="option"
                            value="yes"
                          />
                          <label for="yes">Yes</label>
                          <input
                            type="radio"
                            id="no"
                            name="option"
                            value="no"
                          />
                          <label for="no">No</label>
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Location
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Location"
                          />
                        </div>

                        <div className="mb-3">
                          <label>E Charge?</label>
                          <br />
                          <input
                            type="radio"
                            id="yes"
                            name="option"
                            value="yes"
                          />
                          <label for="yes">Yes</label>
                          <input
                            type="radio"
                            id="no"
                            name="option"
                            value="no"
                          />
                          <label for="no">No</label>
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Campany Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Campany Name"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            Source
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Source"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            FlightTo{' '}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="FlightTo"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            InBoundFlight
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="InBoundFlight"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="txtMakeType" className="form-label">
                            OutBoundFlight
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="OutBoundFlight"
                          />
                        </div>

                        <div className="mb-3">
                          <label>DifferentFromAirport?</label>
                          <br />
                          <input
                            type="radio"
                            id="yes"
                            name="option"
                            value="yes"
                          />
                          <label for="yes">Yes</label>
                          <input
                            type="radio"
                            id="no"
                            name="option"
                            value="no"
                          />
                          <label for="no">No</label>
                        </div>
                      </form>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="site-btn">
                        Update changes
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="p-2">
              <button
                type="button"
                className="btn btn-light details-btnn me-2 me-2"
                data-bs-toggle="modal"
                data-bs-target="#DetailsModal"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Details"
                >
                  <i className="fa-solid fa-clipboard" title="Details"></i>
                </span>
              </button>

              <div
                className="modal fade"
                id="DetailsModal"
                tabindex="-1"
                aria-labelledby="DetailsModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="DetailsModalLabel">
                        Personal Details
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <div className="personal-dtails mb-2 d-flex">
                        <h6 className="mb-0">Full Name:</h6>
                        <p className="ms-2 mb-0">Anna Adame</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Phone Number:</h6>
                        <div className="ms-2 mb-0">
                          <p className="ms-2 mb-0">+923188233285</p>
                        </div>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Customer Email:</h6>
                        <p className="ms-2 mb-0">zoe7@gmail.com</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Quote Code:</h6>
                        <p className="ms-2 mb-0">3456</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Start Date Time:</h6>
                        <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">End Date Time:</h6>
                        <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Total Cost:</h6>
                        <p className="ms-2 mb-0">4.5 $</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Airport Name:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Flying From:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Flying To:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Inbound Flight Number:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Outbound Flight Number:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Inbound Terminal ID:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Outbound Terminal ID:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Inbound Terminal:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Outbound Terminal:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Location:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Car Clean:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> E-Charge:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Campany Name:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Source:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="site-btn invisible">
                        {' '}
                        Update changes{' '}
                      </button>
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <button
                type="button"
                className="btn btn-light details-btnn me-2 me-2"
                data-bs-toggle="modal"
                data-bs-target="#DetailsCard"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Details"
                >
                  {/* <i className="fa-solid fa-clipboard"></i> */}
                  <i className="fa-solid fa-credit-card" title="Pay Now"></i>
                </span>
              </button>

              <div
                className="modal fade"
                id="DetailsCard"
                tabindex="-1"
                aria-labelledby="CardDetailsModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="DetailsModalLabel">
                        Card Details
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <div className="personal-dtails mb-2 d-flex">
                        <h6 className="mb-0">Full Name:</h6>
                        <p className="ms-2 mb-0">Anna Adame</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Phone Number:</h6>
                        <div className="ms-2 mb-0">
                          <p className="ms-2 mb-0">+923188233285</p>
                        </div>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Customer Email:</h6>
                        <p className="ms-2 mb-0">zoe7@gmail.com</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Quote Code:</h6>
                        <p className="ms-2 mb-0">3456</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Start Date Time:</h6>
                        <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">End Date Time:</h6>
                        <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Total Cost:</h6>
                        <p className="ms-2 mb-0">4.5 $</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Airport Name:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Flying From:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Flying To:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Inbound Flight Number:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Outbound Flight Number:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Inbound Terminal ID:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Outbound Terminal ID:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Inbound Terminal:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Outbound Terminal:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0">Location:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Car Clean:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> E-Charge:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Campany Name:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>

                      <div className="personal-dtails  mb-2 d-flex">
                        <h6 className="mb-0"> Source:</h6>
                        <p className="ms-2 mb-0">ABC</p>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="site-btn invisible">
                        {' '}
                        Update changes{' '}
                      </button>
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <button
                type="button"
                className="btn btn-light details-btnn me-2 me-2"
                data-bs-toggle="modal"
                data-bs-target="#DeleteModal"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Delete"
                >
                  <i className="fa-solid fa-trash" title="Delete"></i>
                </span>
              </button>

              <div
                className="modal fade"
                id="DeleteModal"
                tabindex="-1"
                aria-labelledby="DeleteModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="DeleteModalLabel">
                        Confirmation
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <div className="mb-3">
                        <label className="fs-5">Do you want to Delete? </label>
                        <br />
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input fs-7"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label fs-7"
                          for="flexCheckDefault"
                        >
                          Never ask again
                        </label>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="site-btn invisible">
                        Update changes
                      </button>
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="cancel-btn"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="toggal-button-box">
                <label className="switchh">
                  <input type="checkbox" />
                  <span className="sliderr round"></span>
                </label>
              </div>
            </div>
            <div className="p-2">
              <div className="col-lg-3 col-md-6">
                <div className="input-box mb-3">
                  <input
                    type="search"
                    className="form-control search-bar"
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="col-lg-5 col-md-3 ">
                <div className="right mb-3 d-flex justify-content-between align-items-center col-md-12">
                  <label className="mx-1">From: </label>
                  <input
                    type="date"
                    className="form-control col-md-5"
                    id="exampleFormControlInput1"
                    placeholder="date"
                  />
                  <label className="mx-1">To: </label>
                  <input
                    type="date"
                    className="form-control col-md-5"
                    id="exampleFormControlInput2"
                    placeholder="date"
                  />
                </div>
              </div>
            </div>
          </div>
          </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="font">
                  Sr No.
                </th>
                <th scope="col">From Email</th>
                <th scope="col">Message</th>
                <th scope="col">priority</th>
                <th scope="col">Edit, Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td
                  className="table-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#InboxmodelMake"
                >
                  x@gmail.com
                </td>
                <td>Honda</td>
                <td>abc</td>
                <td>
                  <button
                    type="button"
                    className="details-btn"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Edit"
                  >
                    <i className="fa-solid fa-pencil" title='Edit'></i>
                  </button>

                  <button
                    type="button"
                    className="details-btn"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Delete"
                  >
                    <i className="fa-solid fa-trash" title='Delete'></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end me-1">
            <li className="page-item disabled">
              <a className="page-link">Previous</a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Inbox
