import React, { useState } from 'react'
// import { FaEye, FaEyeSlash, FaRegClipboard } from 'react-icons/fa'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import './signup.css'
import { API_BASE_URL, AUTH_TOKEN } from '../../Config/Config'
// import BookingLoader from '../BookingLoader/LoadingAndError'
import Cookies from 'js-cookie'

export default function Register() {
  const [discoundCode, setDiscountCode] = useState()
  const [copySuccess, setCopySuccess] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible)
  const navigate = useNavigate()

  // const location = useLocation()
  // const searchParams = new URLSearchParams(location.search)
  // const role = searchParams.get('role')

  // var name = str.match(/^([^@]*)@/)[1]

  const [formData, setFormData] = useState({
    usersID: null,
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    password: '',
    confirmPassword: '',
    phoneNo: '',
    userTypeID: 3,
    companyName: '',
    promotion: '',
    createdDate: null,
    createdBy: null,
    modifiedDate: null,
    modifiedBy: null,
    deletedDate: null,
    deletedBy: null,
    discountCode: null,
    successMessage: null,
    errorMessage: null,
    agreeTerms: null,
  })
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target

    // If the email field is being updated, extract the username
    // if (name === 'email') {
    //   const nameMatch = value.match(/^([^@]*)@/)
    //   const userName = nameMatch ? nameMatch[1] : value

    //   // Update both email and username fields
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     email: value,
    //     userName: userName, // auto-set username from email
    //   }))
    // } else {
    // For other form fields, update as usual
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
    //   }
  }
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(formData.userName)
      setCopySuccess('Copied!')
    } catch (err) {
      setCopySuccess('Failed to copy')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const {
      usersID,
      firstName,
      lastName,
      email,
      userName,
      password,
      confirmPassword,
      phoneNo,
      userTypeID,
      companyName,
      promotion,
      createdDate,
      createdBy,
      modifiedDate,
      modifiedBy,
      deletedDate,
      deletedBy,
      discountCode,
      successMessage,
      errorMessage,
      agreeTerms,
    } = formData

    if (!agreeTerms) {
      alert('You must agree to the terms and conditions')
      return
    }

    if (password !== confirmPassword) {
      alert('Passwords do not match!')
      return
    }

    if (password.length < 10 || userName.length < 10) {
      alert('Username and Password must be at least 10 characters long.')
      return
    }

    const userData = {
      usersID: 0,
      userName,
      password,
      firstName,
      lastName,
      email,
      phoneNo,
      userTypeID: 3,
      companyName,
      promotion,
      createdDate: null,
      createdBy: 1,
      modifiedDate: null,
      modifiedBy: null,
      deletedDate: null,
      deletedBy: null,
      discountCode: '',
      successMessage: null,
      errorMessage: null,
    }

    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/User/Register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: AUTH_TOKEN,
        },
        body: JSON.stringify(userData),
      })
      const result = await response.json()
      console.log(result)
      // if (!response.ok) {
      // }
      if (response.ok) {
        // alert('Registration successful!')

        navigate('/login?role=Customer')
      } else {
        alert(`Registration failed`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert('An error occurred during registration.')

      // console.error('Registration error:', error)
      // console.log(error)
    }
  }

  return (
    <>
      {/* {loading && <BookingLoader />} */}
      <div className="registration-container">
        <form className="registration-form" onSubmit={handleSubmit}>
          <h2>Customer Register Form</h2>

          <div className="form-row">
            <div className="form-column">
              <label>
                First Name: *
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="form-column">
              <label>
                Surname:
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-column">
              <label>
                Email: *
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="form-column">
              <label>
                User Name: *
                <input
                  type="text"
                  name="userName"
                  onChange={handleInputChange}
                  value={formData.userName}
                  min="10"
                  // max="5"
                  required
                />
                <span onClick={handleCopyToClipboard}>
                  {formData.userName ? (
                    <i class="fa fa-clipboard" aria-hidden="true"></i>
                  ) : (
                    ''
                  )}
                </span>
                {copySuccess && (
                  <span style={{ marginRight: '20px' }}>{copySuccess}</span>
                )}
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-column">
              <label>
                Password: *
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <span onClick={togglePasswordVisibility}>
                  {formData.password ? (
                    passwordVisible ? (
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    )
                  ) : (
                    ''
                  )}
                </span>
              </label>
            </div>

            <div className="form-column">
              <label>
                Confirm Password: *
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
                <span onClick={togglePasswordVisibility}></span>
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-column">
              <label>
                Mobile: *
                <input
                  type="tel"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="form-column">
              <label>
                Company Name:
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
              </label>
            </div>
          </div>

          <div className="form-row">
            <label>Receive Promotions: </label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="promotion"
                checked={formData.promotion}
                onChange={handleInputChange}
              />
              <label className="form-check-label">Yes</label>
            </div>
          </div>

          <label className="terms">
            <input
              type="checkbox"
              name="agreeTerms"
              checked={formData.agreeTerms}
              onChange={handleInputChange}
              required
            />
            Please tick to confirm you have read and agree to the terms and
            conditions. *
          </label>

          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  )
}
