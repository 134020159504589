import React, { useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import Cookies from 'js-cookie'

function Yards() {
  const data = useLoaderData()
  const [yards, setYards] = useState(data)
  const [selectedYards, setSelectedYards] = useState([])

  useEffect(() => {
    const fetchYards = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/Yard/GetAllYards`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
        })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setYards(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchYards()
  }, [selectedYards])
  console.log(yards)

  const [yardName, setYardName] = useState('')
  const [yardDescription, setYardDescription] = useState('')
  const [yardLocation, setYardLocation] = useState('')
  const [yardStatus, setYardStatus] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  // const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [token, setToken] = useState({})
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalYards = yards.length

  useEffect(() => {
    const verifyToken = async () => {
      const cookieValue = Cookies.get('userSession')
      let parsedCookie
      parsedCookie = JSON.parse(cookieValue)
      const { tokenID, authToken, userId } = parsedCookie
      setToken({ tokenID, authToken, userId })
    }
    verifyToken()
  }, [])

  function handleStatusChange(e) {
    setYardStatus(e.target.value === 'true')
  }

  // Handle Yard creation
  const handleCreateYard = async (e) => {
    e.preventDefault()
    const newYard = {
      YardName: yardName,
      YardDescription: yardDescription,
      YardLocation: yardLocation,
      IsActive: yardStatus,
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/Yard/CreateYard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(newYard),
      })

      if (response.ok) {
        const createdYard = await response.json()
        // setYards((prevYards) => [...prevYards, createdYard])
        toast.success(`Yard '${createdYard.yardName}' created`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to create Yard.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for adding new Yard
  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  // Reset modal state
  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedYards([])

    setYardName('')
    setYardDescription('')
    setYardLocation('')
    setYardStatus(true)
  }

  // Handle backdrop click to close modal
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  // Handle Yard update
  const handleEditYard = async (e) => {
    e.preventDefault()
    const selectedYard = yards.find((yard) => yard.yardID === selectedYards[0])

    console.log(selectedYard)
    const updatedYard = {
      ...selectedYard,
      yardName,
      yardDescription,
      yardLocation,
      isActive: true,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Yard/UpdateYard/${selectedYard.yardID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.authToken,
          },
          body: JSON.stringify(updatedYard),
        }
      )

      if (response.ok) {
        // const updatedYardData = await response.json()
        // setYards((prevYards) =>
        //   prevYards.map((yard) =>
        //     yard.yardID === updatedYardData.yardID ? updatedYardData : yard
        //   )
        // )
        console.log(updatedYard)
        console.log(yards)

        toast.success(`Yard '${selectedYard.yardName}' updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
        resetModal()
      } else {
        toast.warn(`Failed to update Yard.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating Yard: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  // Show modal for editing Yard
  const handleEditButtonClick = () => {
    const selectedYard = yards.find((yard) => yard.yardID === selectedYards[0])
    console.log(selectedYard)
    setYardName(selectedYard.yardName)
    setYardDescription(selectedYard.yardDescription)
    setYardLocation(selectedYard.yardLocation)
    setYardStatus(selectedYard.isActive)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  // handle for Details Yard
  const handleDetailsYard = () => {
    const selectedYard = yards.find((yard) => yard.yardID === selectedYards[0])
    setYardName(selectedYard.yardName)
    setYardDescription(selectedYard.yardDescription)
    setYardLocation(selectedYard.yardLocation)
    setYardStatus(selectedYard.isActive)
  }

  const handleDetailYard = (singleYard) => {
    const selectedYard = yards.find((yard) => yard.yardID === singleYard)
    setYardName(selectedYard.yardName)
    setYardDescription(selectedYard.yardDescription)
    setYardLocation(selectedYard.yardLocation)
    setYardStatus(selectedYard.isActive)
  }

  // Handle Yard deletion
  const handleDeleteYard = async () => {
    const selectedYard = { yardID: selectedYards, isActive: false }

    try {
      const response = await fetch(`${API_BASE_URL}/api/Yard/BulkDeleteYards`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.authToken,
        },
        body: JSON.stringify(selectedYard),
      })

      if (response.ok) {
        // setYards((prevYards) =>
        //   prevYards.filter((yard) => !selectedYards.includes(yard.yardID))
        // )
        toast.success(`Selected Yards deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedYards([])
      }
    } catch (error) {
      toast.error(`Error deleting Yards: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setYardName('')
    setYardDescription('')
    setYardLocation('')
  }

  // Show modal for deleting Yard
  const handleDeleteButtonClick = () => {
    const selectedYard = yards.find((yard) => yard.yardID === selectedYards[0])
    setYardName(selectedYard.yardName)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredYards = yards.filter((yard) => {
    const matchesSearchTerm =
      yard.yardName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      yard.yardDescription?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      yard.yardLocation?.toLowerCase().includes(searchTerm.toLowerCase())

    const yardDate = new Date(yard.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      // Helper function to format date to 'dd/mm/yyyy'
      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedYardDate = formatDate(yardDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedYardDate >= formattedFromDate &&
          formattedYardDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedYardDate === formattedFromDate // Exact match for fromDate
      }

      return true // If no fromDate or toDate, show all data
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (yardId) => {
    setSelectedYards((prevSelected) => {
      let newSelection

      newSelection = prevSelected.includes(yardId)
        ? prevSelected.filter((id) => id !== yardId)
        : [...prevSelected, yardId]

      return newSelection
    })
  }
  // console.log(selectedYards, '----itsme-----')
  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * 15)
  }

  const totalPages = Math.ceil(filteredYards.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentYards = filteredYards.reverse().slice(startIndex, endIndex)

  const calculateDisplayRange = () => {
    return `${currentYards.length}/${totalYards}`
  }

  // Handle the "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  // Handle the "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }
  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>{' '}
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedYards.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsYard}
                    title="Details"
                    disabled={selectedYards.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Yard Name: '}</p>
                            <p className="ms-2 mb-0">{yardName}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Yard Description: '}</p>
                            <p className="ms-2 mb-0">{yardDescription}</p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Yard Description: '}</p>
                            <p className="ms-2 mb-0">{yardLocation}</p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedYards.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedYards.length === 1
                                ? ` '${yardName}' record?`
                                : `${selectedYards.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteYard}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="YardmodelMake"
                tabIndex="-1"
                aria-labelledby="YardModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={isEditMode ? handleEditYard : handleCreateYard}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="YardModalLabel">
                          {isEditMode ? 'Edit Yard' : 'Add New Yard'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Yard Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={yardName}
                            onChange={(e) => setYardName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Yard Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={yardDescription}
                            onChange={(e) => setYardDescription(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Yard Location
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={yardLocation}
                            onChange={(e) => setYardLocation(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3 active-inactive-all">
                          <div>
                            <input
                              type="radio"
                              id="active"
                              name="yardStatus"
                              value="true"
                              onChange={handleStatusChange}
                              checked={yardStatus === true}
                            />
                            <label htmlFor="active">Active</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="inactive"
                              name="yardStatus"
                              value="false"
                              onChange={handleStatusChange}
                              checked={yardStatus === false}
                            />
                            <label htmlFor="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode ? 'Update Yard' : 'Create Yard'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      yards.length <= 0
                        ? false
                        : selectedYards.length === yards.length
                      // yards.length >= 1
                      // selectedYards.length === yards.length ? true : false
                      // yards.length
                    }
                    onChange={() =>
                      setSelectedYards(
                        selectedYards.length === yards.length
                          ? []
                          : yards.map((yard) => yard.yardID)
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Yard Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Yard Description
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Yard Location
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Lane
                </th>
              </tr>
            </thead>
            <tbody>
              {currentYards.map((yard, index) => (
                <tr
                  className="faqs-table-row"
                  // title={`${yard.isActive ? 'Active Item' : 'Inactive Item'}`}
                  key={yard.yardID}
                  // style={yard.isActive ? { backgroundColor: '#D3D3D3' } : {}}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedYards.includes(yard.yardID)}
                      onChange={(e) => handleCheckboxChange(yard.yardID)}
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailYard(yard.yardID)}
                  >
                    {yard.yardName}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() => handleDetailYard(yard.yardID)}
                  >
                    {yard.yardDescription}
                  </td>
                  <td>{yard.yardLocation}</td>
                  <td>{yard.createdDate}</td>

                  <td key={yard.yardID}>
                    <Link to={`/airport/${yard.yardID}/lane`}>
                      <i class="fa-solid fa-grip-lines-vertical text-black"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing Yards: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Yards
// export const getAllYards = async () => {
//   const response = await fetch(`${API_BASE_URL}/api/Yard/GetAllYards`)
//   // console.log(response.json());
//   return response.json()
// }
